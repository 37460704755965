import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Form, List, Popup } from 'semantic-ui-react';
import { URL_WOOD_EXPORT } from '../../constance/urls';
import { PATH } from '../../routes/Routes';
import CustomTable from '../common/CustomTable';
import * as _ from 'lodash';
import { ellipsisString } from '../../utils/stringFormat';
import { getExportTransactionStatus } from '../../constance/dataTypes';
import ReportExportButton from '../common/ReportExportButton';


export default function ExportTransactionList(props) {
  const history = useHistory();
  const { t } = useTranslation();
  const [date, setDate] = React.useState([null, null]);

  const tableRef = React.useRef();

  const columns = React.useMemo(() => [
    {
      Header: t('date'),
      accessor: 'created',
      Cell: ({ row }) => new Date(row.original.created).toLocaleString('en-GB')
    },
    {
      Header: t('export_transactions.sale_order_no'),
      accessor: 'sale_order_no',
    },
    {
      Header: t('export_transactions.container_no'),
      accessor: 'container_no',
    },
    {
      Header: t('export_transactions.customer_code'),
      accessor: 'customer_code',
    },
    {
      Header: t('export_transactions.wood_size'),
      Cell: ({ row }) => {
        const woodSizeList = _.get(row.original, 'export_wood_sizes', [])
        let groups = woodSizeList.reduce((cnt, cur) => (cnt[cur] = cnt[cur] + 1 || 1, cnt), {});
        const listItems = Object.entries(groups).reduce((data, cur) => [
          ...data,
          <List.Item>{cur[0]} x {cur[1]}</List.Item>
        ], [])
        return (
          <Popup
            content={
              <List bulleted>
                {listItems}
              </List>
            }
            inverted
            trigger={<div>{ellipsisString(woodSizeList.join(', '), 26)}</div>}
            position='bottom left'
          />
        )
      }
    },
    {
      Header: t('status'),
      Cell: ({row}) => {
        return t(`export_transactions.statuses.${getExportTransactionStatus(row.original)}`)
      }
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({ row }) => (
        <div style={{textAlign: 'center'}}>
          { _.get(row, 'original.is_completed', false) && (
            <ReportExportButton
              url={`${URL_WOOD_EXPORT.EXPORT_TRANSACTION}${row.original.id}/report/`}
              params={{ export_type: 'pdf' }}
              basic color='teal' icon='print'
              content={t('export_transactions.print')}
              title={t('export_transactions.print')}
              size='medium'/>
          )}
          <Button
            basic
            primary
            icon='file alternate outline'
            content={ _.get(row, 'original.is_completed') ? null : t('detail')}
            onClick={() => {
              history.push(`${PATH.EXPORT_TRANSACTION}${row.original.id}`)
            }}
          />
        </div>
      )
    }
  ], [])

  const dateFilterComponent = (
    <Form.Field    
      label={t('searchs.fields.date')}
      locale='en-US'
      format='DD/MM/YY'
      showToday={true}
      type="range"
      maxDate={new Date().setHours(23, 59, 59, 999)}
      placeholder={t('searchs.placeholders.date')}
      control={SemanticDatepicker}
      onChange={(_, data) => setDate(data.value)}
    />
  )

  return (
    <div>
      <CustomTable
        ref={tableRef}
        fetchUrl={URL_WOOD_EXPORT.EXPORT_TRANSACTION}
        columns={columns}
        showSearch
        showSize
        showPagination
        extraFilter={[
          dateFilterComponent
        ]}
        extraFilterParams={{
          date_after: _.get(date, 0, null) ? moment(date[0]).format('YYYY-MM-DD') : null,
          date_before: _.get(date, 1, null) ? moment(_.get(date, 1)).format('YYYY-MM-DD') : null,
        }}
      />

    </div>
  )
}