import React from "react";
import { Button, Modal } from "semantic-ui-react";
import InputWasteWoodForm from "./InputWasteWoodForm";
import { useFormik } from "formik";
import { Toast } from "../../utils/Toast";
import { defaultPostBakingWasteWoodInputDetail } from "../../constance/dataTypes";
import { GET, POST, PUT } from "../../utils/HttpClient";
import * as Yup from "yup";
import * as _ from "lodash";
import { useTranslation } from "react-i18next";
import { URL_POST_BAKING } from "../../constance/urls";
import ErrorMessage from "../common/ErrorMessage";


export default function WasteWoodModal (props) {
  const {
    transaction,
    open,
    onClose,
    target
  } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const isCreate = target == 'add'; 
  const formik = useFormik({
    validateOnChange: false,
    initialValues: defaultPostBakingWasteWoodInputDetail,
    validationSchema: Yup.object().shape({
      grade: Yup.string().required(t('error_messages.validation.required')),
      wood_code_dimension: Yup.string().required(t('error_messages.validation.required'))
        .length(10, t('error_messages.validation.length')),
      amount: Yup.number().required(t('error_messages.validation.required'))
        .moreThan(0, `${t('error_messages.validation.more_than')} 0`).nullable(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setErrorMessages(null)
      try {
        if (isCreate) {
          await POST(URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT, values)
        } else {
          await PUT(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT}${target}/`, values)
        }
        Toast.success(`${t('save')} ${t('success')}`)
        onClose();
      } catch (error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setLoading(false)
      }
    }
  });

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null)
    try {
      const response = await GET(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT}${target}/`)
      formik.setValues(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (open) {
      formik.resetForm();
      if (!isCreate) {
        fetchData()
      } else {
        formik.setFieldValue('transaction', transaction)
      }
    }
  }, [open])

  return (
    <Modal open={open} onClose={onClose} closeIcon centered={false}>
      <Modal.Header>{isCreate ? t('add') : t('edit')} {t('post_baking_waste_wood_input.detail')}</Modal.Header>
      <Modal.Content>
        <ErrorMessage message={errorMessages}/>
        <InputWasteWoodForm formik={formik}/>
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon='cancel'
          content={t('cancel')}
          loading={loading}
          onClick={onClose}/>
        <Button
          color={isCreate ? 'green' : 'blue'}
          icon={isCreate ? 'plus' : 'edit'}
          content={isCreate ? t('add') : t('edit')}
          loading={loading}
          onClick={formik.handleSubmit}/>
      </Modal.Actions>
    </Modal>
  )
}