import React from "react";
import { Dimmer , Loader, Message } from "semantic-ui-react";
import Cookies from "js-cookie";
import { useLiff } from "react-liff-v2";
import { useHistory, useParams } from "react-router-dom";
import { PATH } from "../../routes/Routes";
import LoginSegment from "../../components/common/LoginSegment";

export default function LiffLoginView(props) {
	const history = useHistory();
  const { error, liff, isLoggedIn, ready } = useLiff();
	const  { app } = useParams();
  const [nextPath, setNextPath] = React.useState(PATH.LOG_WOOD_PO);

	const handleOnLoggedIn = async (values) => {
    history.replace(nextPath);
  };

	React.useEffect(() => {
    setNextPath(`/${app}/`);  // Manual set next route from given URL
  }, [app]);

  React.useEffect(() => {
    if (ready && isLoggedIn && Cookies.get('token') != null) {
      // Already logged in, auto redirect
      history.replace(nextPath);
    }
  }, [ready]);
	
	return (
		<>
			{!ready && 
        <Dimmer active>
          <Loader>Initializing Line LIFF...</Loader>
        </Dimmer>
      }
      {ready && !isLoggedIn &&
        < Dimmer active>
          <Loader>Logging In to Line Server...</Loader>
          {liff.login()}
        </Dimmer>
      }
			{ready && isLoggedIn && Cookies.get('token') == null &&
				<LoginSegment onLoggedIn={handleOnLoggedIn} liff={liff}/>
			}
			{error &&
        <Message
          negative
          icon="exclamation circle"
          header="Error on Line LIFF"
          content={error}
        />
      }
		</>
	)
};