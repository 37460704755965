import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader, Message, Form, Button, Input, Segment, Icon, Card, List, Divider, Header } from 'semantic-ui-react';
import propTypes from 'prop-types';
import { GET } from '../../utils/HttpClient';
import { FETCH_DATA_LIST } from '../../constance/dataTypes';
import * as _ from 'lodash';


const ItemList = ({data, content}) => {
  return (
    <Card.Group>
      {data.results.map(item => (
        <Card fluid onClick={() => content.onClick(item)}>
          <Card.Content>
            {
              content.header &&
              <Card.Header textAlign='left'>
                { content.header.icon && 
                  <Icon name={content.header.icon}/>
                }
                { content.header.detail ? 
                  content.header.detail(item) : 
                  _.get(item, content.header.accessor)
                }
              </Card.Header>
            }
            <Card.Description textAlign='left'>
              <List>
                { content.descriptions.map(description => {
                    return (
                      <List.Item>
                        <List.Content floated='left'>
                        { description.icon && 
                          <Icon name={description.icon}/>
                        }
                          {description.header(item)}
                        </List.Content>
                        <List.Content floated='right'>{description.detail(item)}</List.Content>
                      </List.Item>
                    )
                  })
                }
              </List>
            </Card.Description>
          </Card.Content>
        </Card>
      ))}
    </Card.Group>
  )
}


const MobileList = React.forwardRef((props, ref) => {

  const {
    fetchUrl,
    content,

    // filter props
    showFilterHeader,
    extraFilter,
    extraFilterParams,
  } = props;

  const { t } = useTranslation();

  const [data, setData] = React.useState(FETCH_DATA_LIST);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  // filter
  const [searchValue, setSearchValue] = React.useState(null);

  const refetch = async (params) => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(fetchUrl, params);
      setData(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

	const handleSearch = () => {
		refetch({page: 1, page_size: 10, search: searchValue, ...extraFilterParams})
	}

  React.useEffect(() => {
    refetch();
  }, []);

  const renderActionHeader = () => {
    if (extraFilter || showFilterHeader) {    
      return (
        <Form>
          <Form.Group inline widths='equal'>
            {extraFilter}
            {showFilterHeader &&
              <Form.Input
                name='search'
                label={t('search')}
                placeholder={`${t('search')}...`}
                value={searchValue || ''}
                onChange={e => {
                  setSearchValue(e.target.value);
                }}
              />
            }
            <Button color='blue' icon='search' onClick={handleSearch}/>
          </Form.Group>
        </Form>
      )
    }
    return null;
  }

  return (
    <Segment basic>
      <Dimmer active={isLoading} inverted>
        <Loader inverted>{t('loading')}</Loader>
      </Dimmer>
      { errorMessages &&
        <Message negative>
          <Message.Header>{t('error')}</Message.Header>
          <p>{errorMessages}</p>
        </Message>
      }
      { renderActionHeader() }
      
      { !isLoading && !data.total &&
        <Header disabled>
          <Icon
            name='search'
          />
          {t('datas.no_result')}
        </Header>
      }
      <ItemList
        data={data}
        content={content}
      />

    </Segment>
  );
});

MobileList.defaultProps = {
  fetchUrl: '',
  showFilterHeader: false,
  extraFilter: null,
  extraFilterParams: {},
}

MobileList.propTypes = {
  fetchUrl: propTypes.string,
  showFilterHeader: propTypes.bool,
  extraFilter: propTypes.object,
  extraFilterParams: propTypes.object,
}

export default React.memo(MobileList);