export const defaultLogWoodEvaluationDataDetail = {
  id: null,
  courtyard: null,
  log_wood_type: null,
  wood_plot: null,
  species: null,
  eval_length: 0,
  evaluate_user: null,
  std_buy_price: 0,
  buy_price: 0,
  buy_amount: 0,
  cut_weight: 0,
  return_wood: 0,
  wood_quality: '',
  location_code: '',
  length_meet_criteria: 0,
  length_under_criteria: 0,
  length_over_criteria: 0,
  need_approve: false,
  is_approved: false,
  courtyard_name: null,
  log_wood_type_name: '',
  eval_length_name: '',
  species_name: '',
  log_wood_cut_weight_evaluate_list: [],
  log_wood_evaluate_diameter_list: [],
  log_wood_species_evaluate_list: [],
  created: null,
  updated: null,
};

export const defaultLogWoodEvaluationDataLogSummary = {
  id: null,
  std_cost: 0,
  exchange_std: 0,
  std_buy_price: 0,
  buy_price: 0,
  real_cost: 0,
  buy_amount: 0,
  avg_weight: 0,
  cut_weight: 0,
  return_wood: 0,
  net_weight: 0,
}
