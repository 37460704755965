import React from "react";
import PropTypes from "prop-types";
import { Form } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../common/ErrorMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import moment from "moment";
import { GET } from "../../utils/HttpClient";
import { URL_POST_BAKING } from "../../constance/urls";
import * as _ from "lodash";


export default function SearchWasteWood (props) {
  const {
    onSelect,
    onBack,  
  } = props;
  
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: { post_baking_group_date: null, type_select: null, ovens: [] },
    validationSchema: Yup.object().shape({
      post_baking_group_date: Yup.string().required(t('error_messages.validation.required')).nullable(),
      input_date: Yup.string().required(t('error_messages.validation.required')).nullable(),
    }),
    onSubmit: async ({ post_baking_group_date, input_date }) => {
      setLoading(true);
      try {
        const checkWoodResponse = await GET(URL_POST_BAKING.CHECK_POST_BAKING_GROUP, { date: post_baking_group_date })
        const checkInputResponse = await GET(URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_DONE_CHECK, { date: post_baking_group_date })
        const woodExists = _.get(checkWoodResponse, 'data.exists', false)
        const doneCheckExists = _.get(checkInputResponse, 'data.exists', false)
        if (!woodExists) {
          setErrorMessages(t('post_baking_waste_wood_count.errors.no_wood'))
        } else if (doneCheckExists) {
          setErrorMessages(t('post_baking_waste_wood_input.errors.done_check'))
        } else {
          onSelect(post_baking_group_date, input_date)
        }
      } catch (error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setLoading(false);
      }
    }
  })

  return (
    <div style={{ marginTop: '3rem' }}>
      <ErrorMessage message={errorMessages}/>
      <Form error>
        <Form.Field
          inline
          label={t('post_baking_waste_wood_count.group_date')}
          locale='en-US'
          format='DD/MM/YYYY'
          showToday={true}
          placeholder={t('searchs.fields.date')}
          clearable={false}
          control={SemanticDatepicker}
          error={formik.errors.post_baking_group_date}
          onChange={(__, data) => {
            formik.setFieldValue('post_baking_group_date', data.value ? moment(data.value).format('yyyy-MM-DD') : null);
          }}/>
        <Form.Field
          inline
          label={t('post_baking_waste_wood_input.input_date')}
          locale='en-US'
          format='DD/MM/YYYY'
          showToday={true}
          placeholder={t('searchs.fields.date')}
          clearable={false}
          control={SemanticDatepicker}
          error={formik.errors.input_date}
          onChange={(__, data) => {
            formik.setFieldValue('input_date', data.value ? moment(data.value).format('yyyy-MM-DD') : null);
          }}/>
        <Form.Group inline style={{ justifyContent: 'center' }}>
          <Form.Button
            style={{ marginTop: '2rem' }}
            primary 
            icon='check'
            content={t('confirm')}
            loading={loading}
            onClick={formik.handleSubmit}/>
          <Form.Button style={{ marginTop: '2rem' }} content={t('back')} onClick={onBack}/>
        </Form.Group>
      </Form>
    </div>
  ) 
}



SearchWasteWood.propTypes = {
  onSelect: PropTypes.func,
  onBack: PropTypes.func,
}

SearchWasteWood.defaultProps = {
  onSelect: () => null,
  onBack: () => null,
}