import React from 'react';
import { Button, Container, Header, Icon } from 'semantic-ui-react';
import MainHeader, { MENU_BOM } from '../components/common/MainHeader';
import { Media } from "../AppMedia";
import { useTranslation } from 'react-i18next';
import BOMList from '../components/bom/BOMList';
import { PATH } from '../routes/Routes';
import { useHistory } from 'react-router-dom';

function BOMListView () {

	const history = useHistory();
	const { t } = useTranslation();

	return (
		<div>
			<MainHeader activeItem={MENU_BOM}/>
			
			<Container style={{ marginTop: '7em' }}>
				<Header as='h2'>{t('menus.wood_menus.bom')}</Header>
				<div style={{textAlign: "right", marginBottom: 12}}>
					<Button 
						positive
						onClick={() => history.push(`${PATH.BOM}add/`)}>
						<Icon name={"add"}/>
						{t('bom_transactions.add')}
					</Button>
				</div>

				<Media lessThan={"md"}>
					<BOMList />
					{/* <BOMMobileList /> */}
				</Media>

				<Media greaterThanOrEqual="md">
					<BOMList />
				</Media>
			</Container>
		</div>
	);
}

export default BOMListView;