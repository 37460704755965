import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Form, Header } from 'semantic-ui-react';
import MainHeader, { MENU_REPORT } from '../../components/common/MainHeader';
import { WOOD_IMPORT_GRADE_DISPLAY } from '../../constance/dataTypes';
import * as Yup from 'yup';
import moment from 'moment';
import ReportDetail from '../../components/reports/ReportDetail';
import { URL_BARCODE } from '../../constance/urls';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { formatComma } from '../../utils/stringFormat';
import ReportExportButton from '../../components/common/ReportExportButton';


export default function ReportWoodImportOutGrade() {

  const { t } = useTranslation();
  const [queryParams, setQueryParams] = React.useState(null)
  const wood_grade_options = [
    { value: 'all', key: 'all', text: t('all') },
    ...WOOD_IMPORT_GRADE_DISPLAY.map(grade => { return { value: grade, key: grade, text: grade.toLocaleUpperCase() } })
  ]
  const ref = React.useRef();

  const columns = React.useMemo(() => [
    { Header: 'ลูกค้า', accessor: 'truck_company', width: 200 },
    { Header: 'ขนาดไม้', accessor: 'mil_thick_code' },
    { Header: 'รหัสสินค้า', accessor: 'wood_code' },
    { Header: 'จำนวน (ท่อน)', accessor: 'amount' },
    { Header: 'ปริมาตร (ลบ.ฟ.)', accessor: 'volume' },
    { Header: '%', accessor: 'percentage' },
    { Header: 'ราคาซื้อ', accessor: 'buy_price' },
    { Header: 'ราคาขาย', accessor: 'sell_price' },
    { Header: 'กำไร', accessor: 'profit' },
  ])

  const getQueryParams = () => {
    const values = formik.values;
    const startDate = moment(values.dateRange[0]).format('YYYY-MM-DD');
    const endDate = moment(values.dateRange[1]).format('YYYY-MM-DD');
    const queryString = `date_after=${startDate}&date_before=${endDate}&grade=${values.grade}${values.truckCompanyCode ? `&truck_company_code=${values.truckCompanyCode}` : ''}`
    
    return queryString
  }

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      dateRange: Yup.array().min(1, t('error_messages.validation.required')),
    }),
    initialValues: {
      dateRange: [],
      grade: 'all',
    },
    onSubmit: () => {
      if (ref.current) {
        const params = getQueryParams()
        ref.current.refetch(params);
        setQueryParams(params)
      }
    }
  })

  return (
    <div>
      <MainHeader activeItem={MENU_REPORT}/>
      <Container style={{ marginTop: '7em' }}>
        <Header as='h2'>{t('menus.report_menus.wood_import_menus.wood_import_out_grade')}</Header>
        <Form autoComplete="off" style={{marginTop: 24, marginBottom: 24}}>
          <Form.Group inline>
            <Form.Field
              id='form-input-control-dateRange'
              name='dateRange'
              inline
              label={t('filter')}
              control={SemanticDatepicker}
              locale="en-US"
              type="range"
              format="DD/MM/YY"
              showToday={true}
              placeholder={t('searchs.placeholders.date')}
              values={formik.values.dateRange}
              error={formik.errors.dateRange != null}
              onChange={(_, data) => {
                formik.setFieldValue('dateRange', data.value);
              }}
            />
            <Form.Select
              label={t('wood_imports.grade')}
              options={wood_grade_options}
              defaultValue={formik.values.grade}
              error={formik.errors.grade}
              onChange={(_, data) => {
                formik.setFieldValue('grade', data.value);
              }}
            />
            <Form.Button primary type='submit' onClick={formik.handleSubmit}>{t('search')}</Form.Button>
          </Form.Group>
          <div style={{ textAlign: 'right' }}>
            {['pdf', 'excel'].map(type => (
              <ReportExportButton
                url={`${URL_BARCODE.REPORT_WOOD_IMPORT_OUT_GRADE_EXPORT}${type}/?${queryParams}`}
                type={type}
                title={`${t('menus.report_menus.wood_import_menus.wood_import_out_grade')}.....`}
                disabled={queryParams==null}
              />)
            )}
          </div>
        </Form>
        <ReportDetail
          ref={ref}
          reportUrl={URL_BARCODE.REPORT_WOOD_IMPORT_OUT_GRADE}
          columns={columns}
          isCustomSetData
          customSetData={(datas) => {
            const newData = [];
            let sumAmount = 0;
            let sumVolume = 0;
            for (let data of datas) {
              for (let [woodThickGroupIndex, woodThickGroupReport] of data.report.entries()) {
                for (let [woodCodeGroupIndex, woodCodeGroupReport] of woodThickGroupReport.report.entries()) {
                  let obj = woodCodeGroupReport;
                  if (woodCodeGroupIndex == 0) {
                    if (woodThickGroupIndex == 0) {
                      obj.truck_company = data.truck_company; 
                    }
                    obj.mil_thick_code = woodThickGroupReport.mil_thick_code;
                  }
                  newData.push(obj)
                }
                let amount = woodThickGroupReport.report.reduce((prev, cur) => prev + Number(cur.amount), Number(0));
                let volume = woodThickGroupReport.report.reduce((prev, cur) => prev + Number(cur.volume), Number(0));
                newData.push({
                  wood_code: 'รวม',
                  amount: amount,
                  volume: formatComma(volume, 4),
                  percentage: volume,
                })
                sumAmount += amount;
                sumVolume += volume;
              }
            }
            newData.forEach(e => {
              if(e.wood_code === 'รวม')
                e.percentage = formatComma((e.percentage * 100) / sumVolume)
            })
            newData.push({
              truck_company: 'รวมทั้งหมด',
              amount: sumAmount,
              volume: formatComma(sumVolume, 4),
            })
            return newData
          }}
        />
      </Container>
    </div>
  );
}