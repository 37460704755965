import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Container, Form, Header, Search } from 'semantic-ui-react';
import MainHeader, { MENU_REPORT } from '../../components/common/MainHeader';
import * as Yup from 'yup';
import { WOOD_IMPORT_GRADE_DISPLAY } from '../../constance/dataTypes';
import moment from 'moment';
import ReportDetail from '../../components/reports/ReportDetail';
import { URL_BARCODE, URL_SCALE_LARGE } from '../../constance/urls';
import { formatComma } from '../../utils/stringFormat';
import useSearchItem from '../../hooks/useSeatchItem';
import ReportExportButton from '../../components/common/ReportExportButton';


export default function ReportWoodImportByWoodThick() {

  const { t } = useTranslation();
  const [queryParams, setQueryParams] = React.useState(null)

  const ref = React.useRef();

  const columns = React.useMemo(() => [
    {
      Header: 'ขนาดไม้',
      accessor: 'mil_thick_code',
      width: 120,
    },
    {
      Header: 'รหัสสินค้า',
      accessor: 'wood_code',
      width: 120,
    },
    {
      Header: 'จำนวนส่ง (ท่อน)',
      accessor: 'total_woods',
      Cell: ({ value }) => formatComma(value, 0)
    },
    {
      Header: 'ไม้ดี (ท่อน)',
      accessor: 'good_woods',
      Cell: ({ value }) => formatComma(value, 0)
    },
    {
      Header: 'ปริมาตร (ลบ.ฟ.)',
      accessor: 'good_volume',
      Cell: ({ value }) => formatComma(value, 4)
    },
    {
      Header: '%',
      accessor: 'percentage',
      Cell: ({ value }) => formatComma(value)
    },
    {
      Header: 'ไม้เสีย (ท่อน)',
      accessor: 'waste_woods',
      Cell: ({ value }) => formatComma(value, 0)
    },
    {
      Header: 'ปริมาตร (ลบ.ฟ.)',
      accessor: 'waste_volume',
      Cell: ({ value }) => formatComma(value, 4)
    },
    {
      Header: 'ปริมาตรทั้งหมด (ลบ.ฟ.)',
      accessor: 'total_volume',
      Cell: ({ value }) => formatComma(value, 4)
    },
    {
      Header: 'จำนวนเงินซื้อ (บาท)',
      accessor: 'buy_amount',
      Cell: ({ value }) => formatComma(value)
    },
    {
      Header: 'จำนวนเงินขาย (บาท)',
      accessor: 'sell_amount',
      Cell: ({ value }) => formatComma(value)
    },
    {
      Header: 'ส่วนต่าง ขาย-ซื้อ (บาท)',
      accessor: 'diff_amount',
      Cell: ({ value }) => formatComma(value)
    },
  ], [])

  const wood_grade_options = WOOD_IMPORT_GRADE_DISPLAY.map(grade => { return { value: grade, key: grade, text: grade.toLocaleUpperCase() } })
  const truckCompanies = useSearchItem({url: URL_SCALE_LARGE.TRUCK_COMPANY, description_render: 'name', title_render: 'code'});
  const [truckCompanyName, setTruckCompanyName] = React.useState('');

  const getQueryParams = () => {
    const values = formik.values;
    const startDate = moment(values.dateRange[0]).format('YYYY-MM-DD');
    const endDate = moment(values.dateRange[1]).format('YYYY-MM-DD');
    const queryString = `date_after=${startDate}&date_before=${endDate}&grade=${values.grade}${values.truckCompanyCode ? `&truck_company_code=${values.truckCompanyCode}` : ''}`
    return queryString
  }

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      dateRange: Yup.array().min(1, t('error_messages.validation.required')),
      grade: Yup.string().required(t('error_messages.validation.required')),
    }),
    initialValues: {
      dateRange: [],
      grade: 'ab',
      truckCompanyCode: '',
    },
    onSubmit: () => {
      if (ref.current) {
        const params = getQueryParams()
        ref.current.refetch(params);
        setQueryParams(params)
      }
    }
  })

  return (
    <div>
      <MainHeader activeItem={MENU_REPORT}/>
      <Container style={{ marginTop: '7em' }}>
        <Header as='h2'>{t('menus.report_menus.wood_import_menus.wood_import_by_width')}</Header>
        <Form autoComplete="off" style={{marginTop: 24, marginBottom: 24}}>
          <Form.Group inline>
            <Form.Field
              id='form-input-control-dateRange'
              name='dateRange'
              inline
              label={t('filter')}
              control={SemanticDatepicker}
              locale="en-US"
              type="range"
              format="DD/MM/YY"
              showToday={true}
              placeholder={t('searchs.placeholders.date')}
              values={formik.values.dateRange}
              error={formik.errors.dateRange != null}
              onChange={(_, data) => {
                formik.setFieldValue('dateRange', data.value);
              }}
            />
            <Form.Select
              label={t('wood_imports.grade')}
              options={wood_grade_options}
              defaultValue={formik.values.grade}
              error={formik.errors.grade}
              onChange={(_, data) => {
                formik.setFieldValue('grade', data.value);
              }}
            />
            <label>{t('wood_imports.truck_company')}</label>
            <Form.Field
              fluid
              inline
              placeholder={`${t('search')} ${t('searchs.fields.code')} ${t('or')} ${t('searchs.fields.name')}...`}
              minCharacters={2}
              control={Search}
              results={truckCompanies.response}
              showNoResults={true}
              onSearchChange={(_ ,data) => {
                truckCompanies.fetchData({search: data.value})
                if (formik.values.truckCompanyCode) {
                  formik.setFieldValue('truckCompanyCode', null)
                }
                setTruckCompanyName(data.value)
              }}
              onResultSelect={(_, data) => {
                setTruckCompanyName(data.result.name)
                formik.setFieldValue('truckCompanyCode', data.result.code)
              }}
              value={truckCompanyName}
              loading={truckCompanies.isLoading}
            />
            <Form.Button primary type='submit' onClick={formik.handleSubmit}>{t('search')}</Form.Button>
          </Form.Group>
          <div style={{ textAlign: 'right' }}>
            {['pdf', 'excel'].map(type => (
              <ReportExportButton
                url={`${URL_BARCODE.REPORT_WOOD_IMPORT_WOOD_THICK_EXPORT}${type}/?${queryParams}`}
                type={type}
                title={`${t('menus.report_menus.wood_import_menus.wood_import_by_width')}.....`}
                disabled={queryParams==null}
              />)
            )}
          </div>
        </Form>
        <ReportDetail
          ref={ref}
          reportUrl={URL_BARCODE.REPORT_WOOD_IMPORT_WOOD_THICK}
          columns={columns}
          isCustomSetData
          customSetData={(datas) => {
            const newData = [];
            let sumGoodVolume = 0;
            for (let data of datas) {
              for (let [index, wood_size_grade_map_data] of data.report.entries()) {
                if (index == 0) {
                  newData.push({
                    mil_thick_code: data.mil_thick_code,
                    ...wood_size_grade_map_data,
                  })
                } else {
                  newData.push(wood_size_grade_map_data)
                }
              }
              const goodVolume = data.report.reduce((prev, cur) => prev + Number(cur.good_volume), Number(0))
              newData.push({
                wood_code: 'รวม',
                total_woods: data.report.reduce((prev, cur) => prev + Number(cur.total_woods), Number(0)),
                total_volume: data.report.reduce((prev, cur) => prev + Number(cur.total_volume), Number(0)),
                good_woods: data.report.reduce((prev, cur) => prev + Number(cur.good_woods), Number(0)),
                good_volume: goodVolume,
                percentage: goodVolume,
                waste_woods: data.report.reduce((prev, cur) => prev + Number(cur.waste_woods), Number(0)),
                waste_volume: data.report.reduce((prev, cur) => prev + Number(cur.waste_volume), Number(0)),
                buy_amount: data.report.reduce((prev, cur) => prev + Number(cur.buy_amount), Number(0)),
                sell_amount: data.report.reduce((prev, cur) => prev + Number(cur.sell_amount), Number(0)),
                diff_amount: data.report.reduce((prev, cur) => prev + Number(cur.diff_amount), Number(0)),
              })
              sumGoodVolume += goodVolume;
            }
            newData.forEach(e => {
              if(e.wood_code === 'รวม')
                e.percentage = formatComma((e.percentage * 100) / sumGoodVolume)
            })
            return newData
          }}
        />
      </Container>
    </div>
  )
}