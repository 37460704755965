import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Dropdown, Form, Message, Search, Icon, Popup } from 'semantic-ui-react';
import { defaultTruckCompanyDetail } from '../../constance/dataTypes';
import { defaultWoodSizeBuyPriceMapDetail, WOOD_IMPORT_GRADE_DISPLAY } from '../../constance/dataTypes/barcode';
import { URL_BARCODE, URL_SCALE_LARGE } from '../../constance/urls';
import useItemDetail from '../../hooks/useItemDetail';
import useSearchItem from '../../hooks/useSeatchItem';
import { POST } from '../../utils/HttpClient';
import { ellipsisString } from '../../utils/stringFormat';
import { Toast } from '../../utils/Toast';
import ConfirmModal from '../common/ConfirmModal';
import CustomTable from '../common/CustomTable';
import WoodImportSettingsPriceModal from './WoodImportSettingsPriceModal';
import * as _ from 'lodash';


export default function WoodImportSettingPriceList() {
  const { t } = useTranslation();
  const tableRef = React.useRef();
  const fileInputRef = React.useRef();


  const columns = React.useMemo(() => [
    {
      Header: '',
      accessor: 'info',
      columns: [
        {
          Header: t('wood_imports.wood_code'),
          accessor: 'wood_code',
        },
        {
          Header: t('wood_imports.truck_company'),
          accessor: 'truck_company_name',
          Cell: ({ row }) => (
            <Popup
              inverted
              flowing
              content={row.original.truck_company_name}
              trigger={<label>{ellipsisString(row.original.truck_company_name, 30)}</label>}
            />
          )
        },
        {
          Header: t('wood_imports.vat'),
          accessor: 'vat',
          Cell: ({ row }) => (
            <div style={{ textAlign: 'center' }}>
              <Icon 
                name={row.original.vat ? 'check' : 'cancel'}
                color={row.original.vat ? 'green' : 'red'}
              />
            </div>
          )
        },
      ]
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>{t('wood_imports.buy_price')}</div>,
      accessor: 'buy_price',
      columns: [
        ...WOOD_IMPORT_GRADE_DISPLAY.map(grade => {
          return {
            Header: t(`wood_imports.grades.${grade}`),
            accessor: `buy_${grade}`
          }
        }),    
      ]
    },
    {
      Header: () => <div style={{textAlign: 'center'}}>{t('wood_imports.sell_price')}</div>,
      accessor: 'sell_price',
      columns: [
        ...WOOD_IMPORT_GRADE_DISPLAY.map(grade => {
          return {
            Header: t(`wood_imports.grades.${grade}`),
            accessor: `sell_${grade}`
          }
        }),    
      ]
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ({ row  }) => (
        <div style={{textAlign: 'center'}}>
          <Button
            color='blue'
            basic
            content={t('edit')}
            icon='edit'
            onClick={() => {
              setIsDetailModalOpen(true);
              setDetail(row.original)
            }}
          />
          <Button
            negative
            basic
            icon='trash'
            onClick={() => {
              setIsDeleteConFirmModalOpen(true);
              setDetail(row.original)
            }}
          />
        </div>
      )
    }
  ], []);

  const [searchValue, setSearchValue] = React.useState('')
  const [truckCompany, setTruckCompany] = React.useState(defaultTruckCompanyDetail);
  const [isVat, setIsVat] = React.useState('all');
  const [detail, setDetail] = React.useState(defaultWoodSizeBuyPriceMapDetail);
  const [isEmpty, setIsEmpty] = React.useState(false);

  // import export state
  const [isLoading, setIsLoading] = React.useState(false)
  const [errorMessages, setErrorMessages] = React.useState(false)

  // detail modal
  const [isDetailModalOpen, setIsDetailModalOpen] = React.useState(false);
  const [isDeleteConFirmModalOpen, setIsDeleteConFirmModalOpen] = React.useState(false);

  const truckCompanies = useSearchItem({url: URL_SCALE_LARGE.TRUCK_COMPANY, description_render: 'name', title_render: 'code'});

  const handleImportFile = async (file) => {
    setIsLoading(true);
    setErrorMessages(null)
    try {
      await POST(URL_BARCODE.WOOD_SIZE_BUY_PRICE_MAP_IMPORT, {'attachment': file}, true)
      Toast.success(`${t('save')} ${t('success')}`)
      if (tableRef.current && truckCompany) {
        tableRef.current.refetch();
      }
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const woodSizeBuyPriceMapDetail = useItemDetail({
		url: URL_BARCODE.WOOD_SIZE_BUY_PRICE_MAP,
		id: detail.id ? detail.id : null,
		manual: true,
		onSuccess: () => {
			Toast.success(`${t('save')} ${t('success')}`)
			setIsDetailModalOpen(false);
      setDetail(defaultWoodSizeBuyPriceMapDetail)
      if (tableRef.current && truckCompany) {
        tableRef.current.refetch();
      }
		}
	})

  const truckCompanyFilterComponent = [
    <label>{t('wood_imports.truck_company')}</label>,
    <Form.Field
      fluid
      inline
      placeholder={`${t('search')} ${t('searchs.fields.code')} ${t('or')} ${t('searchs.fields.name')}...`}
      minCharacters={2}
      control={Search}
      results={truckCompanies.response}
      showNoResults={true}
      onSearchChange={(_ ,data) => {
        truckCompanies.fetchData({search: data.value})
        if (truckCompany.id) {
          setTruckCompany(defaultTruckCompanyDetail);
        }
        setSearchValue(data.value)
      }}
      onResultSelect={(_, data) => {
        setTruckCompany(data.result)
        setSearchValue(data.result.name)
      }}
      value={searchValue}
      loading={truckCompanies.isLoading}
    />
  ]

  const vatFilterComponent = (
    <Form.Select
      label={t('wood_imports.vat')}
      options={[
        { key: 'all', value: 'all', text: t('all') },
        { key: 'vat', value: true, text: t('wood_imports.vat') },
        { key: 'no_vat', value: false, text: t('wood_imports.no_vat') },
      ]}
      defaultValue={isVat}
      onChange={(_, data) => setIsVat(data.value)}
    />
  )

  return (
    <div>
      <ConfirmModal
        open={isDeleteConFirmModalOpen}
        onClose={() => setIsDeleteConFirmModalOpen(false)}
        title={t('confirms.delete_title')}
        content={t('confirms.delete_content')}
        onConfirm={() => {
          setIsDeleteConFirmModalOpen(false);
          woodSizeBuyPriceMapDetail.handleDelete(detail.id);
        }}
      />
      <WoodImportSettingsPriceModal
        open={isDetailModalOpen}
        detail={detail}
        onClose={() => {
          setIsDetailModalOpen(false)
          setDetail(defaultWoodSizeBuyPriceMapDetail)
        }}
        woodSizeBuyPriceMapDetail={woodSizeBuyPriceMapDetail}
      />
      <Button
        floated='right'
        positive
        icon='add'
        content={t('wood_imports.add_code')}
        onClick={() => setIsDetailModalOpen(true)}
      />
      <input
        ref={fileInputRef}
        hidden
        type='file'
        onClick={() => {
          // set value to null when select file to upload
          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }
        }}
        onChange={(e) => {
          handleImportFile(e.target.files[0])
        }}
      />
      <Button
        floated='right'
        color='blue'
        icon='download'
        loading={isLoading}
        content={t('import')}
        onClick={() => {
          if (fileInputRef.current) {
            fileInputRef.current.click()
          }
        }}
      />
      <Divider clearing hidden/>
      { errorMessages &&
        <Message negative>
          <Message.Header>{t('error')}</Message.Header>
          <p>{errorMessages}</p>
        </Message>
      }
      <CustomTable
        ref={tableRef}
        fetchUrl={URL_BARCODE.WOOD_SIZE_BUY_PRICE_MAP}
        columns={columns}
        showSize
        showPagination
        extraFilter={[
          truckCompanyFilterComponent,
          vatFilterComponent,
        ]}
        extraFilterParams={{ vat: isVat === 'all' ? null : isVat, truck_company_code: truckCompany.code }}
        setIsEmpty={(value) => setIsEmpty(value)}
      />
      { !isEmpty && 
        <Button.Group color='teal'>
          <Dropdown
            text={t('export')}
            button
          >
            <Dropdown.Menu>
              <Dropdown.Item
                text='Excel'
                icon='file excel outline'
                onClick={() => window.open(`${URL_BARCODE.WOOD_SIZE_BUY_PRICE_MAP_EXPORT}excel/?vat=${isVat}&truck_company_code=${truckCompany.code}`)}
              />
              <Dropdown.Item
                text='CSV'
                icon='file code outline'
                onClick={() => window.open(`${URL_BARCODE.WOOD_SIZE_BUY_PRICE_MAP_EXPORT}csv/?vat=${isVat}&truck_company_code=${truckCompany.code}`)}
              />
            </Dropdown.Menu>
          </Dropdown>
        </Button.Group>
      }
    </div>
  )
}