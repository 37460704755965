import React from 'react';
import ReactTable from "react-table-6";

export default function TSReactTable(props) {

  return (
		<ReactTable
			noDataText={"ไม่มีข้อมูล"}
			defaultPageSize={props.pageSize ? props.pageSize : 10}
			showPagination={true}
			showPageSizeOptions={false}
			className="-striped -highlight"
			getTdProps={() => ({
				style: { whiteSpace: 'unset', textAlign: "center" }
			})}
			{...props}
			style={{borderRadius: 5, ...props.style}}
		/>
	)
}