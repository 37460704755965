import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import propTypes from 'prop-types';
import CustomTable from '../common/CustomTable';
import { URL_SCALE_LARGE } from '../../constance/urls';
import { useTranslation } from 'react-i18next';
import { formatComma } from '../../utils/stringFormat';



function SelectTruckTransactionModal(props) {

  const {
    open,
    onClose,
    onSelect,
    filterParams,
  } = props;

  const { t } = useTranslation();

  const columns = [
    {
      Header: t('truck_transactions.in_date_time'),
      accessor: 'in_date_time',
      Cell: ({ row }) => new Date(row.original.in_date_time).toLocaleString('en-GB')
    },
    {
      Header: t('trucks.license_plate'),
      accessor: 'license_plate',
    },
    {
      Header: t('truck_transactions.company'),
      accessor: 'truck_company_detail.name',
    },
    {
      Header: t('truck_transactions.in_weight'),
      accessor: 'in_weight',
      Cell: ({ row }) => formatComma(row.original.in_weight)
    },
    {
      Header: t('truck_transactions.out_weight'),
      accessor: 'out_weight',
      Cell: ({ row }) => formatComma(row.original.out_weight)
    },
    {
      Header: t('truck_transactions.net_weight'),
      accessor: 'net_weight',
      Cell: ({ row }) => formatComma(row.original.net_weight)
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({ row }) => (
        <Button
          positive
          icon='add'
          onClick={(_) => {
            onSelect(row.original)
            onClose()
          }}
        />
      )
    }
  ]

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
    >
      <Modal.Header>{`${t('select')} ${t('trucks.license_plate')}`}</Modal.Header>
      <Modal.Content>
        <CustomTable
          fetchUrl={URL_SCALE_LARGE.TRUCK_TRANSACTION}
          columns={columns}
          showSearch
          showPagination
          extraFilterParams={filterParams}
        />
      </Modal.Content>
    </Modal>
  )
}

SelectTruckTransactionModal.defaultProps = {
  open: false,
  onClose: () => null,
  onSelect: () => null,
  filterParams: {},
}

SelectTruckTransactionModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  onSelect: propTypes.func,
  filterParams: propTypes.object,
}

export default React.memo(SelectTruckTransactionModal)