import React from 'react';
import { DELETE, GET, PATCH, POST, PUT } from '../utils/HttpClient';


export default function useItemDetail ({ url, id, onSuccess=() => null, manual=false, readOnly=false, returnData=false }) {
  const [response, setResponse] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const fetchData = async (fetch_id) => {
    if (!id && !fetch_id) {
      return false;
    }
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${url}${id ? id : fetch_id}/`)
      setResponse(response.data)

    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSave = async (params) => {
    if (readOnly) {
      return false;
    }
    let isSuccess = false;
    let results = null

    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await PUT(`${url}${id}/`, params);
      results = response.data
      isSuccess = true;
      onSuccess('save');
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }

    if(returnData) {
      return [results, isSuccess];
    }
    return isSuccess;
  }

  const handleCreate = async (params) => {
    let isSuccess = false;
    let results = null

    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await POST(url, params);
      results = response.data
      isSuccess = true;
      onSuccess('create');
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }

    if(returnData) {
      return [results, isSuccess];
    }
    return isSuccess;  
  }

  const handleListCreate = async (params) => {
    let isSuccess = false;

    setIsLoading(true);
    setErrorMessages(null);
    try {
      await POST(`${url}list_create/`, params);

      isSuccess = true;
      onSuccess('list_create');
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }

    return isSuccess;  
  }

  const handleDelete = async (delete_id) => {
    if (!id && !delete_id) {
      return false;
    }
    let isSuccess = false;

    setIsLoading(true);
    setErrorMessages(null);
    try {
      await DELETE(`${url}${id ? id : delete_id}/`)

      isSuccess = true
      onSuccess('delete');
    } catch (error) {
      setErrorMessages(error.errorMessages);;
    } finally {
      setIsLoading(false);
    }

    return isSuccess;
  }

  const handleListDelete = async (params) => {
    let isSuccess = false;

    setIsLoading(true);
    setErrorMessages(null);
    try {
      await POST(`${url}list_delete/`, params);

      isSuccess = true;
      onSuccess('list_delete');
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }

    return isSuccess;  
  }

  const handleAction = async (method='post', detail=false, action_str='', params={}) => {
    let isSuccess = false;

    setIsLoading(true);
    setErrorMessages(null);
    try {
      const target_url = `${url}${detail ? `${id}/` : '' }${action_str}/`
      switch (method.toLowerCase()) {
        case 'get': 
          await GET(target_url, params);
          break;
        case 'post': 
          await POST(target_url, params);
          break;
        case 'put': 
          await PUT(target_url, params);
          break;
        case 'patch': 
          await PATCH(target_url, params);
          break;
        default:
          return false;
      }
      isSuccess = true;
      onSuccess(action_str);
    } catch (error) {
      console.log(error)
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }

    return isSuccess;  
  }

  const clearErrorMessages = () => setErrorMessages(null);

  React.useEffect(() => {
    if (!manual) {
      fetchData();
    }
  }, []);

  return {
    response,
    fetchData,
    handleSave,
    handleCreate,
    handleListCreate,
    handleDelete,
    handleListDelete,
    handleAction,
    isLoading,
    errorMessages,
    clearErrorMessages
  };
}