import React from "react";
import { Container, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import MainHeader from "../components/common/MainHeader";
import BarcodeInput from "../components/barcode/BarcodeInput";
import WoodSummary from "../components/post-baking-waste-wood-input/WoodSummary";
import { URL_POST_BAKING } from "../constance/urls";
import SearchWasteWood from "../components/post-baking-waste-wood-input/SearchWasteWood";
import InputWasteWood from "../components/post-baking-waste-wood-input/InputWasteWood";
import { defaultPostBakingWasteWoodInputTrasactionDetail } from "../constance/dataTypes";
import * as _ from "lodash";


export default function PostBakingWasteWoodInputView () {
  const { t } = useTranslation();

  const POST_BAKING_WASTE_WOOD_INPUT_STEP = {
    ENTER_BARCODE: 'enter-barcode',
    WOOD_SUMMARY: 'wood-summary',
    SEARCH_WASTE_WOOD: 'search-waste-wood',
    SELECT_OVEN_SET: 'selec-oven-set',
    INPUT_WASTE_WOOD: 'input-waste-wood',
  }

  const [step, setStep] = React.useState(POST_BAKING_WASTE_WOOD_INPUT_STEP.ENTER_BARCODE)
  const [transaction, setTransaction] = React.useState('');
  const [transactionDetail, setTransactionDetail] = React.useState(defaultPostBakingWasteWoodInputTrasactionDetail);
  const [groupDate, setGroupDate] = React.useState('');
  const [inputDate, setInputDate] = React.useState('');
  const [editTarget, setEditTarget] = React.useState(null);

  const setDetail = (data) => {
    setTransactionDetail({...defaultPostBakingWasteWoodInputTrasactionDetail, ...data})
    setGroupDate(_.get(data, 'post_baking_group_date', ''));
    setInputDate(_.get(data, 'input_date', ''));
  }

  // ENTER_BARCODE -> WOOD_SUMMARY
  const onEnterSummary = (transaction) => {
    setTransaction(transaction)
    setStep(POST_BAKING_WASTE_WOOD_INPUT_STEP.WOOD_SUMMARY)
  }

  // WOOD_SUMMARY -> ENTER_BARCODE
  const onFinishSummary = () => {
    setStep(POST_BAKING_WASTE_WOOD_INPUT_STEP.ENTER_BARCODE)
  }

  // WOOD_SUMMARY -> SEARCH_WASTE_WOOD
  const onSearchWasteWood = () => {
    setStep(POST_BAKING_WASTE_WOOD_INPUT_STEP.SEARCH_WASTE_WOOD)
  }

  // WOOD_SUMMARY -> INPUT_WASTE_WOOD
  const onAddWood = () => {
    setStep(POST_BAKING_WASTE_WOOD_INPUT_STEP.INPUT_WASTE_WOOD)
  }

  // WOOD_SUMMARY -> INPUT_WASTE_WOOD
  const onEditWood = (target) => {
    setEditTarget(target)
    setStep(POST_BAKING_WASTE_WOOD_INPUT_STEP.INPUT_WASTE_WOOD)
  }

  // SEARCH_WASTE_WOOD -> INPUT_WASTE_WOOD
  const onSelectGroupDate = (groupDate, inputDate) => {
    setGroupDate(groupDate)
    setInputDate(inputDate)
    setStep(POST_BAKING_WASTE_WOOD_INPUT_STEP.INPUT_WASTE_WOOD)
  }

  // SEARCH_WASTE_WOOD, INPUT_WASTE_WOOD -> WOOD_SUMMARY
  const onBackToSummary = () => {
    setEditTarget(null);
    setStep(POST_BAKING_WASTE_WOOD_INPUT_STEP.WOOD_SUMMARY)
  }  

  const renderStep = () => {
    switch (step) {
      case POST_BAKING_WASTE_WOOD_INPUT_STEP.ENTER_BARCODE:
        return <BarcodeInput
          submitUrl={URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}
          onNext={onEnterSummary}/>
      case POST_BAKING_WASTE_WOOD_INPUT_STEP.WOOD_SUMMARY:
        return <WoodSummary
          transaction={transaction}
          transactionDetail={transactionDetail}
          setTransactionDetail={setDetail}
          onSearchWasteWood={onSearchWasteWood}
          onAdd={onAddWood}
          onEdit={onEditWood}
          onFinish={onFinishSummary}/>
      case POST_BAKING_WASTE_WOOD_INPUT_STEP.SEARCH_WASTE_WOOD:
        return <SearchWasteWood
          onSelect={onSelectGroupDate}
          onBack={onBackToSummary}/>
      case POST_BAKING_WASTE_WOOD_INPUT_STEP.INPUT_WASTE_WOOD:
        return <InputWasteWood
          target={editTarget}
          transactionDetail={transactionDetail}
          groupDate={groupDate}
          inputDate={inputDate}
          onBack={onBackToSummary}/>
    }
    return null;
  }

  // add confirmation before refresh
  React.useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);  

  return (
    <div>
			<Container style={{ marginTop: '7em' }}>
        <MainHeader/>
        <Header as='h2'>{t('menus.wood_menus.post_baking_waste_wood_input')}</Header>
        {renderStep()}
      </Container>
    </div>
  )
}