import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Header, Form, Message } from 'semantic-ui-react';
import { PATH } from '../../routes/Routes';
import { formatComma } from '../../utils/stringFormat';
import CustomTable from '../common/CustomTable';
import { URL_WORKFLOW } from '../../constance/urls';
import { useTranslation } from 'react-i18next';
import useWorkflowDetail from '../../hooks/useWorkflowDetail';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';
import { Toast } from '../../utils/Toast';
import ActionButtons from '../workflow/ActionButtons';
import Cookies from 'js-cookie';
import * as _ from 'lodash';


const AVAILABLE_ACTIONS = {
  manager: [
    'manager_approve',
    'manager_reject'
  ],
  md: [
    'md_approve',
    'md_reject'
  ],
}

function LogWoodPOList(props) {

  const { t } = useTranslation();
  const history = useHistory();
  const [date, setDate] = React.useState('');
  const tableRef = React.useRef();
  const [selectedList, setSelectedList] = React.useState([])
  const [user] = React.useState(JSON.parse(Cookies.get('user', {})));

  const {
    handleAction,
    isLoading,
    errorMessages,
  } = useWorkflowDetail({
    id: selectedList,
    workflowUrl: URL_WORKFLOW.LOG_WOOD_PO_WORKFLOW,
    manual: true,
    multiple: true,
    onSuccess: () => {
      if (tableRef.current) {
        tableRef.current.refetch()
        Toast.success(t('success'))
      }
    }
  })

  const columns = React.useMemo(() => [
    {
      Header: t('truck_transactions.ticket1'),
      accessor: 'truck_transaction.ticket1',
    },
    {
      Header: t('truck_transactions.ticket2'),
      accessor: 'truck_transaction.ticket2',
    },
    {
      Header: t('truck_transactions.in_date_time'),
      accessor: 'truck_transaction.in_date_time',
      Cell: ({ row }) => new Date(row.original.truck_transaction.in_date_time).toLocaleString('en-GB')
    },
    {
      Header: t('truck_transactions.out_date_time'),
      accessor: 'truck_transaction.out_date_time',
      Cell: ({ row }) => row.original.truck_transaction.out_date_time ?
        new Date(row.original.truck_transaction.out_date_time).toLocaleString('en-GB') :
        <div style={{ textAlign: 'center' }}>-</div>
    },
    {
      Header: t('trucks.license_plate'),
      accessor: 'truck_transaction.license_plate',
    },
    {
      Header: t('truck_transactions.company'),
      accessor: 'truck_transaction.truck_company_detail.name',
      Cell: ({ row }) => <div style={{ width: 100 }}>
        [{_.get(row, 'original.truck_transaction.truck_company_detail.code')}] {_.get(row, 'original.truck_transaction.truck_company_detail.name')}
      </div>,
    },
    {
      Header: t('truck_transactions.in_weight'),
      accessor: 'truck_transaction.in_weight',
      Cell: ({ row }) => formatComma(row.original.truck_transaction.in_weight)
    },
    {
      Header: t('truck_transactions.out_weight'),
      accessor: 'truck_transaction.out_weight',
      Cell: ({ row }) => formatComma(row.original.truck_transaction.out_weight)
    },
    {
      Header: t('log_wood_evaluation_datas.cut_weight'),
      accessor: 'truck_transaction.cut_weight',
      Cell: ({ row }) => formatComma(row.original.truck_transaction.cut_weight)
    },
    {
      Header: t('truck_transactions.net_weight'),
      accessor: 'truck_transaction.net_weight',
      Cell: ({ row }) => formatComma(row.original.truck_transaction.net_weight)
    },
    {
      Header: t('log_wood_evaluation_datas.buy_price'),
      accessor: 'truck_transaction.buy_price',
      Cell: ({ row }) => formatComma(row.original.truck_transaction.buy_price)
    },
    {
      Header: t('log_wood_evaluation_datas.buy_amount'),
      accessor: 'truck_transaction.buy_amount',
      Cell: ({ row }) => formatComma(row.original.truck_transaction.buy_amount)
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({ row }) => (
        <Button
          basic
          primary
          content={t('detail')}
          onClick={() => history.push(`${PATH.LOG_WOOD_PO}${row.original.id}`)}
        />
      )
    }
  ], []);

  const dateFilterComponent = (
    <Form.Field
      label={t('searchs.fields.date')}
      locale='en-US'
      format='DD/MM/YY'
      showToday={true}
      placeholder={t('searchs.placeholders.date')}
      control={SemanticDatepicker}
      onChange={(_, data) => {
        if (data.value) {
          setDate(moment(data.value).format('YYYY-MM-DD'))
        } else {
          setDate('')
        }
      }}
    />
  )

  const renderLogWoodPOButton = () => {
    if (!AVAILABLE_ACTIONS[_.get(user.groups, '0', '')]) {
      return null;
    }
    return (
      <ActionButtons
        actions={AVAILABLE_ACTIONS[_.get(user.groups, '0', '')]}
        handleAction={(action) => handleAction(action)}
        disabled={!selectedList.length}
        isLoading={isLoading}
      />
    );
  }

  return (
    <div>
      {errorMessages &&
        <Message error>{errorMessages}</Message>
      }
      <CustomTable
        ref={tableRef}
        fetchUrl={URL_WORKFLOW.LOG_WOOD_PO_WORKFLOW}
        columns={columns}
        showSearch
        showSize
        showPagination
        extraFilter={dateFilterComponent}
        extraFilterParams={{ date: date }}
        showSelect={AVAILABLE_ACTIONS[_.get(user.groups, '0', '')]}
        extraActions={renderLogWoodPOButton()}
        updateSelectedList={(value) => setSelectedList(value)}
      />
    </div>
  )
}

export default LogWoodPOList;