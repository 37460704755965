import { useFormik } from "formik";
import PropTypes from "prop-types";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { Form } from "semantic-ui-react";
import * as Yup from "yup";
import { GET } from "../../utils/HttpClient";
import { URL_POST_BAKING } from "../../constance/urls";
import * as _ from "lodash";
import ErrorMessage from "../common/ErrorMessage";


export default function EnterGroupDate (props) {
  const {
    onEnter,
  } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null)

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: { date: null },
    validationSchema: Yup.object().shape({
      date: Yup.string().required(t('error_messages.validation.required')).nullable(),
    }),
    onSubmit: async ({ date }) => {
      setLoading(true);
      try {
        const response = await GET(URL_POST_BAKING.CHECK_POST_BAKING_GROUP, { date: date })
        if (_.get(response, 'data.exists', false)) {
          onEnter(date)
        } else {
          setErrorMessages(t('post_baking_waste_wood_count.errors.no_wood'))
        }
      } catch (error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setLoading(false);
      }
    }
  })

  return (
    <div>
      <ErrorMessage message={errorMessages}/>
      <Form style={{ marginTop: '7em' }}>
        <Form.Field
          inline
          label={t('post_baking_waste_wood_count.group_date')}
          locale='en-US'
          format='DD/MM/YYYY'
          showToday={true}
          placeholder={t('searchs.placeholders.date')}
          control={SemanticDatepicker}
          error={formik.errors.date}
          onChange={(__, data) => {
            formik.setFieldValue('date', data.value ? moment(data.value).format('yyyy-MM-DD') : null);
          }}/>
        <Form.Button
          primary
          icon='search'
          content={t('search')}
          loading={loading}
          onClick={formik.handleSubmit}/>
      </Form>
    </div>
  )
}

EnterGroupDate.propTypes = {
  onEnter: PropTypes.func,
}

EnterGroupDate.defaultProps = {
  onEnter: () => null,
}