import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from 'semantic-ui-react';
import propTypes from 'prop-types';
import { Media } from "../../AppMedia";


function TimeoutModal(props) {

  const {
    open,
    onClose,
  } = props;

  const { t } = useTranslation();

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      size='small'
    >
      <Modal.Header>
        <Icon
          color='red'
          name='clock outline'
          size='big'
        />
        {t('error_messages.session_timeout.header')}
      </Modal.Header>
      <Modal.Content>{t('error_messages.session_timeout.content')}</Modal.Content>
      <Modal.Actions>
        <Button
          color='blue'
          content={t('confirm')}
          onClick={onClose}
        />
      </Modal.Actions>
    </Modal>
  );
}


export default React.memo(TimeoutModal);

TimeoutModal.defaultProps = {
  open: false,
  onClose: () => null,
}

TimeoutModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
}
