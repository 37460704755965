export const defaultExportItemDetail = {
  id: null,
  export_sale_line: null,
  packing_type: 'normal',
  barcode: null,
  humidity: '',
}

export const defaultExportTransactionDetail = {
  id: null,
  created: null,
  customer_code: '',
  export_wood_sizes: [],
  is_completed: false,
  is_interfaced: false,
  sale_order_no: '',
  container_no: '',
  export_doc: '',
  export_doc_line_no: '',
}


export const PACKING_TYPE = {
  NORMAL: 'normal',
  EXTRA: 'extra' 
}


export const STATUS = {
  SETTING: 'setting',
  COMPLETED: 'completed',
  INTERFACED: 'interfaced',
}


export const getExportTransactionStatus = ({ is_completed, is_interfaced }=defaultExportTransactionDetail) => {
  if (is_completed && is_interfaced) {
    return STATUS.INTERFACED
  } else if (is_completed) {
    return STATUS.COMPLETED
  }
  return STATUS.SETTING
}