import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Header } from 'semantic-ui-react'
import MainHeader, { MENU_WOOD_EXPORT } from '../components/common/MainHeader'
import ExportTransactionList from '../components/wood-export/ExportTransactionList';


export default function ExportTransactionListView(props) {
  const { t } = useTranslation();

  return (
    <div>
      <MainHeader activeItem={MENU_WOOD_EXPORT}/>

      <Container style={{ marginTop: '7em' }}>
        <Header as='h2'>{t('menus.wood_menus.export_transaction')}</Header>

        <ExportTransactionList />
      </Container>

    </div>
  )
}
