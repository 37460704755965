import React from "react";
import PropTypes from "prop-types";
import { Message, Popup } from "semantic-ui-react";
import { ellipsisString } from "../../utils/stringFormat";


export default function ErrorMessage(props) {
  const {
    message,
    length
  } = props;

  return message ? (
    <Popup
      hoverable
      inverted
      position='bottom center'
      content={message}
      trigger={<Message error>{ellipsisString(message, length)}</Message>}
    />
  ) : null
}

ErrorMessage.propTypes = {
  message: PropTypes.string,
  length: PropTypes.number,
}

ErrorMessage.defaultProps = {
  message: null,
  length: 30,
}