import { useFormik } from 'formik';
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom';
import { Button, Container, Dimmer, Divider, Form, Header, Loader, Message, Search, Segment } from 'semantic-ui-react'
import SubHeader from '../components/common/SubHeader';
import { URL_AX_DB, URL_WOOD_EXPORT, URL_WORKFLOW } from '../constance/urls';
import { GET, PATCH, POST } from '../utils/HttpClient';
import * as Yup from 'yup';
import * as _ from 'lodash';
import useDropdownItem from '../hooks/useDropdownItem';
import ExportItemList from '../components/wood-export/ExportItemList';
import { defaultExportTransactionDetail } from '../constance/dataTypes';
import { Toast } from '../utils/Toast';
import { PATH } from '../routes/Routes';
import ConfirmModal from '../components/common/ConfirmModal';


export default function ExportTransactionDetailView(props) {
  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [isInterfaceConFirmModalOpen, setIsInterfaceConFirmModalOpen] = React.useState(false);
  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: defaultExportTransactionDetail,
    validationSchema: Yup.object().shape({
      export_doc_line_no: Yup.string().required(t('error_messages.validation.required')),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setErrorMessages(null);
  
      try {
        await PATCH(`${URL_WOOD_EXPORT.EXPORT_TRANSACTION}${params.id}/`, values)
        Toast.success(t('success'))
        fetchData();
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false);
      }  
    }
  })

  const exportDocLineNoChoices = useDropdownItem({
    url: `${URL_AX_DB.AX_EXPORT_DOC}${formik.values.sale_order_no}/${formik.values.container_no}/`,
    manual: true,
    id: 'LINENUM',
    value: 'LINENUM',
    text_renderer: (item) => {
      const lineNum = _.get(item, 'LINENUM', '')
      const customerName = _.get(item, 'ECL_DRIVERNAME', '')
      const licensePlate = _.get(item, 'ECL_CARNUMBER', '')
      let stringRender = lineNum;
      stringRender += customerName ? ` : ${customerName}` : ''
      stringRender += licensePlate ? ` [${licensePlate}]` : ''
      return stringRender
    }
  })

  const fetchData = async () => {
    setIsLoading(true);
    setErrorMessages(null);

    try {
      const response = await GET(`${URL_WOOD_EXPORT.EXPORT_TRANSACTION}${params.id}`)
      formik.setValues({...defaultExportTransactionDetail, ...response.data})
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const handleDoInterfaceAX = async () => {
    const formValidation = await formik.validateForm();
    if (Object.keys(formValidation).length) { 
      return null 
    };
    setIsLoading(true);
    setErrorMessages(null);
    try {
      await PATCH(`${URL_WOOD_EXPORT.EXPORT_TRANSACTION}${params.id}/`, formik.values)
      await POST(`${URL_WOOD_EXPORT.EXPORT_TRANSACTION}${params.id}${URL_WOOD_EXPORT.INTERFACE}`)
      history.replace(PATH.EXPORT_TRANSACTION)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false)
    }
  }

  React.useEffect(() => {
    if (params.id) {
      fetchData();
    }
  }, [params.id])

  React.useEffect(() => {
    if (formik.values.sale_order_no && formik.values.container_no) {
      exportDocLineNoChoices.fetchData()
    }
  }, [formik.values.sale_order_no, formik.values.container_no])

  const editable = !formik.values.is_completed && !formik.values.is_interfaced;

  return (
    <div>
      <SubHeader/>
      <Container style={{ marginTop: '7em' }} textAlign='left'>
        { errorMessages && 
          <Message error>
            <Message.Header>{t('error')}</Message.Header>
            {errorMessages}
          </Message>
        }

        <Header as='h2'>{t('export_transactions.detail')}</Header>
        <Divider/>

        <Segment>
          <Dimmer inverted active={isLoading}>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
          <Form>
            <Form.Group widths='equal'>
              <Form.Input
                fluid
                label={t('date')}
                transparent
                readOnly
                value={new Date(formik.values.created).toLocaleString('en-GB')}/>
              <Form.Input
                fluid
                label={t('export_transactions.sale_order_no')}
                transparent
                readOnly
                value={formik.values.sale_order_no || '-'}/>
              <Form.Input
                fluid
                label={t('export_transactions.container_no')}
                transparent
                readOnly
                value={formik.values.container_no || '-'}/>
              <Form.Input
                fluid
                label={t('export_transactions.customer_code')}
                transparent
                readOnly
                value={formik.values.customer_code || '-'}/>
            </Form.Group>
            <Form.Group widths='4'>
              <Form.Input
                fluid
                label={t('export_transactions.export_doc')}
                transparent
                readOnly
                value={formik.values.export_doc || '-'}/>
              <Form.Dropdown
                search
                selection
                label={t('export_transactions.export_doc_line_no')}
                options={exportDocLineNoChoices.response}
                value={formik.values.export_doc_line_no}
                error={formik.errors.export_doc_line_no}
                onChange={(_, data) => {
                  if (!editable) return;
                  formik.setFieldValue('export_doc_line_no', data.value)
                }}
                />
            </Form.Group>
            { editable && 
              <div style={{ textAlign: 'right' }}>
                { editable && (
                  <Button
                    basic
                    color='blue'
                    icon='external alternate'
                    content={t('menus.interface_ax')}
                    onClick={() => setIsInterfaceConFirmModalOpen(true)}/>
                )}
                <Button
                  color='blue'
                  icon='save'
                  content={t('save')}
                  onClick={formik.handleSubmit}/>
              </div>
            }
            <Divider/>
          </Form>
          
          <ExportItemList
            editable={editable}
            exportTransactionId={params.id}/>

        </Segment>

      </Container>

      <ConfirmModal
        open={isInterfaceConFirmModalOpen}
        onClose={() => setIsInterfaceConFirmModalOpen(false)}
        title={t('confirms.interface_title')}
        content={t('confirms.interface_content')}
        onConfirm={() => handleDoInterfaceAX()}
      />
    </div>
  )
}
