import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Container, Form, Header, Popup } from 'semantic-ui-react';
import CustomTable from '../../components/common/CustomTable';
import MainHeader, { MENU_INTERFACE_AX } from '../../components/common/MainHeader';
import { URL_INTERFACE_AX } from '../../constance/urls';
import { POST } from '../../utils/HttpClient';
import { ellipsisString } from '../../utils/stringFormat';
import { Toast } from '../../utils/Toast';
import * as _ from 'lodash'
import InterfaceAXTransactionModal from '../../components/interface-ax/InterfaceAXTransactionModal';


export default function InterfaceAXBOMTransactionView() {

  const { t } = useTranslation();
  const [date, setDate] = React.useState('');
  const tableRef = React.useRef()
  const [target, setTarget] = React.useState(null);

  const columns = React.useMemo(() => [
    {
      Header: t('date'),
      accessor: 'date',
      Cell: ({ row }) => new Date(row.original.date).toLocaleString('en-GB')
    },
    {
      Header: t('bom_transactions.order_no'),
      accessor: 'order_no',
    },
    {
      Header: t('bom_transactions.description'),
      accessor: 'description',
      Cell: ({row}) => <Popup trigger={<div>{ellipsisString(row.original.description)}</div>} inverted content={row.original.description}/>
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({row}) => {
        const target = _.get(row, 'original.id', null);
        const progressing = _.get(row, 'original.interface_progressing', false)
        return (
          <div style={{ width: '180px' }}>
            <Button
              basic
              primary
              disabled={progressing}
              content={progressing ? t('progressing') : t('do_interface_ax')}
              onClick={() => !progressing ? handleDoInterfaceAX(target) : null}/>
            <Button
              basic
              primary
              icon='file alternate outline'
              onClick={() => setTarget(target)}/>
          </div>
        )
      }
    }
  ], []);

  const handleDoInterfaceAX = async (id) => {
    if (!tableRef.current) {
      return;
    }
    tableRef.current.setIsLoading(true);
    tableRef.current.setErrorMessages(null);
    try {
      const response = await POST(`${URL_INTERFACE_AX.BOM_TRANSACTION}${id}/do_interface/`)
      tableRef.current.refetch();
      Toast.success(t('success'))
    } catch (error) {
      tableRef.current.setErrorMessages(error.errorMessages);
    } finally {
      tableRef.current.setIsLoading(false);
    }
  }

  const dateFilterComponent = (
    <Form.Field
      inline
      label={t('searchs.fields.date')}
      locale="en-US"
      format="DD/MM/YYYY"
      showToday={true}
      placeholder={t('searchs.placeholders.date')}
      control={SemanticDatepicker}
      onChange={(_, data) => {
        if (data.value) {
          setDate(moment(data.value).format('YYYY-MM-DD'))
        } else {
          setDate('')
        }
      }}
    />
  )

  return (
		<div>
			<MainHeader activeItem={MENU_INTERFACE_AX}/>
			<Container style={{ marginTop: '7em' }}>
        <Header as='h2'>
          {t('menus.interface_ax')}: {t('menus.interface_ax_menus.bom')}
        </Header>
        <CustomTable
          ref={tableRef}
          fetchUrl={URL_INTERFACE_AX.BOM_TRANSACTION}
          columns={columns}
          showSearch
          showSize
          showPagination
          extraFilter={dateFilterComponent}
          extraFilterParams={{date: date}}
        />
        <InterfaceAXTransactionModal
          open={target != null}
          onClose={() => setTarget(null)}
          onInterfaceAX={handleDoInterfaceAX}
          url={URL_INTERFACE_AX.BOM_TRANSACTION}
          target={target}/>
			</Container>
		</div>
  )
}