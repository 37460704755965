export const defaultPostBakingWasteWoodCountWoodSizeAmountMapDetail = {
  transaction: null,
  wood_code: '',
  amount: 0,
  waste_wood_group: '',
  waste_wood_type: '',
}

export const defaultPostBakingWasteWoodCountDetail = {
  id: null,
  created: '',
  updated: '',
  post_baking_group_date: null,
  ovens: [],
  print_datetime: null,
  print_user: null,
  print_user_name: '',
  wood_size_amount_map_list: [],
  sum_amount: 0,
  sum_volume: 0,
}

export const OVEN_TYPE_SELECT = {
  ALL: 'all',
  SPECIFIC: 'specific',
  SET: 'set'
}


export const defaultPostBakingWasteWoodInputTrasactionDetail = {
  barcode: '',
  wood_size_amount_map_list: [],
  post_baking_group_date: '',
  ovens: [],
  print_datetime: null,
  print_user: null,
  print_user_name: '',
  is_interfaced_order: false,
  is_interfaced_bom: false,
}

export const defaultPostBakingWasteWoodInputDetail = {
  transaction: null,
  wood_size: '',
  wood_code: '',
  amount: null,
}

export const WASTE_WOOD_GROUP_TYPE = {
  FROM_OVEN: 'from_oven',
  FROM_GRADING: 'from_grading'
}


export const WASTE_WOOD_TYPE = {
  [WASTE_WOOD_GROUP_TYPE.FROM_OVEN]: {
    ORANGE_MOLD: 'orange_mold',
    WHITE_MOLD: 'white_mold',
    BENDED_WOOD_TO_SHAPE: 'bended_wood_to_shape',
    BENDED_WOOD_TO_DISCARD: 'bended_wood_to_discard',
    BROKEN_WOOD_TO_SHAPE: 'broken_wood_to_shape',
    BROKEN_WOOD_TO_DISCARD: 'broken_wood_to_discard',
  },
  [WASTE_WOOD_GROUP_TYPE.FROM_GRADING]: {
    GRADE_TO_AB: 'grade_to_ab',
    GRADE_TO_C: 'grade_to_c',
    GRADE_TO_P: 'grade_to_p',
    GRADE_TO_PP: 'grade_to_pp',
    WASTE_WOOD_DESTROY: 'waste_wood_destroy',
    WASTE_WOOD_SHAPE: 'waste_wood_shape',
  }
}


export const WASTE_WOOD_ORDER_TYPE = {
  GRADING_TYPE: 'grading_type',
  SHAPING_TYPE: 'shaping_type',
}
