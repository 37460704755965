import React from "react";
import ReportLogWoodDetail from "../../components/reports/ReportLogWoodDetail";
import { URL_LOG_WOOD } from "../../constance/urls";
import useDropdownItem from "../../hooks/useDropdownItem";
import { useTranslation } from "react-i18next";
import { formatComma } from "../../utils/stringFormat";

function ReportLogWoodSpeciesByCompanyView() {

  const logWoodSpeciesChoices = useDropdownItem({url: URL_LOG_WOOD.LOG_WOOD_SPECIES_GRADE});
  const { t } = useTranslation();

  const defaultColumns = [
    { 
      Header: "ลำดับที่", 
      columns: [{Header: "", accessor: "index", width: 80}] 
    },
    { 
      Header: "รหัสลูกค้า", 
      columns: [{ Header: "", accessor: "truck_company_code", width: 120 }]
    },
    { 
      Header: "ชื่อลูกค้า", 
      columns: [{Header: "", accessor: "truck_company_name", width: 220}] 
    }
  ];

  const [columns, setColumns] = React.useState(defaultColumns);

  React.useEffect(() => {
    if (!logWoodSpeciesChoices.response.length) {
      return;
    }
    setColumns([
      ...defaultColumns,
      ...logWoodSpeciesChoices.response.sort((a, b) => a.name < b.name ? -1 : 1).map(species => {
        return {
          Header: species.name,
          columns: [
            { Header: t("units.tonne"), accessor: `report.${species.name}_weight`, Cell: ({ value }) => formatComma(value, 3)},
            { Header: "%", accessor: `report.${species.name}_percent` },
          ]
        }
      }),
      {
        Header: t('datas.total'),
        columns: [
          { Header: t("units.tonne"), accessor: "report.total_weight", Cell: ({ value }) => formatComma(value, 3)},
          { Header: "%", accessor: "report.total_percent" },
        ]
      }
    ])
  }, [logWoodSpeciesChoices.response]);

  return (
    <>
			<ReportLogWoodDetail 
			title={t('menus.report_menus.log_wood_menus.log_wood_species_by_customer')}
			url={URL_LOG_WOOD.REPORT_LOG_WOOD_SPECIES_CUSTOMER}
			tableColumns={columns}
      exportUrl={URL_LOG_WOOD.REPORT_LOG_WOOD_SPECIES_CUSTOMER_EXPORT}
      hideCustomerFilter/>
    </>
  )
}

export default ReportLogWoodSpeciesByCompanyView;