import React from "react";
import { useTranslation } from 'react-i18next';
import MainHeader from "../../components/common/MainHeader";
import { Button, Container, Form, Header, Icon, List, Popup } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useFormik } from "formik";
import * as _ from "lodash";
import moment from "moment";
import * as Yup from "yup";
import { POST } from "../../utils/HttpClient";
import { URL_INTERFACE_AX, URL_POST_BAKING } from "../../constance/urls";
import { Toast } from "../../utils/Toast";
import ErrorMessage from "../../components/common/ErrorMessage";
import CustomTable from "../../components/common/CustomTable";
import InterfaceAXHistoryModal from "../../components/interface_ax/InterfaceAXHistoryModal";
import { INTERFACE_TYPE } from "../../constance/dataTypes";
import { formatComma } from "../../utils/stringFormat";

export default function InterfaceAxBOMWasteWoodView () {

  const { t } = useTranslation();
  const tableRef = React.useRef();
  const [isEmpty, setIsEmpty] = React.useState(true);
  const [allowInterfaceAX, setAllowInterfaceAX] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      date: Yup.date().required(t('error_messages.validation.required')).nullable()
    }),
    initialValues: { date: null },
    onSubmit: () => {
      if (tableRef.current) {
        tableRef.current.refetch();
      }
    }
  })

  const columns = React.useMemo(() => [
    {
      Header: t('barcodes.barcode_id'),
      accessor: 'barcode',
    },
    {
      Header: t('post_baking_waste_wood_count.group_date'),
      accessor: 'post_baking_group_date',
      Cell: ({ value }) => value ? moment(value).format('DD/MM/yyyy') : '-',
    },
    {
      Header: t('wood_size'),
      Cell: ({ row }) => {
        const value = _.get(row, 'original.wood_size_amount_map_list', []).filter(e => e.is_interfaced_order)
        return value ? (
            <List>
               { value.filter(data => _.get(data, 'amount', 0)).map(data => (
                 <List.Item>{_.get(data, 'wood_code', '')} - {`(${formatComma(_.get(data, 'amount', 0))})`} {formatComma(_.get(data, 'volume', 0))}</List.Item>
               )) }
            </List>
        ) : '-'
      }
    },
    {
      Header: t('interface_ax.is_interfaced'),
      accessor: 'is_interfaced_bom',
      Cell: ({ row }) => {
        const value = _.get(row, 'original.wood_size_amount_map_list', []).filter(e => e.is_interfaced_order)
        return value ? (
            <List>
               { value.filter(data => _.get(data, 'amount', 0)).map(data => (
                 <List.Item>
                    <Icon name={data.is_interfaced_bom ? 'check' : 'cancel'} color={data.is_interfaced_bom ? 'green' : 'red'}/>
                 </List.Item>
               )) }
            </List>
        ) : '-'
      }
    },
  ])

  const handleDoInterfaceAX = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await POST(URL_INTERFACE_AX.BOM_WASTE_WOOD, { date: moment(formik.values.date).format('YYYY-MM-DD') })
      if (tableRef.current) {
        tableRef.current.reset();
      }
      Toast.info(t('progressing'))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    setAllowInterfaceAX(!isEmpty);
  }, [isEmpty])

  React.useEffect(() => {
    if (allowInterfaceAX) {
      setAllowInterfaceAX(false);
    }
  }, [formik.values])

  return (
    <div>

      <MainHeader/>
			<Container style={{ marginTop: '7em' }}>
        <Header as='h2'>
          {t('menus.interface_ax')}: {t('menus.interface_ax_menus.bom_waste_wood')}
        </Header>

        <Form>
          <Form.Group inline style={{ justifyContent: 'center' }}>
            <Form.Field
              id='form-input-control-dateRange'
              name='dateRange'
              label={t('post_baking_waste_wood_input.input_date')}
              control={SemanticDatepicker}
              locale="en-US"
              format="DD/MM/YYYY"
              showToday={true}
              placeholder={t('searchs.fields.date')}
              value={formik.values.date}
              error={formik.errors.date}
              onChange={(_, data) => formik.setFieldValue('date', data.value)}
            />
            <Form.Button
              primary
              icon='search'
              content={t('search')}
              onClick={formik.handleSubmit}/>
            <Button
              type='button'
              primary
              basic
              icon='file alternate outline'
              content={t('interface_ax.history')}
              onClick={() => setOpenHistory(true)}/>
          </Form.Group>
        </Form>

        <Popup
          inverted
          content={`${t('select')} ${t('searchs.fields.date')}`}
          on='hover'
          position='bottom center'
          trigger={
            <span>
              <Button
                basic
                primary
                loading={loading}
                disabled={!allowInterfaceAX}
                content={t('interface_ax.submit')}
                onClick={() => handleDoInterfaceAX()}/>
            </span>
          }/>

        <ErrorMessage message={errorMessages} length={100}/>

        <CustomTable
          ref={tableRef}
          columns={columns}
          fetchUrl={URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}
          manual
          showPagination
          showSize
          setIsEmpty={setIsEmpty}
          extraFilterParams={{ 
            input_date: moment(formik.values.date).format('YYYY-MM-DD'),
            is_interfaced_order: true,
          }}/>
			</Container>
      <InterfaceAXHistoryModal
        open={openHistory}
        onClose={() => setOpenHistory(false)}
        type={INTERFACE_TYPE.BOM_WASTE_WOOD}/>
    </div>
  )
}