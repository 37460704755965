import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Modal, TextArea } from 'semantic-ui-react';
import propTypes from 'prop-types';


function ConfirmModal (props) {
  const {
    open,
    onClose,
    title,
    content,
    onConfirm,
    showMemo,
    isMemoRequired
  } = props;

  const { t } = useTranslation();
  const [memo, setMemo] = React.useState('');
  const [memoValidationError, setMemoValidationError] = React.useState('');

  React.useEffect(() => {
    setMemo('');
  }, [open])

  const handleConfirmAction = () => {
    if (isMemoRequired && !memo) {
      setMemoValidationError(true)
      return;
    }
    onClose();
    if (showMemo) {
      onConfirm(memo);
    } else {
      onConfirm();
    }
  }

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
    >
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        {content}
        { showMemo &&
          <Form style={{marginTop: '1rem'}}>
            <Form.Field error={memoValidationError}>
              <label>{t('memo')}</label>
              <TextArea
                placeholder={t('memo')}
                value={memo}
                onChange={(_, data) => setMemo(data.value)}
              />
            </Form.Field>
          </Form>        
        }
      </Modal.Content>
      <Modal.Actions>
        <Button
          icon='cancel'
          content={t('cancel')}
          onClick={onClose}
        />
        <Button
          positive
          icon='check'
          content={t('confirm')}
          onClick={handleConfirmAction}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default React.memo(ConfirmModal);

ConfirmModal.defaultProps = {
  open: false,
  onClose: () => null,
  title: '',
  content: '',
  onConfirm: () => null,
  showMemo: false,
  isMemoRequired: false,
}

ConfirmModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  title: propTypes.string,
  content: propTypes.string,
  onConfirm: propTypes.func,
  showMemo: propTypes.bool,
  isMemoRequired: propTypes.bool
}
