import React from 'react';
import { Form, Button, Icon, Popup } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../common/CustomTable';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';
import { URL_BARCODE } from '../../constance/urls';
import FactoryWoodExportDetailModal from './FactoryWoodExportDetailModal';
import { defaultFactoryWoodExportTransactionDetail } from '../../constance/dataTypes';
import { ellipsisString } from '../../utils/stringFormat';


export default function FactoryWoodExportList() {

  const { t } = useTranslation();
  const [date, setDate] = React.useState('');
  const [status, setStatus] = React.useState('UNFINISHED');
  const tableRef = React.useRef();

  // modal
  const [isDetailModalOpen, setIsDetailModalOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(defaultFactoryWoodExportTransactionDetail);

  const columns = React.useMemo(() => [
    {
      Header: t('date'),
      accessor: 'created',
      Cell: ({ row }) => new Date(row.original.created).toLocaleString('en-GB')
    },
    {
      Header: t('factory_wood_exports.order_no'),
      accessor: 'order_no',
    },
    {
      Header: t('factory_wood_exports.destination_factory'),
      accessor: 'destination_factory',
    },
    {
      Header: t('trucks.license_plate'),
      accessor: 'license_plate',
    },
    {
      Header: t('factory_wood_exports.is_exported'),
      accessor: 'is_exported',
      Cell: ({ row }) => <Icon color={row.original.is_exported ? 'green' : 'red'} name={row.original.is_exported ? 'check' : 'cancel'} />
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({ row }) => (
        <div style={{textAlign: 'center'}}>
          <Button
            basic
            color={row.original.truck_transactions.length > 0 ? 'blue' : 'green'}
            icon={row.original.truck_transactions.length > 0 ? 'file alternate outline' : 'add'}
            content={row.original.truck_transactions.length > 0 ? t('detail') : `${t('select')} ${t('trucks.license_plate')}`}
            onClick={() => {
              setIsDetailModalOpen(true)
              setSelected(row.original.id)
            }}
          />
          { row.original.truck_transactions.length > 0 &&
            <Button
              basic
              icon='print'
              primary
              content={t('factory_wood_exports.print')}
              onClick={() => window.open(`${URL_BARCODE.WOOD_EXPORT_TRANSACTION_EXPORT}${row.original.id}`)}
            />
          }
        </div>
      )
    }
  ], []);

  const statusFilterComponent = (
    <Form.Select
      label={t('status')}
      options={[
        { key: 'ALL', value: 'ALL', text: t('factory_wood_exports.status.all') },
        { key: 'UNFINISHED', value: 'UNFINISHED', text: t('factory_wood_exports.status.unfinished') },
        { key: 'FINISHED', value: 'FINISHED', text: t('factory_wood_exports.status.finished') },
        { key: 'EXPORTED', value: 'EXPORTED', text: t('factory_wood_exports.status.exported') },
      ]}
      defaultValue={status}
      onChange={(_, data) => setStatus(data.value)}
    />
  )

  const dateFilterComponent = (
    <Form.Field
      label={t('searchs.fields.date')}
      locale='en-US'
      format='DD/MM/YY'
      showToday={true}
      placeholder={t('searchs.placeholders.date')}
      control={SemanticDatepicker}
      onChange={(_, data) => {
        if (data.value) {
          setDate(moment(data.value).format('YYYY-MM-DD'))
        } else {
          setDate('')
        }
      }}
    />
  )

  return (
    <div>
      <FactoryWoodExportDetailModal
        open={isDetailModalOpen}
        onClose={() => {
          setIsDetailModalOpen(false)
          if (tableRef) {
            tableRef.current.refetch()
          }
        }}
        detail={selected}
      />      
      <CustomTable
        ref={tableRef}
        fetchUrl={URL_BARCODE.WOOD_EXPORT_TRANSACTION}
        columns={columns}
        showSearch
        showSize
        showPagination
        extraFilter={[
          statusFilterComponent,
          dateFilterComponent
        ]}
        extraFilterParams={{ date: date, status: status }}
      />
    </div>
  );
}