import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import MainHeader, { MENU_FACTORY_WOOD_EXPORT } from '../components/common/MainHeader';
import { Media } from "../AppMedia";
import { useTranslation } from 'react-i18next';
import FactoryWoodExportList from '../components/factory-wood-export/FactoryWoodExportList';


export default function FactoryWoodExportListView() {

  const { t } = useTranslation();

  return (
    <div>
      <MainHeader activeItem={MENU_FACTORY_WOOD_EXPORT}/>

      <Container style={{ marginTop: '7em' }}>
        <Header as='h2'>{t('menus.wood_menus.factory_wood_export')}</Header>

        <FactoryWoodExportList/>
			</Container>
    </div>
  );
}