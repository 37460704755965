import React from 'react';
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoutes";
import LoginView from '../views/LoginView';
import LogWoodPOListView from "../views/LogWoodPOListView";
import LogWoodPODetailView from '../views/LogWoodPODetailView';
import LiffLoginView from "../views/liff/LiffLoginView";
import InterfaceAXLogWoodPOView from "../views/interface-ax/InterfaceAXLogWoodPOView"
import ReportLogWoodLengthView from "../views/po-reports/ReportLogWoodLengthView";
import ReportLogWoodLengthByCompanyView from "../views/po-reports/ReportLogWoodLengthByCompanyView";
import ReportLogWoodSpeciesView from "../views/po-reports/ReportLogWoodSpeciesView";
import ReportLogWoodSpeciesByCompanyView from "../views/po-reports/ReportLogWoodSpeciesByCompanyView";
import ReportLogWoodCutWeightView from "../views/po-reports/ReportLogWoodCutWeightView";
import WoodImportListView from '../views/WoodImportListView';
import WoodImportDetailView from "../views/WoodImportDetailView";
import WoodImportSettingsPriceListView from '../views/WoodImportSettingsPriceListView';
import FactoryWoodExportListView from '../views/FactoryWoodExportListView';
import ReportWoodImportListView from '../views/wood-import-reports/ReportWoodImportListView';
import ReportWoodImportByCompany from '../views/wood-import-reports/ReportWoodImportByCompany';
import ReportWoodImportByCompanyDate from '../views/wood-import-reports/ReportWoodImportByCompanyDate';
import ReportWoodImportByWoodCode from '../views/wood-import-reports/ReportWoodImportByWoodCode';
import ReportWoodImportByWoodThick from '../views/wood-import-reports/ReportWoodByWoodThick';
import ReportWoodImportByVolume from '../views/wood-import-reports/ReportWoodImportByVolume';
import UserProfileView from '../views/UserProfileView';
import InterfaceAXMovementIssueView from '../views/interface-ax/InterfaceAXMovementIssueView';
import InterfaceAXMovementReceiptView from '../views/interface-ax/InterfaceAXMovementReceiptView';
import InterfaceAXProcessedWoodPOView from '../views/interface-ax/InterfaceAXProcessedWoodPOView';
import ReportWoodImportOutGrade from '../views/wood-import-reports/ReportWoodImportOutGrade';
import NotFoundView from '../views/NotFoundView';
import AdminView from '../views/AdminView';
import BOMListView from '../views/BOMListView';
import BOMDetailView from '../views/BOMDetailView';
import InterfaceAXBOMTransactionView from '../views/interface-ax/InterfaceAXBOMTransactionView';
import ExportTransactionListView from '../views/ExportTransactionListView';
import ExportTransactionDetailView from '../views/ExportTransactionDetailView';
import InterfaceAXExportTransactionView from '../views/interface-ax/InterfaceAXExportTransactionView';
import PostBakingWasteWoodCountView from '../views/PostBakingWasteWoodCountView';
import PostBakingWasteWoodCountListView from '../views/waste-wood-reports/PostBakingWasteWoodCountListView';
import PostBakingWasteWoodCountDetailView from '../views/waste-wood-reports/PostBakingWasteWoodCountDetailView';
import InterfaceAXPostBakingWasteWoodCountView from '../views/interface-ax/InterfaceAXPostBakingWasteWoodCountView';
import PostBakingWasteWoodInputView from '../views/PostBakingWasteWoodInputView';
import PostBakingWasteWoodInputListView from '../views/waste-wood-reports/PostBakingWasteWoodInputListView';
import PostBakingWasteWoodInputDetailView from '../views/waste-wood-reports/PostBakingWasteWoodInputDetailView';
import PostBakingWasteWoodInputSummaryReportView from '../views/waste-wood-reports/PostBakingWasteWoodInputSummaryReportView';
import InterfaceAxWasteWoodOrderView from '../views/interface-ax/InterfaceAxWasteWoodOrderView';
import InterfaceAxBOMWasteWoodView from '../views/interface-ax/InterfaceAxBOMWasteWoodView';
import InterfaceAxWasteWoodOrderGradingView from '../views/interface-ax/InterfaceAxWasteWoodOrderGradingView';
import PostBakingWasteWoodSummaryReportView from '../views/waste-wood-reports/PostBakingWasteWoodSummaryReportView';

export const LIFF_PATH = {  // Set Line LIFF path to /liff/<app_name>/
  LOG_WOOD_PO: 'log-wood-po',
}

export const PATH = {
  LIFF_LANDING: "/liff/:app",
  LOGIN: '/login/',
  HOME: '/',
  API: '/api/',
  USER_PROFILE: '/profile/',
  ADMIN: '/admin/',

  // wood menu
  LOG_WOOD_PO: '/log-wood-po/',
  WOOD_IMPORT: '/wood-import/',
  WOOD_IMPORT_SETTINGS_PRICE: '/wood-import-settings/',
  FACTORY_WOOD_EXPORT: '/factory-wood-export/',
  BOM: '/bom/',
  EXPORT_TRANSACTION: '/export-transaction/',

  // log wood po report
  REPORT_LOG_WOOD_LENGTH: '/report-log-wood-length/',
  REPORT_LOG_WOOD_LENGTH_COMPANY: '/report-log-wood-length-company/',
  REPORT_LOG_WOOD_SPECIES: '/report-log-wood-species/',
  REPORT_LOG_WOOD_SPECIES_COMPANY: '/report-log-wood-species-company/',
  REPORT_LOG_WOOD_CUT_WEIGHT: '/report-log-wood-cut-weight/',

  // wood import report
  REPORT_WOOD_IMPORT: '/report-wood-import/',
  REPORT_WOOD_IMPORT_COMPANY: '/report-wood-import-company/',
  REPORT_WOOD_IMPORT_COMPANY_DATE: '/report-wood-import-company-date/',
  REPORT_WOOD_IMPORT_THICK: '/report-wood-import-thick/',
  REPORT_WOOD_IMPORT_VOLUME: '/report-wood-import-volume/',
  REPORT_WOOD_IMPORT_WOOD_CODE: '/report-wood-import-wood-code/',
  REPORT_WOOD_IMPORT_OUT_GRADE: '/report-wood-import-out-grade/',

  // interface ax
  INTERFACE_AX_LOG_WOOD_PO: '/interface-ax-log-wood-po/',
  INTERFACE_AX_PROCESSED_WOOD_PO: '/interface-ax-processed-wood-po/',
  INTERFACE_AX_MOVEMENT_RECEIPT: '/interface-ax-movement-receipt/',
  INTERFACE_AX_MOVEMENT_ISSUE: '/interface-ax-movement-issue/',
  INTERFACE_AX_BOM_TRANSACTION: '/interface-ax-bom-transaction/',
  INTERFACE_AX_EXPORT_TRANSACTION: '/interface-ax-export-transaction/',
  INTERFACE_AX_POST_BAKING_WASTE_WOOD_COUNT: '/interface-ax-post-baking-waste-wood-count/',
  INTERFACE_AX_WASTE_WOOD_ORDER: '/interface-ax-waste-wood-order/',
  INTERFACE_AX_WASTE_WOOD_ORDER_GRADING: '/interface-ax-waste-wood-order-grading/',
  INTERFACE_AX_BOM_WASTE_WOOD: '/interface-ax-bom-waste-wood/',

  // post baking waste wood
  POST_BAKING_WASTE_WOOD_COUNT: '/post-baking-waste-wood-count/',
  POST_BAKING_WASTE_WOOD_INPUT: '/post-baking-waste-wood-input/',

  // waste wood report
  POST_BAKING_WASTE_WOOD_COUNT_REPORT: '/post-baking-waste-wood-count-report/',
  POST_BAKING_WASTE_WOOD_INPUT_REPORT: '/post-baking-waste-wood-input-report/',
  POST_BAKING_WASTE_WOOD_SUMMARY_REPORT: '/post-baking-waste-wood-summary-report/',
  POST_BAKING_WASTE_WOOD_INPUT_SUMMARY_REPORT: '/post-baking-waste-wood-input-summary-report/',
}

export default function Routes(){
  return (
    <Switch>
      <Route exact path={PATH.LOGIN} component={LoginView} />
      <Route exact path={PATH.LIFF_LANDING} component={LiffLoginView} />
      <PrivateRoute exact path={PATH.USER_PROFILE} component={UserProfileView}/>
      <PrivateRoute exact path={PATH.ADMIN} component={AdminView}/>

      {/* wood menu */}
      <PrivateRoute exact path={PATH.HOME} component={LogWoodPOListView} />
      <PrivateRoute exact path={PATH.LOG_WOOD_PO} component={LogWoodPOListView} />
      <PrivateRoute exact path={`${PATH.LOG_WOOD_PO}:id`} component={LogWoodPODetailView} />
      <PrivateRoute exact path={PATH.WOOD_IMPORT} component={WoodImportListView} />
      <PrivateRoute exact path={`${PATH.WOOD_IMPORT}:id`} component={WoodImportDetailView} />
      <PrivateRoute exact path={PATH.WOOD_IMPORT_SETTINGS_PRICE} component={WoodImportSettingsPriceListView} />
      <PrivateRoute exact path={PATH.FACTORY_WOOD_EXPORT} component={FactoryWoodExportListView} />

      {/* log wood po report */}
      <PrivateRoute exact path={PATH.REPORT_LOG_WOOD_LENGTH} component={ReportLogWoodLengthView}/>
      <PrivateRoute exact path={PATH.REPORT_LOG_WOOD_LENGTH_COMPANY} component={ReportLogWoodLengthByCompanyView}/>
      <PrivateRoute exact path={PATH.REPORT_LOG_WOOD_SPECIES} component={ReportLogWoodSpeciesView}/>
      <PrivateRoute exact path={PATH.REPORT_LOG_WOOD_SPECIES_COMPANY} component={ReportLogWoodSpeciesByCompanyView}/>
      <PrivateRoute exact path={PATH.REPORT_LOG_WOOD_CUT_WEIGHT} component={ReportLogWoodCutWeightView}/>

      {/* wood import report */}
      <PrivateRoute exact path={PATH.REPORT_WOOD_IMPORT} component={ReportWoodImportListView}/>
      <PrivateRoute exact path={PATH.REPORT_WOOD_IMPORT_COMPANY} component={ReportWoodImportByCompany}/>
      <PrivateRoute exact path={PATH.REPORT_WOOD_IMPORT_COMPANY_DATE} component={ReportWoodImportByCompanyDate}/>
      <PrivateRoute exact path={PATH.REPORT_WOOD_IMPORT_THICK} component={ReportWoodImportByWoodThick}/>
      <PrivateRoute exact path={PATH.REPORT_WOOD_IMPORT_VOLUME} component={ReportWoodImportByVolume}/>
      <PrivateRoute exact path={PATH.REPORT_WOOD_IMPORT_WOOD_CODE} component={ReportWoodImportByWoodCode}/>
      <PrivateRoute exact path={PATH.REPORT_WOOD_IMPORT_OUT_GRADE} component={ReportWoodImportOutGrade}/>

      {/* bom */}
      <PrivateRoute exact path={PATH.BOM} component={BOMListView}/>
      <PrivateRoute exact path={`${PATH.BOM}:id`} component={BOMDetailView}/>

      {/* wood export */}
      <PrivateRoute exact path={PATH.EXPORT_TRANSACTION} component={ExportTransactionListView}/>
      <PrivateRoute exact path={`${PATH.EXPORT_TRANSACTION}:id`} component={ExportTransactionDetailView}/>

      {/* interface ax */}
      <PrivateRoute exact path={PATH.INTERFACE_AX_LOG_WOOD_PO} component={InterfaceAXLogWoodPOView} />
      <PrivateRoute exact path={PATH.INTERFACE_AX_PROCESSED_WOOD_PO} component={InterfaceAXProcessedWoodPOView} />
      <PrivateRoute exact path={PATH.INTERFACE_AX_MOVEMENT_RECEIPT} component={InterfaceAXMovementReceiptView} />
      <PrivateRoute exact path={PATH.INTERFACE_AX_MOVEMENT_ISSUE} component={InterfaceAXMovementIssueView} />
      <PrivateRoute exact path={PATH.INTERFACE_AX_MOVEMENT_ISSUE} component={InterfaceAXMovementIssueView} />
      <PrivateRoute exact path={PATH.INTERFACE_AX_BOM_TRANSACTION} component={InterfaceAXBOMTransactionView} />
      <PrivateRoute exact path={PATH.INTERFACE_AX_EXPORT_TRANSACTION} component={InterfaceAXExportTransactionView} />
      <PrivateRoute exact path={PATH.INTERFACE_AX_POST_BAKING_WASTE_WOOD_COUNT} component={InterfaceAXPostBakingWasteWoodCountView} />
      <PrivateRoute exact path={PATH.INTERFACE_AX_WASTE_WOOD_ORDER_GRADING} component={InterfaceAxWasteWoodOrderGradingView} />
      <PrivateRoute exact path={PATH.INTERFACE_AX_WASTE_WOOD_ORDER} component={InterfaceAxWasteWoodOrderView} />
      <PrivateRoute exact path={PATH.INTERFACE_AX_BOM_WASTE_WOOD} component={InterfaceAxBOMWasteWoodView} />

      {/* post baking waste wood count */}
      <PrivateRoute exact path={PATH.POST_BAKING_WASTE_WOOD_COUNT} component={PostBakingWasteWoodCountView} />
      {/* post baking wastw wood input */}
      <PrivateRoute exact path={PATH.POST_BAKING_WASTE_WOOD_INPUT} component={PostBakingWasteWoodInputView} />

      {/* waste wood report */}
      <PrivateRoute exact path={PATH.POST_BAKING_WASTE_WOOD_COUNT_REPORT} component={PostBakingWasteWoodCountListView} />
      <PrivateRoute exact path={`${PATH.POST_BAKING_WASTE_WOOD_COUNT_REPORT}:id`} component={PostBakingWasteWoodCountDetailView} />
      <PrivateRoute exact path={PATH.POST_BAKING_WASTE_WOOD_INPUT_REPORT} component={PostBakingWasteWoodInputListView} />
      <PrivateRoute exact path={`${PATH.POST_BAKING_WASTE_WOOD_INPUT_REPORT}:id`} component={PostBakingWasteWoodInputDetailView} />
      <PrivateRoute exact path={PATH.POST_BAKING_WASTE_WOOD_SUMMARY_REPORT} component={PostBakingWasteWoodSummaryReportView} />
      <PrivateRoute exact path={PATH.POST_BAKING_WASTE_WOOD_INPUT_SUMMARY_REPORT} component={PostBakingWasteWoodInputSummaryReportView} />

      <Route component={NotFoundView}/>
    </Switch>
  )
}