import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Header, Image } from 'semantic-ui-react';
import logo from '../assets/logo.png';
import { PATH } from '../routes/Routes';


export default function NotFoundView() {

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div className='not-found'>
      <style>
        {`
          body > div,
          body > div > div,
          body > div > div > div.not-found {
            height: 100%;
          }
        `}
      </style>
        <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle' centered>
          <Grid.Column textAlign='center'>
            <Image src={logo} size='medium' centered/>
            <Header color='green' style={{fontSize: '6rem'}}>404</Header>
            <Header size='large' color='grey'>{t('error_messages.page_not_found')}</Header>
            <Button 
              content={t('back')}
              color='green'
              size='big'
              icon='arrow left'
              onClick={() => history.push(PATH.HOME)}/>
          </Grid.Column>
        </Grid>
    </div>
  )
}