import React from 'react';
import { Container, Header, Button, Icon } from 'semantic-ui-react';
import MainHeader, { MENU_WOOD_IMPORT } from '../components/common/MainHeader';
import { Media } from "../AppMedia";
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { PATH } from '../routes/Routes';
import WoodImportList from '../components/wood-import/WoodImportList';
import WoodImportMobileList from '../components/wood-import/mobile/WoodImportMobileList';
import Cookies from 'js-cookie';


export default function WoodImportListView() {

	const { t } = useTranslation();
	const history = useHistory();
  const [user] = React.useState(JSON.parse(Cookies.get('user', {})));

  return (
    <div>
      <MainHeader activeItem={MENU_WOOD_IMPORT}/>

			<Container style={{ marginTop: '7em' }}>
				<Header as='h2'>{t('menus.wood_menus.wood_import')}</Header>
				{ user.groups && user.groups.length > 0 && user.groups.includes('officer') &&
					<div style={{textAlign: "right", marginBottom: 12}}>
						<Button 
							positive
							onClick={() => history.push(`${PATH.WOOD_IMPORT}add/`)}>
							<Icon name={"add"}/>
							{t('wood_imports.add')}
						</Button>
					</div>
				}
				<Media lessThan={"md"}>
					<WoodImportMobileList/>
				</Media>

				<Media greaterThanOrEqual="md">
					<WoodImportList/>
				</Media>
			</Container>
    </div>
  );
}