import React from 'react';
import { Dropdown, Input } from 'semantic-ui-react';


export default function TimePicker (props) {
  const {
    label,
    error,
    onChange,
  } = props

  const hourOptions = Array.from({length: 24}, (_, i) => {
    const value = i.toLocaleString('en-Us', {minimumIntegerDigits: 2, useGrouping: false})
    return {key: i, value: value, text: value}
  })
  const minuteOptions = Array.from({length: 60}, (_, i) => {
    const value = i.toLocaleString('en-Us', {minimumIntegerDigits: 2, useGrouping: false})
    return {key: i, value: value, text: value}
  })

  const [hour, setHour] = React.useState(null)
  const [minute, setMinute] = React.useState(null)

  React.useEffect(() => {
    if (hour && minute && onChange) {
      onChange(`${hour}:${minute}`)
    }
  }, [hour, minute])

  return (
    <div class='inline field'>
      <label>{label}</label>
      <Dropdown
        placeholder='--'
        search
        selection
        compact
        options={hourOptions}
        value={hour}
        error={error}
        onChange={(_, data) => setHour(data.value)}
      />
      <span style={{ marginLeft: '1rem', marginRight: '1rem', fontSize: '24px' }}>:</span>
      <Dropdown
        placeholder='--'
        search
        selection
        value={minute}
        error={error}
        options={minuteOptions}
        onChange={(_, data) => setMinute(data.value)}
      />
    </div>
  )
}