import React from 'react';
import { FETCH_DATA_LIST } from '../constance/dataTypes';
import { GET } from '../utils/HttpClient';
import { Toast } from '../utils/Toast';


export default function useSearchItem ({url, use_pagination=false, title_render, description_render}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const [response, setResponse] = React.useState(use_pagination ? FETCH_DATA_LIST : []);

  const fetchData = async (params) => {
    setIsLoading(true);
    try {
      console.log("Searching : " + url)
      const response = await GET(url, params);
      const responseData = use_pagination ? response.data.results : response.data
      setResponse(responseData.map(item => {
        return {
          ...item,
          key: item.id,
          value: item.id,
          title: item[title_render],
          description: item[description_render],
        }
      }));
    } catch (error) {
      console.log("Error")
      Toast.error(error.errorMessages);
    } finally {
      console.log('Done')
      setIsLoading(false);
    }
  };

  return { response, isLoading, fetchData };
}