import React from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Grid, Input, Message, Modal, Search } from 'semantic-ui-react';
import useItemDetail from '../../hooks/useItemDetail';
import { URL_SCALE_LARGE } from '../../constance/urls';
import { useFormik } from 'formik';
import { defaultTruckCompanyDetail, defaultWoodSizeBuyPriceMapDetail, WOOD_IMPORT_GRADE_DISPLAY } from '../../constance/dataTypes';
import * as Yup from 'yup';
import useSearchItem from '../../hooks/useSeatchItem';
import { Toast } from '../../utils/Toast';


const WoodImportSettingsPriceModal = (props) => {
	
	const {
		open,
		onClose,
		detail,
		woodSizeBuyPriceMapDetail
	} = props;

	const { t } = useTranslation();

	const truckCompanies = useSearchItem({url: URL_SCALE_LARGE.TRUCK_COMPANY, description_render: 'name', title_render: 'code'});
  const truckCompanyDetail = useItemDetail({
    url: URL_SCALE_LARGE.TRUCK_COMPANY,
    id: detail ? detail.truck_company : null,
    manual: true,
    readOnly: true,
  });

  const truckCompanyFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: truckCompanyDetail.response || defaultTruckCompanyDetail,
  });

  const woodImportTransactionValidationSchema = Yup.object().shape({
    wood_code: Yup.string().required(t('error_messages.validation.required')).length(10, `${t('error_messages.validation.length')} (10 ${t('units.character')})`),
    truck_company: Yup.string().required(t('error_messages.validation.required')),
		vat: Yup.boolean().required(t('error_messages.validation.required')),
  })

	const WoodSizeBuyPriceMapFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: woodImportTransactionValidationSchema,
    initialValues: detail || defaultWoodSizeBuyPriceMapDetail,
		onSubmit: async (values) => {
			const isSaveTruckCompanySuccess = (truckCompanyFormik.values.code !== detail.truck_company) ? 
				await truckCompanyDetail.handleCreate(truckCompanyFormik.values) :
				true;
			if (!isSaveTruckCompanySuccess) {
				Toast.error(`${t('save')} ${t('error')}`)
				return
			}

			let isSuccess = false;
			if (detail.id) {
				isSuccess = await woodSizeBuyPriceMapDetail.handleSave(values)
			} else {
				isSuccess = await woodSizeBuyPriceMapDetail.handleCreate(values)
			}
			if (!isSuccess) {
				Toast.error(`${t('save')} ${t('error')}`)
				return
			}
		},
	})

	React.useEffect(() => {
		if (detail.id) {
			truckCompanyDetail.fetchData();
		} else {
			truckCompanyFormik.resetForm();
			WoodSizeBuyPriceMapFormik.resetForm()
		}
	}, [open])

	return (
		<Modal
			open={open}
			onClose={onClose}
			closeIcon
		>
			<Modal.Header>
				{detail.id ? t('wood_imports.edit_code') : t('wood_imports.add_code')}
			</Modal.Header>
			<Modal.Content>
				{ (
						truckCompanyDetail.errorMessages ||
						woodSizeBuyPriceMapDetail.errorMessages
					) &&
					<Message error>
						<Message.Header>{t('error')}!</Message.Header>
						<label>{ truckCompanyDetail.errorMessages }</label>
						<label>{ woodSizeBuyPriceMapDetail.errorMessages }</label>
					</Message>
				}
				<Form>
					<Form.Field
						fluid
						label={t('truck_transactions.company')}
						placeholder={`${t('search')} ${t('searchs.fields.code')} ${t('or')} ${t('searchs.fields.name')}...`}
						minCharacters={2}
						control={Search}
						results={truckCompanies.response}
						showNoResults={true}
						onSearchChange={(_ ,data) => {
							truckCompanies.fetchData({search: data.value})
							if (truckCompanyFormik.values.id) {
								truckCompanyFormik.setValues(defaultTruckCompanyDetail);
								WoodSizeBuyPriceMapFormik.setFieldValue('truck_company', null)
							}
							truckCompanyFormik.setFieldValue('name', data.value)
						}}
						onResultSelect={(_, data) => {
							truckCompanyFormik.setValues(data.result);
							WoodSizeBuyPriceMapFormik.setFieldValue('truck_company', data.result.code)
						}}
						value={truckCompanyFormik.values.name}
						error={WoodSizeBuyPriceMapFormik.errors.truck_company}
						loading={truckCompanies.isLoading}
					/>
					<Form.Checkbox
						name='vat'
						label={t('wood_imports.vat')}
						checked={WoodSizeBuyPriceMapFormik.values.vat}
						onChange={(_, data) => {
							WoodSizeBuyPriceMapFormik.setFieldValue('vat', data.checked)
						}}
					/>
					<Form.Input
						label={t('wood_imports.wood_code')}
						placeholder={`${t('wood_imports.wood_code')}...`}
						name='wood_code'
						value={WoodSizeBuyPriceMapFormik.values.wood_code}
						error={WoodSizeBuyPriceMapFormik.errors.wood_code}
						onChange={WoodSizeBuyPriceMapFormik.handleChange}
					/>

					<Grid columns='2'>
						<Grid.Column>
							<Form.Field label={t(`wood_imports.buy_price`)}/>
							{WOOD_IMPORT_GRADE_DISPLAY.map(grade => (
								<Form.Field>
									<label>{t(`wood_imports.grades.${grade}`)}</label>
									<Input
										label={{ basic: true, content: t('units.baht') }}
										labelPosition='right'					
										placeholder={`${t(`wood_imports.buy_price`)}`}
										name={`buy_${grade}`}
										type='number'
										min='0'
										value={WoodSizeBuyPriceMapFormik.values[`buy_${grade}`]}
										error={WoodSizeBuyPriceMapFormik.errors[`buy_${grade}`]}
										onChange={WoodSizeBuyPriceMapFormik.handleChange}
										onBlur={() => {
											if (WoodSizeBuyPriceMapFormik.values[`buy_${grade}`] === '') {
												WoodSizeBuyPriceMapFormik.setFieldValue(`buy_${grade}`, 0)
											}
										}}/>
								</Form.Field>
							))}
						</Grid.Column>
						<Grid.Column>
							<Form.Field label={t(`wood_imports.sell_price`)}/>
							{WOOD_IMPORT_GRADE_DISPLAY.map(grade => (
								<Form.Field>
									<label>{t(`wood_imports.grades.${grade}`)}</label>
									<Input
										label={{ basic: true, content: t('units.baht') }}
										labelPosition='right'					
										placeholder={`${t(`wood_imports.sell_price`)}`}
										name={`sell_${grade}`}
										type='number'
										min='0'
										value={WoodSizeBuyPriceMapFormik.values[`sell_${grade}`]}
										error={WoodSizeBuyPriceMapFormik.errors[`sell_${grade}`]}
										onChange={WoodSizeBuyPriceMapFormik.handleChange}
										onBlur={() => {
											if (WoodSizeBuyPriceMapFormik.values[`sell_${grade}`] === '') {
												WoodSizeBuyPriceMapFormik.setFieldValue(`sell_${grade}`, 0)
											}
										}}/>
								</Form.Field>
							))}
						</Grid.Column>
					</Grid>
				</Form>
			</Modal.Content>
			<Modal.Actions>
				<Button
					icon='cancel'
					content={t('cancel')}
					loading={truckCompanyDetail.isLoading || woodSizeBuyPriceMapDetail.isLoading}
					onClick={props.onClose}
				/>
				<Button
					positive
					icon='check'
					content={t('confirm')}
					loading={truckCompanyDetail.isLoading || woodSizeBuyPriceMapDetail.isLoading}
					onClick={WoodSizeBuyPriceMapFormik.handleSubmit}
				/>
			</Modal.Actions>
		</Modal>
	)
}

WoodImportSettingsPriceModal.defaultProps = {
  open: false,
  onClose: () => null,
	detail: defaultWoodSizeBuyPriceMapDetail,
	woodSizeBuyPriceMapDetail: {
		isLoading: false,
		errorMessages: null,
		handleSave: () => null,
		handleCreate: () => null,
	},
}

WoodImportSettingsPriceModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
	detail: propTypes.object,
	woodSizeBuyPriceMapDetail: propTypes.object,
}


export default React.memo(WoodImportSettingsPriceModal);