import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Container, Dimmer, Grid, Header, Loader } from 'semantic-ui-react';
import MainHeader, { MENU_ADMIN } from '../components/common/MainHeader';
import SyncDataModal from '../components/admin/SyncDataModal';
import { URL_AUTH, URL_AX_DB, URL_LOG_WOOD, URL_SCALE_LARGE, URL_SYNC_DATA } from '../constance/urls';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import { PATH } from '../routes/Routes';
import { GET } from '../utils/HttpClient';
import * as _ from 'lodash';
import UpdateDataModal from '../components/admin/UpdateDataModal';
import { SYNC_TYPE } from '../constance/dataTypes/sync_data';
import { useUserContext } from '../hooks/UserSettingProvider';


export default function AdminView() {
  const { t } = useTranslation();
  const { onLogout } = useUserContext();
  const [activeMenu, setActiveMenu] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const history = useHistory();

  const syncUrl = {
    log_wood_evaluation_data_sync: URL_LOG_WOOD.LOG_WOOD_EVALUATION_DATA_SYNC,
    truck_transaction_sync: URL_SCALE_LARGE.TRUCK_TRANSACTION_SYNC,
  }

  const updateUrl = {
    update_ax_code_from_wwg_wage: {
      syncUrl: URL_SYNC_DATA.SYNC_AX_CODE,
      syncType: SYNC_TYPE.WWG_WAGE,
    },
    update_employee_from_outpay: {
      syncUrl: URL_SYNC_DATA.SYNC_EMPLOYEE,
      syncType: SYNC_TYPE.EMPLOYEE,
    }
  }

  const adminMenu = [
    // sync data from grail server
    { id: 'log_wood_evaluation_data_sync', name: t('admins.log_wood_evaluation_data_sync.title'), onClick: () => setActiveMenu('log_wood_evaluation_data_sync') },
    { id: 'truck_transaction_sync', name: t('admins.truck_transaction_sync.title'), onClick: () => setActiveMenu('truck_transaction_sync') },

    // sync data from ms ax db and will send to update grail server
    { id: 'update_ax_code_from_wwg_wage', name: t('admins.update_ax_code_from_wwg_wage.title'), onClick: () => setActiveMenu('update_ax_code_from_wwg_wage') },
    { id: 'update_employee_from_outpay', name: t('admins.update_employee_from_outpay.title'), onClick: () => setActiveMenu ('update_employee_from_outpay')},

    { id: 'admin_django_page', name: t('admins.admin_django_page'), onClick: () => window.open('/api/admin') },
  ]

  const logOut = () => {
    onLogout()
    history.replace(PATH.LOGIN)
  }

  React.useEffect(() => {
    const isAdmin = async () => {
      try {
        const response = await GET(URL_AUTH.IS_ADMIN)
        if (!response.data.is_admin) { logOut(); }
        setIsLoading(false);
      } catch {
        logOut();
      }  
    }
    isAdmin();
  }, [])

  return (
    <div>
      <Dimmer active={isLoading}><Loader></Loader></Dimmer>
      <MainHeader activeItem={MENU_ADMIN}/>

      <Container style={{ marginTop: '7em' }}>
        <Header as='h2'>{t('menus.admin')}</Header>

        <Grid textAlign='center'>
          { adminMenu.map(menu => 
            <Grid.Row>
              <Grid.Column computer='6' tablet='8' mobile='16'>
                <Button labelPosition='left' fluid basic color='blue' icon='caret right' content={menu.name} onClick={menu.onClick}/>
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>

        {/* menu modal */}
        <SyncDataModal
          url={_.get(syncUrl, activeMenu, '')}
          title={activeMenu ? t(`admins.${activeMenu}.title`) : ''}
          content={activeMenu ? t(`admins.${activeMenu}.content`) : ''}
          open={activeMenu && activeMenu.includes('sync')}
          onClose={() => setActiveMenu(null)}
        />

        <UpdateDataModal
          syncUrl={_.get(updateUrl, `${activeMenu}.syncUrl`, '')}
          syncType={_.get(updateUrl, `${activeMenu}.syncType`, '')}
          activeMenu={activeMenu}
          open={activeMenu && activeMenu.includes('update')}
          onClose={() => setActiveMenu(null)}
        />
			</Container>
    </div>
  )
}