import React from "react";
import PropTypes from "prop-types";
import { Icon, Modal, Popup } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import CustomTable from "../common/CustomTable";
import { URL_INTERFACE_AX } from "../../constance/urls";
import moment from "moment";
import { ellipsisString } from "../../utils/stringFormat";
import * as _ from "lodash";


export default function InterfaceAXHistoryModal (props) {
  const {
    type,
    open,
    onClose,
  } = props;

  const { t } = useTranslation();
  const tableRef = React.useRef();
  const columns = React.useMemo(() => [
    {
      Header: t('date'),
      accessor: 'created',
      Cell: ({ value }) => moment(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      Header: t('interface_ax.reference'),
      accessor: 'refs',
      Cell: ({ value }) =>
        <Popup
          inverted
          content={value.map(e => <div>{e}</div>)}
          trigger={<span>{ellipsisString(value.join(', '), 30)}</span>}/>
    },
    {
      Header: t('interface_ax.ref_params'),
      accessor: 'ref_params',
      Cell: ({ value }) => {
        return Object.keys(value).map(e => `${t(`interface_ax.params.${e}`)}: ${_.get(value, e, '-')}`)
      }
    },
    {
      Header: t('status'),
      accessor: 'is_success',
      Cell: ({ value }) => value != null ? 
        <Icon color={value ? 'green' : 'red'} name={value ? 'check' : 'cancel'}/> : 
        <span><Icon name='sync'/> {t('progressing')}</span>
    },
    {
      Header: t('detail'),
      accessor: 'response_text',
      Cell: ({ value }) =>
        <Popup
          inverted
          content={value}
          trigger={<span>{ellipsisString(value, 30)}</span>}/>
    }
  ]);

  React.useEffect(() => {
    if (open && tableRef.current) {
      tableRef.current.refetch();
    }
  }, [open]);

  return (
    <Modal
      centered={false}
      open={open}
      onClose={onClose}
      closeIcon>
      <Modal.Header>{t('interface_ax.history')}</Modal.Header>
      <Modal.Content>
        <CustomTable
          ref={tableRef}
          columns={columns}
          fetchUrl={URL_INTERFACE_AX.INTERFACE_TRANSACTION}
          manual
          showPagination
          extraFilterParams={{ type }}/>
      </Modal.Content>
    </Modal>
  )
}


InterfaceAXHistoryModal.propTypes = {
  type: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func, 
}

InterfaceAXHistoryModal.defaultProps = {
  type: '',
  open: false,
  onClose: () => null,
}