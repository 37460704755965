import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Header } from 'semantic-ui-react';
import { Media } from '../AppMedia';
import MainHeader, { MENU_WOOD_IMPORT_SETTINGS_PRICE } from '../components/common/MainHeader';
import WoodImportSettingPriceList from '../components/wood-import/WoodImportSettingsPriceList';


export default function WoodImportSettingPriceListView() {

	const { t } = useTranslation();

	return (
		<div>
			<MainHeader activeItem={MENU_WOOD_IMPORT_SETTINGS_PRICE}/>
			<Container style={{ marginTop: '7em' }}>
				<Header as='h2'>{t('menus.wood_menus.wood_import_settings_price')}</Header>

				<Media lessThan={"md"}>					
				</Media>

				<Media greaterThanOrEqual="md">
					<WoodImportSettingPriceList/>
				</Media>
			</Container>
		</div>
	);
}