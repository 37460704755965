import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "semantic-ui-react";
import { useTranslation } from "react-i18next";


export default function OvenSetMenu (props) {
  const {
    onGoToSelectOvenSet,
    onGoToSelectOvenSetDetail,
    onBack,
  } = props;

  const { t } = useTranslation();

  return (
    <div style={{ marginTop: '3rem' }}>
      <Grid centered>
        <Grid.Row>
          <Button
            color='blue'
            icon='arrow right'
            labelPosition='right'
            content={t('post_baking_waste_wood_count.select_oven_set')}
            onClick={onGoToSelectOvenSet}/>
        </Grid.Row>
        <Grid.Row>
          <Button
            color='teal'
            icon='arrow right'
            labelPosition='right'
            content={t('post_baking_waste_wood_count.oven_set_wood_status')}
            onClick={onGoToSelectOvenSetDetail}/>
        </Grid.Row>
      </Grid>
      <Button style={{ marginTop: '2rem' }} content={t('back')} onClick={onBack}/>
    </div>
  )
}


OvenSetMenu.propTypes = {
  onGoToSelectOvenSet: PropTypes.func,
  onGoToSelectOvenSetDetail: PropTypes.func,
  onBack: PropTypes.func,
}

OvenSetMenu.defaultProps = {
  onGoToSelectOvenSet: () => null,
  onGoToSelectOvenSetDetail: () => null,
  onBack: () => null,
}