import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Form } from "semantic-ui-react";
import { WASTE_WOOD_GROUP_TYPE, WASTE_WOOD_TYPE, WOOD_GRADE_CHOICES } from "../../constance/dataTypes";
import * as _ from "lodash";


export default function CountWasteWoodForm (props) {
  const {
    style,
    formik
  } = props;

  const { t } = useTranslation(); 

  const [wasteWoodTypeOptions, setWasteWoodTypeOptions] = React.useState([]);
  const wasteWoodGroupOptions = Object.entries(WASTE_WOOD_GROUP_TYPE).map(([_, value]) => ({
    key: value, value, text: t(`post_baking_waste_wood_input.waste_wood_groups.${value}`)
  }))

  React.useEffect(() => {
    setWasteWoodTypeOptions(
      Object.entries(_.get(WASTE_WOOD_TYPE, formik.values.waste_wood_group, {})).map(([_, value]) => ({
        key: value, value, text: t(`post_baking_waste_wood_input.waste_wood_types.${value}`)
      }))
    )
  }, [formik.values.waste_wood_group])

  return (
    <Form style={{ width: 'fit-content', margin: 'auto', textAlign: 'left', ...style }}>
      <Form.Group inline unstackable>
        <Form.Field width='6' label={t('wood_imports.grade')}/>
        <Form.Select
          width='10'
          name='grade'
          options={WOOD_GRADE_CHOICES}
          value={formik.values.grade}
          error={formik.errors.grade}
          onChange={(_, data) => {
            formik.setFieldValue('grade', data.value)
          }}/>
      </Form.Group>
      <Form.Group inline unstackable>
        <Form.Field width='6' label={t('wood_size')}/>
        <Form.Input
          width='10'
          name='wood_code_dimension'
          value={formik.values.wood_code_dimension}
          error={formik.errors.wood_code_dimension}
          onChange={formik.handleChange}/>
      </Form.Group>
      <Form.Group inline unstackable>
        <Form.Field width='6' label={t('barcodes.amount')}/>
        <Form.Input
          width='10'
          name='amount'
          value={formik.values.amount}
          error={formik.errors.amount}
          onChange={formik.handleChange}/>
      </Form.Group>
      <Form.Group inline unstackable>
        <Form.Field width='6' label={t('post_baking_waste_wood_input.waste_wood_group')}/>
        <Form.Select 
          width='10'
          name='waste_wood_group'
          options={wasteWoodGroupOptions}
          value={formik.values.waste_wood_group}
          error={formik.errors.waste_wood_group}
          onChange={(_, { value }) => {
            formik.setFieldValue('waste_wood_group', value)
          }}/>
      </Form.Group>
      <Form.Group inline unstackable>
        <Form.Field width='6' label={t('post_baking_waste_wood_input.waste_wood_type')}/>
        <Form.Select
          width='10'
          name='waste_wood_type'
          options={wasteWoodTypeOptions}
          value={formik.values.waste_wood_type}
          error={formik.errors.waste_wood_type}
          onChange={(_, { value }) => {
            formik.setFieldValue('waste_wood_type', value)
          }}/>
      </Form.Group>
    </Form>
  )
}

CountWasteWoodForm.propTypes = {
  style: PropTypes.object,
  formik: PropTypes.object,
}

CountWasteWoodForm.defaultProps = {
  style: {},
  formik: {
    values: { },
    errors: { },
    setFieldValue: () => null,
    handleChange: () => null,
    handleSubmit: () => null,
  },
}