import React from "react";
import { Button, Container, Dimmer, Form, Header, Loader, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import SubHeader from "../../components/common/SubHeader";
import { useParams } from "react-router-dom";
import { GET, PUT } from "../../utils/HttpClient";
import { URL_POST_BAKING } from "../../constance/urls";
import ErrorMessage from "../../components/common/ErrorMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import { defaultPostBakingWasteWoodCountDetail } from "../../constance/dataTypes";
import moment from "moment";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import * as _ from "lodash";
import { Toast } from "../../utils/Toast";
import { isDateValid } from "../../utils/stringFormat";
import { useHistory } from "react-router-dom";
import OvenForm from "../../components/oven/OvenForm";
import PostBakingWasteWoodCountList from "../../components/post-baking-waste-wood-count/PostBakingWasteWoodCountList";
import WasteWoodCountModal from "../../components/post-baking-waste-wood-count/WasteWoodCountModal";


export default function PostBakingWasteWoodCountDetailView() {

  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();

  const ovenFormRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [inputTarget, setInputTarget] = React.useState(null);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      post_baking_group_date: Yup.string().required(t('error_messages.validation.required')),
      wood_size_amount_map_list: Yup.array().of(Yup.object().shape({
        wood_code: Yup.string().required(t('error_messages.validation.required')),
        amount: Yup.number().required(t('error_messages.validation.required')),
      }))
    }),
    initialValues: defaultPostBakingWasteWoodCountDetail,
    onSubmit: async (values) => {
      setLoading(true);
      setErrorMessages(null);
      try {
        const response = await GET(URL_POST_BAKING.CHECK_POST_BAKING_GROUP, 
          { date: _.get(values, 'post_baking_group_date', ''), ovens: _.get(values, 'ovens', []).join(',') })
        if (!_.get(response, 'data.exists', false)) {
          Toast.error(t('error'))
          setErrorMessages(t('post_baking_waste_wood_count.errors.no_wood'))
          return;
        }  
        await PUT(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT}${id}/`, values)
        fetchData();
        Toast.success(t('succes'))
      } catch (error) {
        Toast.error(t('error'))
        setErrorMessages(error.errorMessages);
      } finally {
        setLoading(false);
      }
    }
  })

  const editable = !_.get(formik, 'values.interfaced', false)

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT}${id}/`)
      formik.setValues(response.data)
      if (ovenFormRef.current){
        ovenFormRef.current.initial(_.get(response, 'data.ovens', []))
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id])

  return (
    <div>
      <Container style={{ marginTop: '7em' }}>
        <SubHeader/>
        <Header as='h2'>{t('post_baking_waste_wood_count.detail')}</Header>
        <ErrorMessage message={errorMessages} length={100}/>
        <Dimmer active={loading} inverted>
          <Loader inverted>{t('loading')}</Loader>
        </Dimmer>
        <Form>
          <Form.Input
            style={{ borderBox: 0 }}
            inline
            transparent
            readOnly
            label={t('post_baking_waste_wood_count.count_date')}
            value={moment(formik.values.created).format('DD/MM/yyyy')}/>
          <Form.Field
            inline
            style={{ width: '100%' }}
            label={t('post_baking_waste_wood_count.group_date')}
            locale='en-US'
            format='DD/MM/YYYY'
            showToday={true}
            clearable={false}
            readOnly={!editable}
            placeholder={t('post_baking_waste_wood_count.group_date')}
            control={SemanticDatepicker}
            value={isDateValid(formik.values.post_baking_group_date) ? new Date(formik.values.post_baking_group_date) : null}
            error={formik.errors.post_baking_group_date}
            onChange={(_, data) => {
              formik.setFieldValue('post_baking_group_date', moment(data.value).format('YYYY-MM-DD'))
            }}
          />

          <OvenForm
            ref={ovenFormRef}
            formik={formik}
            readOnly={!editable}
            setUrl={URL_POST_BAKING.OVEN_SET}
            params={{ date: formik.values.post_baking_group_date }}/>

          <PostBakingWasteWoodCountList
            dataList={formik.values.wood_size_amount_map_list}
            readOnly={!editable}
            onEdit={setInputTarget}
            fetchData={fetchData}/>  

          { editable &&
            <Button
              style={{ marginTop: '1rem' }}
              basic
              color='green'
              icon='plus'
              content={t('add')}
              onClick={() => setInputTarget('add')}/>
          }

          <Form.Group inline style={{ justifyContent: 'center' }}>
            { editable &&
              <Form.Button
                style={{ marginTop: '2rem' }}
                color='green'
                icon='save'
                content={t('save')}
                loading={loading}
                onClick={formik.handleSubmit}/>
            }
            <Form.Button
              style={{ marginTop: '2rem' }}
              content={t('back')}
              loading={loading}
              onClick={() => history.goBack()}/>
          </Form.Group>
        </Form>
      </Container>
      <WasteWoodCountModal
        transaction={formik.values}
        open={inputTarget != null}
        onClose={() => {
          setInputTarget(null);
          fetchData();
        }}
        target={inputTarget}/>
    </div>
  )
}