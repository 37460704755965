import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import moment from "moment";
import { formatComma } from "../../utils/stringFormat";
import { PATH } from "../../routes/Routes";
import { Button, Container, Divider, Form, Header, List, Popup } from "semantic-ui-react";
import { DELETE } from "../../utils/HttpClient";
import { Toast } from "../../utils/Toast";
import MainHeader from "../../components/common/MainHeader";
import ReportExportButton from "../../components/common/ReportExportButton";
import { URL_POST_BAKING, URL_REPORT } from "../../constance/urls";
import ConfirmModal from "../../components/common/ConfirmModal";
import CustomTable from "../../components/common/CustomTable";
import SemanticDatepicker from "react-semantic-ui-datepickers";


export default function PostBakingWasteWoodInputListView() {

  const { t } = useTranslation();
  const history = useHistory();
  const tableRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [dateRange, setDateRange] = React.useState([]);
  const [ovenFilter, setOvenFilter] = React.useState(null)
  const [deleteConfirm, setDeleteConfirm] = React.useState(null);
  const haveDateRange = _.get(dateRange, 0, null) && _.get(dateRange, 1, null)

  const columns = React.useMemo(() => [
    {
      Header: t('barcodes.barcode_id'),
      accessor: 'barcode',
    },
    {
      Header: t('post_baking_waste_wood_count.group_date'),
      accessor: 'post_baking_group_date',
      Cell: ({ value }) => value ? moment(value).format('DD/MM/yyyy') : '-',
    },
    {
      Header: t('post_baking_waste_wood_input.input_date'),
      accessor: 'input_date',
      Cell: ({ value }) => value ? moment(value).format('DD/MM/yyyy') : null,
    },
    {
      Header: t('wood_size'),
      Cell: ({ row }) => {
        const value = _.get(row, 'original.wood_size_amount_map_list', [])
        return value ? (
          <List>
              { value.filter(data => _.get(data, 'amount', 0)).map(data => (
                <List.Item>{_.get(data, 'wood_code', '')} - {`(${formatComma(_.get(data, 'amount', 0))})`} {formatComma(_.get(data, 'volume', 0), 4)}</List.Item>
              )) }
          </List>
        ) : '-'
      }
    },
    {
      Header: t('post_baking_waste_wood_input.remain'),
      Cell: ({ row }) => {
        const value = _.get(row, 'original.wood_size_amount_map_list', [])
        return value ? (
            <List>
              {value.map(v => <List.Item>{formatComma(_.get(v, 'remain_amount', 0))}</List.Item>)}
            </List>
        ) : '-'
      }
    },
    {
      Header: t('workflows.action'),
      accessor: 'id',
      Cell: ({ value, row }) => (
        <div>
          <Button
            primary
            basic
            icon='file outline alternate'
            content={!_.get(row, 'original.interfaced', false) ? t('edit') : t('detail')}
            loading={loading}
            onClick={() => history.push(`${PATH.POST_BAKING_WASTE_WOOD_INPUT_REPORT}${value}`)}/>
          { !_.get(row, 'original.interfaced', false) &&
            <Button
              color='red'
              basic
              icon='trash'
              content={t('delete')}
              loading={loading}
              onClick={() => setDeleteConfirm(value)}/> 
          }
        </div>
      ),
    },

  ])

  const handleDelete = async () => {
    setLoading(true);
    try {
      await DELETE(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}${deleteConfirm}/`)
      if (tableRef.current) {
        tableRef.current.refetch();
      }
      Toast.success(`${t('delete')} ${t('success')}`)
    } catch (error) {
      Toast.error(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const filterComponent = [
    <Form.Field
      id='form-input-control-dateRange'
      name='dateRange'
      inline
      label={t('post_baking_waste_wood_count.group_date')}
      control={SemanticDatepicker}
      locale="en-US"
      type="range"
      format="DD/MM/YYYY"
      showToday={true}
      placeholder={t('searchs.placeholders.date')}
      values={dateRange}
      onChange={(_, data) => {
        setDateRange(data.value)
      }}
    />,
    <Form.Input
      label={t('post_baking_waste_wood_count.oven_no')}
      type='number'
      placeholder={t('all')}
      onChange={(_, data) => setOvenFilter(data.value)}/>
  ]

  return (
    <div>
      <Container style={{ marginTop: '7em' }}>
        <MainHeader/>
        <Header as='h2'>{t('menus.report_menus.waste_woods.post_baking_waste_wood_input')}</Header>
        <Popup
          inverted
          content={t('searchs.placeholders.date')}
          on='hover'
          trigger={
            <span style={{ padding: 0, margin: 0, float: 'right' }}>
              <ReportExportButton
                url={`${URL_REPORT.POST_BAKING_WASTE_WOOD_INPUT_REPORT}excel/`}
                title={t('menus.report_menus.waste_woods.post_baking_waste_wood_input')}
                type='excel'
                disabled={!haveDateRange}
                params={{
                  oven: ovenFilter,
                  date_after: _.get(dateRange, 0, null) ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
                  date_before: _.get(dateRange, 1, null) ? moment(_.get(dateRange, 1)).format('YYYY-MM-DD') : null,
                }}/>
            </span>
          }/>
        <Divider clearing hidden />
        <CustomTable
          ref={tableRef}
          columns={columns}
          fetchUrl={URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}
          showPagination
          showSize
          extraFilter={filterComponent}
          showSearchHeader
          extraFilterParams={{
            oven: ovenFilter,
            date_after: _.get(dateRange, 0, null) ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
            date_before: _.get(dateRange, 1, null) ? moment(_.get(dateRange, 1)).format('YYYY-MM-DD') : null,  
          }}/>
      </Container>
      <ConfirmModal
        open={deleteConfirm != null}
        onClose={() => setDeleteConfirm(null)}
        title={`${t('confirms.delete_title')}`}
        content={`${t('confirms.delete_content')}`}
        onConfirm={handleDelete}/>
    </div>
  )
}

