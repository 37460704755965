import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Checkbox, Form, Input } from "semantic-ui-react";
import * as _ from "lodash";
import ErrorMessage from "../common/ErrorMessage";
import { OVEN_TYPE_SELECT } from "../../constance/dataTypes";


export default function SelectOvenType (props) {
  const {
    onSelectOven,
    onSelectOvenSetMenu,
    onBack,
  } = props;

  const { t } = useTranslation();
  const ovenNoRef = React.useRef();

  const [typeSelect, setTypeSelect] = React.useState(null);
  
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [ovenSelect, setOvenSelect] = React.useState(null);
  const [ovenError, setOvenError] = React.useState('');
  
  const onSelect = () => {
    if (!typeSelect) {
      setErrorMessages(t('error_messages.validation.required'))
      return;
    }
    setErrorMessages(null);
    if (typeSelect == OVEN_TYPE_SELECT.SPECIFIC && !ovenSelect) {
      setOvenError(t('error_messages.validation.required'))
    } else {
      switch (typeSelect) {
        case OVEN_TYPE_SELECT.ALL:
          onSelectOven([]);
          return;
        case OVEN_TYPE_SELECT.SPECIFIC:
          onSelectOven([ovenSelect]);
          return;
        case OVEN_TYPE_SELECT.SET:
          onSelectOvenSetMenu();
          return;
      }
    }
  }

  return (
    <div>
      <ErrorMessage message={errorMessages}/>
      <Form style={{ marginTop: '3em' }}>
        <div style={{ width: 'fit-content', margin: 'auto', textAlign: 'left' }}>
          <Form.Field>
            <Checkbox
              radio
              label={t('post_baking_waste_wood_count.all_oven')}
              value={OVEN_TYPE_SELECT.ALL}
              checked={typeSelect == OVEN_TYPE_SELECT.ALL}
              onChange={(__, data) => setTypeSelect(data.value)}/>
          </Form.Field>
          <Form.Group inline>
            <Form.Field>
              <Checkbox
                radio
                label={`${t('post_baking_waste_wood_count.specific_oven')} ${t('post_baking_waste_wood_count.oven_no')}`}
                value={OVEN_TYPE_SELECT.SPECIFIC}
                checked={typeSelect == OVEN_TYPE_SELECT.SPECIFIC}
                onChange={(__, data) => {
                  setTypeSelect(data.value)
                  if (ovenNoRef.current) {
                    ovenNoRef.current.focus()
                  }
                }}/>
            </Form.Field>
            <Form.Field error={ovenError}>
              <Input
                style={{ width: '70px' }}
                ref={ovenNoRef}
                type='number'
                placeholder={t('input')}
                onChange={(__, data) => setOvenSelect(data.value)}/>
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <Checkbox
              radio
              label={t('post_baking_waste_wood_count.oven_set')}
              value={OVEN_TYPE_SELECT.SET}
              checked={typeSelect == OVEN_TYPE_SELECT.SET}
              onChange={(__, data) => setTypeSelect(data.value)}/>
          </Form.Field>
        </div>
        <Form.Group inline style={{ justifyContent: 'center' }}>
          <Form.Button primary style={{ marginTop: '2rem' }} icon='check' content={t('confirm')} onClick={() => onSelect()}/>
          <Form.Button style={{ marginTop: '2rem' }} content={t('back')} onClick={onBack}/>
        </Form.Group>
      </Form>
    </div>
  )
}

SelectOvenType.propTypes = {
  onSelectOven: PropTypes.func,
  onSelectOvenSetMenu: PropTypes.func,
  onBack: PropTypes.func,
}

SelectOvenType.defaultProps = {
  onSelectOven: () => null,
  onSelectOvenSetMenu: () => null,
  onBack: () => null,
}