import React from "react";
import { dateTimeToString, formatComma } from "../../utils/stringFormat";
import ReportLogWoodDetail from "../../components/reports/ReportLogWoodDetail";
import { useTranslation } from "react-i18next";
import useDropdownItem from "../../hooks/useDropdownItem";
import { URL_LOG_WOOD } from "../../constance/urls";

function ReportLogWoodCutWeightView() {

  const cutWeightChoices = useDropdownItem({url: URL_LOG_WOOD.CUT_WEIGHT})
  const { t } = useTranslation();

  const defaultColumns = [
    { 
      Header: "ลำดับที่", 
      columns: [{Header: "", accessor: "index", width: 80}] 
    },
    {  
      Header: "ว/ด/ป", 
      columns: [
        {
          Header: "", 
          accessor: "in_date_time", 
          width: 140, 
          Cell: ({value}) => (<p>{dateTimeToString(value)}</p>)
        }
      ] 
    },
    { 
      Header: "ทะเบียนรถ", 
      columns: [{ Header: "", accessor: "license_plate", width: 120 }]
    },
    { 
      Header: "รหัสลูกค้า", 
      columns: [{ Header: "", accessor: "truck_company_code", width: 120 }]
    },
    { 
      Header: "ชื่อลูกค้า", 
      columns: [{Header: "", accessor: "truck_company_name", width: 220}] 
    }
  ];

  const [columns, setColumns] = React.useState(defaultColumns);

  React.useEffect(() => {
    if (!cutWeightChoices.response.length) {
      return;
    }
    setColumns([
      ...defaultColumns,
      ...cutWeightChoices.response.sort((a, b) => a.id - b.id).map(cutWeight => {
        return {
          Header: cutWeight.name,
          columns: [
            { Header: t('units.kilo_gram'), accessor: `report.${cutWeight.name}_weight` },
            { Header: "%", accessor: `report.${cutWeight.name}_percent`, Cell: ({ value }) => formatComma(value) },
          ]
        }
      }),
      {
        Header: t('datas.total'),
        columns: [
          { Header: t('units.kilo_gram'), accessor: "report.total_weight" },
          { Header: "%", accessor: "report.total_percent", Cell: ({ value }) => formatComma(value) },
        ]
      }
    ])
  }, [cutWeightChoices.response]);

  return (
    <>
			<ReportLogWoodDetail 
			title={t('menus.report_menus.log_wood_menus.cut_weight')}
			url={URL_LOG_WOOD.REPORT_CUT_WEIGHT}
			tableColumns={columns}
			exportUrl={URL_LOG_WOOD.REPORT_CUT_WEIGHT_EXPORT}/>
    </>
  )
}

export default ReportLogWoodCutWeightView;