import React from 'react';
import './App.css';
import { Dimmer, Loader } from 'semantic-ui-react';
import { BrowserRouter as Router } from "react-router-dom";
import Routes from './routes/Routes';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-table-6/react-table.css';
import { askForPermissioToReceiveNotifications } from './push-notification';
import { MediaContextProvider } from "./AppMedia";
import UserSettingProvider from './hooks/UserSettingProvider';

toast.configure();
function App() {

  React.useEffect(() => {
    if (!localStorage.getItem('notification-token')) {
      askForPermissioToReceiveNotifications();
    }
  }, []);

  return (
    <MediaContextProvider>
      <UserSettingProvider>
        <React.Suspense fallback={
          <Dimmer active>
            <Loader>loading...</Loader>
          </Dimmer>
        }>
          <Router>
            <div className='App'>
              <Routes/>
            </div>
          </Router>
        </React.Suspense>
      </UserSettingProvider>
    </MediaContextProvider>
  );
}

export default App;