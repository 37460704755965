import { useFormik } from 'formik';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Form, Header, Message, Modal } from 'semantic-ui-react';
import * as Yup from 'yup';
import { POST } from '../../utils/HttpClient';
import { Toast } from '../../utils/Toast';
import TimePicker from '../common/TimePicker';


export default function SyncDataModal(props) {

  const {
    url,
    title,
    content,
    open,
    onClose,
  } = props;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: {

    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      date: Yup.string().required(t('error_messages.validation.required')),
      start_time: Yup.string().required(t('error_messages.validation.required')),
      end_time: Yup.string().required(t('error_messages.validation.required')),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      setErrorMessages(null);
      try {
        await POST(url, values)
        Toast.success(t('success'))
        onClose();
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false);
      }
    }
  })

  React.useState(() => {
    if(open) {
      formik.resetForm();
      setErrorMessages(null);
    }
  }, [open])

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        centered={false}
        closeIcon>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content>
          { errorMessages && <Message error>{errorMessages}</Message> }
          <Header>{content}</Header>
          <Form>
            <Form.Group inline>
              <Form.Field
                style={{ width: '100%' }}
                label={t('searchs.fields.date')}
                locale='en-US'
                format='DD/MM/YY'
                showToday={true}
                placeholder={t('searchs.placeholders.date')}
                control={SemanticDatepicker}
                values={formik.values.date}
                error={formik.errors.date != null}
                onChange={(_, data) => {
                  formik.setFieldValue('date', moment(data.value).format('YYYY-MM-DD'))
                }}
              />
            </Form.Group>
            <Form.Group>
              <TimePicker
                label='ตั้งแต่เวลา'
                error={formik.errors.start_time}
                onChange={(value) => formik.setFieldValue('start_time', value)}
              />
              <TimePicker
                label='ถึงเวลา'
                error={formik.errors.end_time}
                onChange={(value) => formik.setFieldValue('end_time', value)}
              />
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button content={t('confirm')} loading={isLoading} icon='check' positive onClick={formik.handleSubmit}/>
          <Button content={t('cancel')} loading={isLoading} icon='cancel' onClick={onClose}/>
        </Modal.Actions>
      </Modal>
    </div>
  )
}