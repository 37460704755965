import React from 'react';
import { Container, Header } from 'semantic-ui-react';
import MainHeader, { MENU_APPROVE_LOGWOOD } from '../components/common/MainHeader';
import LogWoodPOList from '../components/log-wood-po/LogWoodPOList';
import LogWoodPOMobileList from "../components/log-wood-po/mobile/LogWoodPOMobileList";
import { Media } from "../AppMedia";
import { useTranslation } from 'react-i18next';

function LogWoodPOListView () {

	const { t } = useTranslation();

	return (
		<div>
			<MainHeader activeItem={MENU_APPROVE_LOGWOOD}/>
			
			<Container style={{ marginTop: '7em' }}>
				<Header as='h2'>{t('menus.wood_menus.log_wood_po')}</Header>
				<Media lessThan={"md"}>
					<LogWoodPOMobileList />
				</Media>

				<Media greaterThanOrEqual="md">
					<LogWoodPOList />
				</Media>
			</Container>
		</div>
	);
}

export default LogWoodPOListView;