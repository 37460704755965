import React, { useState } from 'react';
import * as Yup from 'yup';
import { Modal, Button, Form, Search } from 'semantic-ui-react';
import propTypes from 'prop-types';
import { defaultTruckCompanyDetail, defaultWoodImportTruckTransactionDetail } from '../../constance/dataTypes';
import { URL_SCALE_LARGE } from '../../constance/urls';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { useDebounce } from "use-debounce";
import useSearchItem from '../../hooks/useSeatchItem';
import useItemDetail from '../../hooks/useItemDetail';
import useDropdownItem from '../../hooks/useDropdownItem';
import * as _ from 'lodash';


export default function CreateTruckTransactionModal(props) {

  const {
    open,
    onClose,
    onFinish,
  } = props;

  const { t } = useTranslation();

  const [truckCompanySearchTxt, setTruckCompanySearchTxt] = useState("")
  const [debouncedText] = useDebounce(truckCompanySearchTxt, 500);

  const woodImportTruckTransactionValidationSchema = Yup.object().shape({
    truck_company: Yup.string().required(t('error_messages.validation.required')),
    truck_type: Yup.mixed().required(t('error_messages.validation.key_not_found')),
    truck_license_plate: Yup.string().required(t('error_messages.validation.required')),
  })

  const truckTypes = useDropdownItem({ url: URL_SCALE_LARGE.TRUCK_TYPE, text: "name" });
  const truckCompanies = useSearchItem({ url: URL_SCALE_LARGE.TRUCK_COMPANY, description_render: 'name', title_render: 'code' });
  const truckCompanyDetail = useItemDetail({
    url: URL_SCALE_LARGE.TRUCK_COMPANY,
    manual: true,
    readOnly: true,
  });
  const truckCompanyFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: truckCompanyDetail.response || defaultTruckCompanyDetail,
  });
  const truckTransactionFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: woodImportTruckTransactionValidationSchema,
    initialValues: defaultWoodImportTruckTransactionDetail,
  })

  React.useEffect(() => {
    if (open) {
      truckCompanyFormik.resetForm()
      truckTransactionFormik.resetForm()
    }
  }, [open])

  React.useEffect(() => {
    if (debouncedText) {
      truckCompanies.fetchData({ search: debouncedText })
      if (truckCompanyFormik.values.id) {
        truckCompanyFormik.setValues(defaultTruckCompanyDetail);
        truckTransactionFormik.setFieldValue('truck_company', null)
      }
      truckCompanyFormik.setFieldValue('name', debouncedText)
    }
  }, [debouncedText])

  React.useEffect(() => {
      truckCompanyFormik.setFieldValue('name', truckCompanySearchTxt)
  }, [truckCompanySearchTxt])

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
    >
      <Modal.Header>{`${t('create')} ${t('trucks.license_plate')}`}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field
            fluid
            label={t('truck_transactions.company')}
            placeholder={`${t('search')} ${t('searchs.fields.code')} ${t('or')} ${t('searchs.fields.name')}...`}
            minCharacters={2}
            control={Search}
            results={truckCompanies.response}
            showNoResults={true}
            onSearchChange = {(_, data) => {
              setTruckCompanySearchTxt(data.value)
            }}
            onResultSelect={(__, data) => {
              truckCompanyFormik.setValues(data.result);
              truckTransactionFormik.setFieldValue('truck_company', _.get(data, 'result.code', ''))
              truckTransactionFormik.setFieldValue('truck_company_name', _.get(data, 'result.name', ''))
            }}
            value={truckCompanyFormik.values.name}
            error={truckTransactionFormik.errors.truck_company}
            loading={truckCompanies.isLoading}
          />
          <Form.Input
            fluid
            name='license_plate'
            label={t('wood_imports.truck_license_plate')}
            value={truckTransactionFormik.values.license_plate}
            onChange={truckTransactionFormik.handleChange}
          />
          <Form.Dropdown
            fluid
            label={t('trucks.type')}
            selection
            options={truckTypes.response}
            value={truckTransactionFormik.values.truck_type}
            error={truckTransactionFormik.errors.truck_type}
            onChange={(_, data) => {
              truckTransactionFormik.setFieldValue('truck_type', data.value)
              truckTransactionFormik.setFieldValue('truck_type_name', data.options.find(d => d.id === data.value).name)
            }}
          />

          <Button type='submit' onClick={() => {
            onFinish(truckTransactionFormik.values)
            onClose()
          }}>Submit</Button>
        </Form>
      </Modal.Content>
    </Modal>
  )
}

CreateTruckTransactionModal.defaultProps = {
  open: false,
  onClose: () => null,
  onFinish: (_)=> null,
}

CreateTruckTransactionModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  onFinish: propTypes.func,
}

