import React from 'react';
import { GET } from '../utils/HttpClient';
import { Toast } from '../utils/Toast';


export default function useDropdownItem ({
  url,
  manual=false,
  key='id',
  value='id',
  text='name',
  text_renderer=null}
) {
  const [response, setResponse] = React.useState([]);

  const fetchData = async () => {
    try {
      const response = await GET(url);
      setResponse(response.data.map(item => (
        {
          ...item,
          key: item[key],
          value: item[value],
          text: typeof(text_renderer) == 'function' ?  text_renderer(item) : item[text],
        }
      )))
    } catch (error) {
      Toast.error(error.errorMessages);
    }
  };

  React.useEffect(() => {
    if (!manual) {
      fetchData();
    }
  }, [])

  return { response, fetchData };
}