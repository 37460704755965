import React from 'react';
import { Dimmer, Loader, Message, Segment } from 'semantic-ui-react';
import TSReactTable from '../common/TSReactTable';
import propTypes from 'prop-types';
import { GET } from '../../utils/HttpClient';
import { useTranslation } from 'react-i18next';

const ReportDetail = React.forwardRef((props, ref) => {
  const {
    reportUrl,
    isCustomSetData,
    customSetData,
    columns,
  } = props;

  const { t } = useTranslation();

  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const refetch = async (queryString) => {
    setIsLoading(true);
    setErrorMessages(false);
    try {
      const response = await GET(`${reportUrl}?${queryString}`)
      let data = response.data;
      if (isCustomSetData == true) {
        data = customSetData(response.data)
      }
      setData(data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  React.useImperativeHandle(ref, () => ({
    refetch: refetch,
  }))

  return (
    <Segment basic style={{padding: 0}}>
      <Dimmer active={isLoading} inverted>
        <Loader inverted/>
      </Dimmer>
      { errorMessages &&
        <Message error>
          <Message.Header>{t('error')}</Message.Header>
          {errorMessages}
        </Message>
      }
      <TSReactTable
        data={data}
        columns={columns}
        pageSize={20}
      />
    </Segment>
  )
});

export default React.memo(ReportDetail);

ReportDetail.propTypes = {
  reportUrl: '',
  isCustomSetData: false,
  customSetData: () => null,
  columns: []
}

ReportDetail.defaultProps = {
  reportUrl: propTypes.string,
  isCustomSetData: propTypes.bool,
  customSetData: propTypes.func,
  columns: propTypes.array,
}