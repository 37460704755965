import React from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import propTypes from 'prop-types';
import CustomTable from '../common/CustomTable';
import { useTranslation } from 'react-i18next';
import { Media } from "../../AppMedia";
import MobileList from '../common/MobileList';


function HistoryListModal (props) {

  const {
    open,
    onClose,
    workflowHistoryUrl,
  } = props;

  const { t } = useTranslation();

  const columns = React.useMemo(() => [
    {
      Header: t('time'),
      accessor: 'timestamp',
      Cell: ({row}) => new Date(row.original.timestamp).toLocaleString('en-GB')
    },
    {
      Header: t('user'),
      accessor: 'by',
    },
    {
      Header: t('workflows.action'),
      accessor: 'transition',
      Cell: ({row}) => t(`workflows.actions.${row.original.transition}`)
    },
    {
      Header: t('memo'),
      accessor: 'description',
      Cell: ({row}) => row.original.description ? row.original.description : '-'
    },
  ], []);

  const content = {
		descriptions: [
      {
        icon: 'calendar',
        header: (item) => t('time'),
        detail: (item) => new Date(item.timestamp).toLocaleString('en-GB')
      },
			{
				icon: 'user',
				header: (item) => t('user'),
				detail: (item) => item.by
			},
      {
				icon: 'edit',
				header: (item) => t('workflows.action'),
				detail: (item) => t(`workflows.actions.${item.transition}`)
			},
      {
				icon: 'info circle',
				header: (item) => t('memo'),
				detail: (item) => item.description ? item.description : '-'
			}
		],
	}

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      centered={false}
    >
      <Modal.Header>
        <Icon name='history'/>
        {t('workflows.history')}
      </Modal.Header>
      <Modal.Content>
        <Media greaterThan={"sm"}>
          <CustomTable
            fetchUrl={workflowHistoryUrl}
            columns={columns}
            showPagination
          />
        </Media>
        
        <Media at={"sm"}>
          <MobileList
            fetchUrl={workflowHistoryUrl}
            content={content}
          />
        </Media>
      </Modal.Content>
    </Modal>
  );
}

export default React.memo(HistoryListModal);


HistoryListModal.defaultProps = {
  open: false,
  onClose: () => null,
  workflowHistoryUrl: '',
}

HistoryListModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  workflowHistoryUrl: propTypes.string,
}
