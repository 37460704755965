import React from 'react';
import { defaultLogWoodEvaluationDataDetail, defaultTruckTransactionDetail } from '../constance/dataTypes';


export default function useLogWoodEvaluationCalculation ({
  truckTransaction=defaultTruckTransactionDetail,
  logWoodEvaluationData=defaultLogWoodEvaluationDataDetail,
}) {

  const [logWoodEvaluataionSummary, setLogWoodEvaluationSummary] = React.useState(defaultLogWoodEvaluationSummary);

  React.useEffect(() => {
    if (truckTransaction, logWoodEvaluationData) {
      const std_cost = logWoodEvaluationData.log_wood_evaluate_diameter_list.reduce((pre, cur) => {
        return (
          pre + (Number(cur.cost_std || 0) * Number(cur.percent || 0) / 100)
        )
      }, Number(0));

      const exchange_std = logWoodEvaluationData.log_wood_evaluate_diameter_list.reduce((pre, cur) => {
        return (
          pre + (Number(cur.trade_std || 0) * Number(cur.percent || 0) / 100)
        )
      }, Number(0));

      const std_buy_price = Number((std_cost * exchange_std) / 1000);

      const buy_price = logWoodEvaluationData.buy_price;

      const real_cost = Number(buy_price * 1000 / exchange_std);

      const avg_weight = logWoodEvaluationData.log_wood_evaluate_diameter_list.reduce((pre, cur) => {
        return (
          pre + (Number(cur.weight_std || 0) * Number(cur.percent || 0) / 100)
        )
      }, 0);

      const cut_weight = logWoodEvaluationData.log_wood_cut_weight_evaluate_list.reduce((pre, cur) => { 
        return pre + Number(cur.weight || 0);
      }, 0);

      const return_wood = logWoodEvaluationData.return_wood;
      const net_weight = Number(truckTransaction.in_weight || 0) - Number(truckTransaction.out_weight || 0) - cut_weight;

      const buy_amount = Number(logWoodEvaluationData.buy_price || 0) * Number(net_weight || 0);

      setLogWoodEvaluationSummary({
        std_cost: std_cost,
        exchange_std: exchange_std,
        std_buy_price: std_buy_price,
        buy_price: buy_price,
        real_cost: real_cost,
        buy_amount: buy_amount,
        avg_weight: avg_weight,
        cut_weight: cut_weight,
        return_wood: return_wood,
        net_weight: net_weight,      
      });
    }
  }, [
    truckTransaction.in_weight,
    truckTransaction.out_weight,
    logWoodEvaluationData.log_wood_evaluate_diameter_list,
    logWoodEvaluationData.log_wood_cut_weight_evaluate_list,
    logWoodEvaluationData.buy_price,
    logWoodEvaluationData.return_wood,
  ]);

  return {
    ...logWoodEvaluataionSummary,
  };
}

const defaultLogWoodEvaluationSummary = {
  std_cost: 0,
  exchange_std: 0,
  std_buy_price: 0,
  buy_price: 0,
  real_cost: 0,
  buy_amount: 0,
  avg_weight: 0,
  cut_weight: 0,
  return_wood: 0,
  net_weight: 0,
};