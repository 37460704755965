import React from 'react';
import propTypes from 'prop-types';
import { Button, Form, Input, Message, Modal } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { POST } from '../../utils/HttpClient';
import { Toast } from '../../utils/Toast';
import { URL_AUTH } from '../../constance/urls';


export default function ChangePasswordModal(props) {
  const {
    open,
    onClose,
  } = props;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      new_password: Yup.string().required(t('error_messages.validation.required')),
    }),
    initialValues: {
      new_password: '',
    },
		onSubmit: async (values) => {
      setIsLoading(true);
      setErrorMessages(null);
      try {
        POST(URL_AUTH.CHANGE_PASSWORD, values)
        Toast.success(t('success'))
        onClose();
      } catch(error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false);
      }
    },
  })

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeIcon
      centered={false}
      size='small'
    >
      <Modal.Header>{t('user_profiles.change_password')}</Modal.Header>
      <Modal.Content>
        { errorMessages &&
          <Message negative>
            <Message.Header>{t('error')}</Message.Header>
            <p>{errorMessages}</p>
          </Message>
        }
        <Form inline>
          <Form.Group inline>
            <label style={{ width: '15%' }}>{t('user_profiles.new_password')}</label>
            <Form.Input
              width={16}
              icon='lock'
              iconPosition='left'
              placeholder={`${t('user_profiles.new_password')}...`}
              name='new_password'
              type='password'
              value={formik.values.new_password}
              error={formik.errors.new_password}
              onChange={formik.handleChange}/>
          </Form.Group>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content={t('cancel')} icon='cancel' onClick={onClose}/>
        <Button content={t('confirm')} icon='check' positive onClick={formik.handleSubmit}/>
      </Modal.Actions>
    </Modal>
  )
}

ChangePasswordModal.defaultProps = {
  open: false,
  onClose: () => null,
};

ChangePasswordModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
};