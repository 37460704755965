import moment from "moment";

export const formatComma = (value, decimal_digit=2) => {
  if (!value) {
    return 0;
  }
  const split_decimal = (+parseFloat(value.toString().replace(',', ''))).toFixed(decimal_digit).split('.');
  const number = split_decimal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (+split_decimal[1] ? "." + split_decimal[1] : "");
  return number;
}


export const ellipsisString = (value, threshold_length=15) => {
  if (!value) {
    return '';
  }
  
  return `${value.toString().substring(0, threshold_length)}${value.toString().length > threshold_length ? '...' : ''}`
}

/** Convert Date-Time */
export const dateTimeToString = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD/MM/YYYY HH:mm");
}

export const dateToString = (date) => {
  if (!date) {
    return "";
  }

  return moment(date).format("DD/MM/YYYY");
}

export const decimalDigitFormatter = (number, decimal_digit=2) => {
  if (isNaN(Number(number))) {
    return 0;
  }

  return Number(number).toFixed(decimal_digit)
}

export const isDateValid = (value) => {
  return !isNaN(new Date(value))
}