import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Form, Grid, Header, Input, Popup } from 'semantic-ui-react';
import { formatComma } from '../../utils/stringFormat';
import propTypes from 'prop-types';
import useLogWoodEvaluationCalculation from '../../hooks/useLogWoodEvaluationCalculation';
import { Media } from "../../AppMedia";
import { defaultLogWoodPOWorkflowDetail } from '../../constance/dataTypes';
import moment from 'moment';

const SummaryItem = ({title, value, unit}) => {
  return (
    <div>
      <Media at="sm">
        <Grid>
          <Grid.Column>
            <Popup 
              content={unit}
              trigger={
                <Form.Field 
                  label={title}
                  control={Input}
                  readOnly={true}
                  value={value != null ? formatComma(value) : ''}/>
              }/>
          </Grid.Column>
        </Grid>
      </Media>
      <Media greaterThan="sm">
        <Grid columns="equal" verticalAlign='middle'>
          <Grid.Row>
            <Grid.Column>{title}</Grid.Column>
            <Grid.Column>
              <input 
                style={{textAlign: 'right'}} 
                readOnly 
                value={value != null ? formatComma(value) : ''}/>
            </Grid.Column>
            <Grid.Column>{unit}</Grid.Column>
          </Grid.Row>
        </Grid>
      </Media>
    </div>
    
  )
};

function LogWoodPOSummary (props) {

  const {
    detail,
    active
  } = props;

  const { t } = useTranslation();
  const updatedSummary = useLogWoodEvaluationCalculation({
    truckTransaction: detail.truck_transaction,
    logWoodEvaluationData: detail.log_wood_evaluation_data
  });

  const isDataUpdated = () => {
    const logWoodEvaluationDataCreated = detail.log_wood_evaluation_data.created_by;
    const logWoodEvaluationDataUpdated = detail.log_wood_evaluation_data.updated_by;
    const isLogWoodEvaluationDataUpdated = logWoodEvaluationDataCreated != logWoodEvaluationDataUpdated;

    const truckTransactionCreated = detail.truck_transaction.created_by;
    const truckTransactionUpdated = detail.truck_transaction.updated_by;
    const isTruckTransactionUpdated = truckTransactionCreated != truckTransactionUpdated;

    return isLogWoodEvaluationDataUpdated || isTruckTransactionUpdated;
  }

  return (
    <div>
      {active && 
        <Form>
          <Grid columns={2} divided>
            <Grid.Column >
              <Header textAlign='center'>{t('log_wood_po.evaluate_summary')}</Header>
              <SummaryItem 
                title={t('log_wood_po.std_cost')}
                unit={`${t('units.baht')} / ${t('units.cubic')}${t('units.ft')}`}
                value={detail.log_summary.std_cost}/>

              <SummaryItem 
                title={t('log_wood_po.exchange_std')}
                unit={`${t('units.cubic')}${t('units.ft')} / ${t('units.tonne')}`}
                value={detail.log_summary.exchange_std}/>

              <SummaryItem 
                title={t('log_wood_evaluation_datas.std_buy_price')}
                unit={`${t('units.baht')} / ${t('units.kilo_gram')}`}
                value={detail.log_summary.std_buy_price}/>

              <SummaryItem 
                title={t('log_wood_evaluation_datas.buy_price')}
                unit={`${t('units.baht')} / ${t('units.kilo_gram')}`}
                value={detail.log_summary.buy_price}/>

              <SummaryItem 
                title={t('log_wood_po.real_cost')}
                unit={`${t('units.baht')} / ${t('units.kilo_gram')}`}
                value={detail.log_summary.real_cost}/>

              <SummaryItem 
                title={t('log_wood_evaluation_datas.buy_amount')}
                unit={`${t('units.baht')}`}
                value={detail.log_summary.buy_amount}/>

              <SummaryItem 
                title={t('log_wood_po.avg_weight')}
                unit={`${t('units.kilo_gram')} / ${t('units.log_wood')}`}
                value={detail.log_summary.avg_weight}/>

              <SummaryItem 
                title={t('log_wood_evaluation_datas.cut_weight')}
                unit={`${t('units.kilo_gram')}`}
                value={detail.log_summary.cut_weight}/>

              <SummaryItem 
                title={t('log_wood_evaluation_datas.return_wood')}
                unit={`${t('units.log_wood')}`}
                value={detail.log_summary.return_wood}/>

              <SummaryItem 
                title={t('log_wood_po.net_weight')}
                unit={`${t('units.kilo_gram')}`}
                value={detail.log_summary.net_weight}/>
            </Grid.Column>

            <Grid.Column >
              <Header textAlign='center'>{t('log_wood_po.updated_summary')}</Header>
                <SummaryItem 
                  title={t('log_wood_po.std_cost')}
                  unit={`${t('units.baht')} / ${t('units.cubic')}${t('units.ft')}`}
                  value={isDataUpdated() ? updatedSummary.std_cost : null}/>

                <SummaryItem 
                  title={t('log_wood_po.exchange_std')}
                  unit={`${t('units.cubic')}${t('units.ft')} / ${t('units.tonne')}`}
                  value={isDataUpdated() ? updatedSummary.exchange_std : null}/>

                <SummaryItem 
                  title={t('log_wood_evaluation_datas.std_buy_price')}
                  unit={`${t('units.baht')} / ${t('units.kilo_gram')}`}
                  value={isDataUpdated() ? updatedSummary.std_buy_price : null}/>

                <SummaryItem 
                  title={t('log_wood_evaluation_datas.buy_price')}
                  unit={`${t('units.baht')} / ${t('units.kilo_gram')}`}
                  value={isDataUpdated() ? updatedSummary.buy_price : null}/>

                <SummaryItem 
                  title={t('log_wood_po.real_cost')}
                  unit={`${t('units.baht')} / ${t('units.kilo_gram')}`}
                  value={isDataUpdated() ? updatedSummary.real_cost : null}/>

                <SummaryItem 
                  title={t('log_wood_evaluation_datas.buy_amount')}
                  unit={`${t('units.baht')}`}
                  value={isDataUpdated() ? updatedSummary.buy_amount : null}/>

                <SummaryItem 
                  title={t('log_wood_po.avg_weight')}
                  unit={`${t('units.kilo_gram')} / ${t('units.log_wood')}`}
                  value={isDataUpdated() ? updatedSummary.avg_weight : null}/>

                <SummaryItem 
                  title={t('log_wood_evaluation_datas.cut_weight')}
                  unit={`${t('units.kilo_gram')}`}
                  value={isDataUpdated() ? updatedSummary.cut_weight : null}/>

                <SummaryItem 
                  title={t('log_wood_evaluation_datas.return_wood')}
                  unit={`${t('units.log_wood')}`}
                  value={isDataUpdated() ? updatedSummary.return_wood : null}/>

                <SummaryItem 
                  title={t('log_wood_po.net_weight')}
                  unit={`${t('units.kilo_gram')}`}
                  value={isDataUpdated() ? updatedSummary.net_weight : null}/>
            </Grid.Column>
            <Divider hidden/>
          </Grid>
        </Form>
      }
    </div>
  );
}

export default React.memo(LogWoodPOSummary);


LogWoodPOSummary.defaultProps = {
  updatedDetail: defaultLogWoodPOWorkflowDetail,
  active: false,
};

LogWoodPOSummary.propTypes = {
  updatedDetail: propTypes.object,
  active: propTypes.bool,
};