import React from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Dropdown, Icon, Image, Menu } from 'semantic-ui-react';
import Cookies from 'js-cookie';
import { PATH } from '../../routes/Routes';
import { useTranslation } from 'react-i18next';
import ConfirmModal from './ConfirmModal';
import { useUserContext } from '../../hooks/UserSettingProvider';


function SubHeader (props) {

  const {
    showConfirmMovingPage,
  } = props;

  const history = useHistory();

  const actionMovePage = (action) => {
    if (action === 'back') {
      history.goBack()
    } else if(action === 'profile') {
      history.replace(PATH.USER_PROFILE)
    }
  }

  const { t, i18n } = useTranslation();
  const [language, setLanguage] = React.useState(null);
  const { user, onLogout } = useUserContext();
  const [confirmMovePageVisible, setConfirmMovePageVisible] = React.useState(null);

  React.useEffect(() => {
    if (localStorage.getItem('i18nextLng')) {
      setLanguage(localStorage.getItem('i18nextLng'));
    }
  }, []);

  React.useEffect(() => {
    if (language && (language != i18n.language)) {
      i18n.changeLanguage(language.toLocaleLowerCase());
    }
  }, [language]);

  const handleLogout = () => {
    onLogout();
    history.replace(PATH.LOGIN)
  }

  return (
    <div>
      <ConfirmModal
        open={confirmMovePageVisible != null}
        onClose={() => setConfirmMovePageVisible(null)}
        title={t('confirms.move_page_title')}
        content={t('confirms.move_page_content')}
        onConfirm={() => {
          actionMovePage(confirmMovePageVisible);
          setConfirmMovePageVisible(null);
        }}/>
      <Menu fixed='top' inverted>
        <Container>
          <Menu.Item as='a' onClick={() => {
            if(showConfirmMovingPage){
              setConfirmMovePageVisible('back')
            } else {
              history.goBack()
            }
          }} header>
            <Icon name={"arrow left"} size="large"/>
          </Menu.Item>
          <Menu.Menu  position='right'>
            <Menu.Item>
              <Dropdown 
                text={language ? language.toUpperCase() : ''}
                icon='world'
                options={[
                  {text: 'TH', value: 'th', key: 'th'},
                  {text: 'EN', value: 'en', key: 'en'},
                ]}
                onChange={(_, data) => setLanguage(data.value)}
                selectOnBlur={false}
              />
            </Menu.Item>
            <Menu.Item>
              <Dropdown icon={null} trigger={<Icon name='user circle' size='big'/>}>
                <Dropdown.Menu>
                <Dropdown.Header content={user.display_name} />
                  <Dropdown.Divider/>
                  <Dropdown.Item onClick={() => {
                    if(showConfirmMovingPage) {
                      setConfirmMovePageVisible('profile')
                    } else {
                      history.replace(PATH.USER_PROFILE)
                    }
                  }}>
                    <Icon name='user'/>
                    {t('menus.user_profile')}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>
                    <Icon name='sign-out'/>
                    {t('menus.log_out')}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Menu.Item>
          </Menu.Menu>
        </Container>
      </Menu>
    </div>
  )
}

export default SubHeader;