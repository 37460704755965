import moment from "moment"


export const defaultBOMModel = {
  transaction: '',
  wood_size_amount_map: '',
  original_wood_size: '',
  original_amount: 0,
  new_wood_size: '',
  new_amount: 0,
}

export const defaultBOMTransactionModel = {
  order_no: '',
  date: moment().format(),
  is_completed: false,
  is_interfaced: false,
  description: '',
  bom_list: [],
}


export const STATUS = {
  DRAFT: 'draft',
  COMPLETED: 'completed',
  INTERFACED: 'interfaced',
}


export const getBOMTranscationStatus = ({ is_completed, is_interfaced }=defaultBOMTransactionModel) => {
  if (is_completed && is_interfaced) {
    return STATUS.INTERFACED
  } else if (is_completed) {
    return STATUS.COMPLETED
  }
  return STATUS.DRAFT
}