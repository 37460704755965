import React from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Container, Dimmer, Divider, Header, Loader, Message, Popup } from 'semantic-ui-react';
import SubHeader from '../components/common/SubHeader';
import ActionButtons from '../components/workflow/ActionButtons';
import HistoryListModal from '../components/workflow/HistoryListModal';
import { URL_BARCODE, URL_WORKFLOW } from '../constance/urls';
import useWorkflowDetail from '../hooks/useWorkflowDetail';
import { PATH } from '../routes/Routes';
import { Toast } from '../utils/Toast';
import { Media } from "../AppMedia";
import { useIdleTimer } from 'react-idle-timer';
import TimeoutModal from '../components/common/TimeoutModal';
import { defaultWoodImportWorkflowDetail } from '../constance/dataTypes/workflow';
import WoodImportDetail from '../components/wood-import/WoodImportDetail';
import ReportExportButton from '../components/common/ReportExportButton';


const BasicButton = ({icon, content, floated, onClick=() => {}}) => {
  return (
    <Button 
      basic
      color='blue'
      icon={icon}
      content={content}
      floated={floated}
      onClick={onClick}
    />
  )
}

function WoodImportDetailView() {

  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    response,
    fetchData,
    handleAction,
    isLoading,
    errorMessages,
  } = useWorkflowDetail({ 
    workflowUrl: URL_WORKFLOW.WOOD_IMPORT_WORKFLOW,
    id: params.id,
    onSuccess: (action) => {
      history.push(PATH.WOOD_IMPORT);
      Toast.success(`${t(`workflows.actions.${action}`)} ${t('success')}`)
    },
    manual: params.id === 'add'
  });

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 minutes
    onIdle: () => setIsTimeout(true),
    crossTab: true
  })

  const [isTimeout, setIsTimeout] = React.useState(false);
  const [detail, setDetail] = React.useState(defaultWoodImportWorkflowDetail);
  const [isActionLoading, setIsActionLoading] = React.useState(false);
  const [isDetailLoading, setIsDetailLoading] = React.useState(false);
  const [showPrintPopup, setShowPrintPopup] = React.useState(false);
  const [isPrinting, setIsPrinting] = React.useState(false);
  const detailRef = React.useRef(null);

  // history modal
  const [isHistoryModalVisible, setIsHistoryModalVisible] = React.useState(false);

  React.useEffect(() => {
    setDetail({...defaultWoodImportWorkflowDetail, ...response})
  }, [response])

  React.useEffect(() => {
    if (params.id != 'add') {
      fetchData();
    }
  }, [params.id])

  const handleSaveWorkflowDetail = async () => {
    setIsActionLoading(true);
    if (!detailRef.current) {
      return false;
    }
    const [isCreate, isSuccess, returnData] = await detailRef.current.handleSaveDetail();
    if (isSuccess) {
      Toast.success(`${t('saving')} ${t('success')}`);
      if (isCreate) {
        history.replace(`${PATH.WOOD_IMPORT}${returnData.workflow}`)
      } else {
        fetchData();  
      }
    } else {
      console.error(returnData)
      Toast.error(`${t('saving')} ${t('error')} ${t('view_debug')}`);
    }
    setIsActionLoading(false);
    return isSuccess;
  }

  const handleDoAction = async (action) => {
    if (detail.workflow_params.formEditable) {
      const isSuccess = await handleSaveWorkflowDetail();
      if (!isSuccess) {
        return;
      }
    }
    handleAction(action)
  }

  return (
    <div>
      <Media lessThan="md">
        <TimeoutModal
          open={isTimeout}
          onClose={() => history.push(PATH.HOME)}
        />
      </Media>

      <Dimmer active={isLoading}>
        <Loader>{t('loading')}</Loader>
      </Dimmer>
      <SubHeader/>

      <Container style={{ marginTop: '7em' }} textAlign='left'>
        { errorMessages && 
          <Message error>
            <Message.Header>{t('error')}</Message.Header>
            {errorMessages}
          </Message>
        }
        { detail.memo && 
          <Message
            warning 
            icon='info circle'
            header={t('memo')}
            content={detail.memo}
          />
        }
        <Media greaterThan="sm">
          <Header as='h2'>
            { params.id != 'add' && 
              <div>
                <BasicButton 
                  icon='history'
                  floated='right'
                  content={t('workflows.history')}
                  onClick={() => setIsHistoryModalVisible(true)}
                />
                <Popup
                  flowing
                  hoverable
                  on='click'
                  position='left center'
                  trigger={
                    <Button
                      icon='file outline alternate'
                      basic
                      color='teal'
                      content={t('wood_imports.print')}
                      floated='right'
                    />
                  }
                  open={showPrintPopup}
                  onClose={() => {
                    if(!isPrinting) {
                      setShowPrintPopup(false)
                    }
                  }}
                  onOpen={() => setShowPrintPopup(true)}
                >
                  {['pdf', 'excel'].map(type => (
                    <ReportExportButton
                      url={`${URL_BARCODE.REPORT_WOOD_IMPORT_EXPORT}${type}/?id=${detail.wood_import.id}`}
                      type={type}
                      title={`${t('wood_imports.detail') }.....`}
                      disabled={isPrinting}
                      onStarted={() => setIsPrinting(true)}
                      onFinished={() => setIsPrinting(false)}
                    />)
                  )}
                </Popup>
              </div>
            }

            {t('wood_imports.detail')}{detail.state_name ? ` - [${detail.state_name}]` : ''}
          </Header>
          <Divider/>
        </Media>

        <Media at="sm">
          <Header as='h4'>
            {t('wood_imports.detail')}{detail.state_name ? ` - [${detail.state_name}]` : ''}
          </Header>
          { params.id != 'add' && 
            <BasicButton 
              icon='history'
              content={t('workflows.history')}
              onClick={() => setIsHistoryModalVisible(true)}
            />
          }
        </Media>

        <WoodImportDetail
          ref={detailRef}
          detail={detail.wood_import}
          enableEdit={detail.workflow_params.formEditable}
          setDetailLoading={(value) => setIsDetailLoading(value)}
        />

        <ActionButtons
          enableSave={detail.workflow_params.formEditable}
          enableCreate={params.id === 'add'}
          handleCreate={() => handleSaveWorkflowDetail()}
          handleSave={() => handleSaveWorkflowDetail()}
          actions={detail.workflow_actions}
          handleAction={(action) => handleDoAction(action)}
          isLoading={isLoading || isActionLoading || isDetailLoading}
        />

      </Container>
            
      <HistoryListModal
        open={isHistoryModalVisible}
        onClose={() => setIsHistoryModalVisible(false)}
        workflowHistoryUrl={`${URL_WORKFLOW.WOOD_IMPORT_WORKFLOW_HISTORY}${params.id}`}
      />
    </div>
  )
}

export default  WoodImportDetailView;