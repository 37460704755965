import React from "react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../common/ErrorMessage";
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import moment from "moment";
import { GET, POST } from "../../utils/HttpClient";
import { URL_POST_BAKING } from "../../constance/urls";
import { defaultPostBakingWasteWoodCountDetail } from "../../constance/dataTypes";
import PostBakingWasteWoodCountList from "./PostBakingWasteWoodCountList";
import * as _ from "lodash";
import ConfirmModal from "../common/ConfirmModal";
import { Toast } from "../../utils/Toast";
import { FONT_NORMAL_ANDROID, ALIGN_CENTER_ANDROID, FONT_LARGE_ANDROID } from '../../constance/printers';
import { formatComma } from "../../utils/stringFormat";


export default function WoodSummary(props) {
  const {
    groupDate,
    ovens,
    transaction,
    handleAddWood,
    onEdit,
    onFinish,
  } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null)
  const [detail, setDetail] = React.useState(defaultPostBakingWasteWoodCountDetail)
  const [finishConfirm, setFinishConfirm] = React.useState(false);

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT}${transaction}/`)
      setDetail(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  const handlePrint = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await POST(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT}${transaction}/print/`)
      doPrintAndriod({...response.data})
      Toast.success(`${t('print')} ${t('success')}`)
    } catch (error) {
      setErrorMessages(_.get(error, 'errorMessages', '') ? error.errorMessages : new String(error))
    } finally {
      setLoading(false);
    }
  }

  const groupWoodList = (dataList) => {
    const results = {}
    dataList.forEach(element => {
      const wasteWoodType = _.get(element, 'waste_wood_type', '')
      const result = {
        'wood_code': element.wood_code,
        'amount': element.amount,
        'volume': element.volume 
      }
      if (_.get(results, wasteWoodType, '')) {
        results[wasteWoodType].push(result)
      } else {
        results[wasteWoodType] = [result]
      }
    });
    return results
  }

  const doPrintAndriod = (detail) => {
    if (window.JSInterface) {
      let datas = []
  
      datas.push(ALIGN_CENTER_ANDROID)
      datas.push(FONT_LARGE_ANDROID)
  
      // title
      let titleRender = "ไม้เสียออกเตา"
      datas.push(titleRender)

      // group date
      let groupDateRender = "วันที่ยุบกอง "
      const groupDate = moment(_.get(detail, 'post_baking_group_date', ''), 'YYYY-MM-DD')
      if (groupDate.isValid()) {
        groupDateRender += groupDate.format('DD/MM/YYYY')
      } else {
        groupDateRender += '-'
      }

      datas.push(groupDateRender)
      datas.push("")
      datas.push(FONT_NORMAL_ANDROID)

      // wood list
      const woodList = groupWoodList(_.get(detail, 'wood_size_amount_map_list', []))
      for (const [key, value] of Object.entries(woodList)) {
        datas.push(t(`post_baking_waste_wood_input.waste_wood_types.${key}`))
        let sumAmount = 0;
        let sumVolume = 0;
        for (let v of value) {
          datas.push(`${v.wood_code} : ${formatComma(v.amount)}(${formatComma(v.volume)}) ท่อน`)
          sumAmount += Number(v.amount)
          sumVolume += Number(v.volume)
        }
        datas.push(`รวม ${formatComma(sumAmount)} ท่อน ${formatComma(sumVolume)} ฟุต`)
        datas.push("")
      }

      datas.push(FONT_LARGE_ANDROID)
      datas.push(`รวม ${formatComma(_.get(detail, 'sum_amount', 0))} ท่อน ${formatComma(_.get(detail, 'sum_volume', 0))} ฟุต`)
      datas.push("")

      // print render
      let printRender = "พิมพ์วันที่ "
      const printDate = moment(_.get(detail, 'print_datetime', ''))
      if (printDate.isValid()) {
        printRender += printDate.format('DD/MM/YYYY HH:mm')
      } else {
        printRender += '-'
      }
      datas.push(printRender)
      datas.push(`โดย ${_.get(detail, 'print_user_name', '-')}`)


      window.JSInterface.printAndroid(datas.join('\r\n'))
    }
  }

  const handleFinish = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await POST(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT}${transaction}/finish/`)
      onFinish();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (transaction) {
      fetchData()
    }
  }, [transaction])

  return (
    <div style={{ marginTop: '1rem' }}>
      <Header as='h4'>
        { groupDate && (
          <div>
            <div>
              {t('post_baking_waste_wood_count.group_date')}&nbsp;
              {moment(groupDate, 'yyyy-MM-DD').format('DD/MM/yyyy')}
            </div>
            { ovens && 
              <div>
                { ovens.length != 0 ? 
                  (<div>{t('post_baking_waste_wood_count.oven_no')}: {ovens.join(', ')}</div>) : 
                  (<div>{t('post_baking_waste_wood_count.all_oven')}</div>)
                }
              </div>
            }
          </div>
        ) }
      </Header>
      <Dimmer inverted active={loading}>
        <Loader inverted content={t('loading')}/>
      </Dimmer>
      <ErrorMessage message={errorMessages}/>

      <PostBakingWasteWoodCountList
        dataList={detail.wood_size_amount_map_list}
        onEdit={onEdit}
        fetchData={fetchData}/>

      <Button color='green' icon='add' content={t('add')} onClick={handleAddWood}/>
      <Button primary icon='print' disabled={!_.get(detail, 'wood_size_amount_map_list.length', 0)} content={t('print')} onClick={() => handlePrint()}/>
      <Button content={t('finish')} onClick={() => {
        if (transaction) {
          setFinishConfirm(true)
        } else {
          onFinish();
        }}}/>
      

      <ConfirmModal
        open={finishConfirm}
        onClose={() => setFinishConfirm(false)}
        title={`${t('finish')}`}
        content={`${t('workflows.confirmation_content')}: [${t('finish')}]?`}
        onConfirm={handleFinish}/>
    </div>
  )
}