import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import { PATH } from '../../../routes/Routes';
import { URL_WORKFLOW } from '../../../constance/urls';
import { useTranslation } from 'react-i18next';
import MobileList from '../../common/MobileList';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';
import { formatComma } from '../../../utils/stringFormat';


function LogWoodPOMobileList() {
  const { t } = useTranslation();
	const history = useHistory();
  const [date, setDate] = React.useState('');

	const content = {
		header: {
			icon: 'user',
			accessor: 'truck_transaction.truck_company_detail.name',
		},
		descriptions: [
			{
				icon: 'truck',
				header: (item) => item.truck_transaction.truck_type_name,
				detail: (item) => `${t('trucks.license_plate')} ${item.truck_transaction.license_plate}`
			},
			{
				icon: 'dolly flatbed',
				header: (item) => t('truck_transactions.net_weight'),
				detail: (item) => `${formatComma(item.truck_transaction.net_weight)} ${t('units.kilo_gram')}`
			}
		],
		onClick: (item) => history.push(`${PATH.LOG_WOOD_PO}${item.id}`)
	}

  const dateFilterComponent = (
    <Form.Field
      label={t('searchs.fields.date')}
      locale='en-US'
      format='DD/MM/YY'
      showToday={true}
      placeholder={t('searchs.placeholders.date')}
      control={SemanticDatepicker}
      onChange={(_, data) => {
        if (data.value) {
          setDate(moment(data.value).format('YYYY-MM-DD'))
        } else {
          setDate('')
        }
      }}
    />
  )

  return (
    <div>
			<MobileList
				fetchUrl={URL_WORKFLOW.LOG_WOOD_PO_WORKFLOW}
				content={content}
				showFilterHeader
				extraFilter={dateFilterComponent}
				extraFilterParams={{date: date}}
			/>
    </div>
  )
}

export default LogWoodPOMobileList;