import { useFormik } from 'formik';
import React from 'react';
import { Form, Grid, Input, Label, Message, Search, Table } from 'semantic-ui-react';
import * as Yup from 'yup';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { decimalDigitFormatter, formatComma } from '../../utils/stringFormat';
import useDropdownItem from '../../hooks/useDropdownItem';
import { URL_LOG_WOOD, URL_SCALE_LARGE } from '../../constance/urls';
import useSearchItem from '../../hooks/useSeatchItem';
import useItemDetail from '../../hooks/useItemDetail';
import { Toast } from '../../utils/Toast';
import useLogWoodEvaluationCalculation from '../../hooks/useLogWoodEvaluationCalculation';
import { 
  defaultLogWoodEvaluationDataDetail,
  defaultTruckTransactionDetail,
  defaultTruckCompanyDetail,
  defaultTruckProductDetail,
  defaultTruckDetail,
} from '../../constance/dataTypes';
import { Media } from "../../AppMedia";


const LogWoodPODetail = React.forwardRef((props, ref) => {

  const {
    truckTransaction,
    enableEdit,
    logWoodEvaluationData,
    active,
    setDetailLoading,
  } = props;

  const { t } = useTranslation();
  
  // dropdown and search item api hook
  const truckCompanies = useSearchItem({url: URL_SCALE_LARGE.TRUCK_COMPANY, description_render: 'name', title_render: 'code'});
  const truckProducts = useSearchItem({url: URL_SCALE_LARGE.TRUCK_PRODUCT, description_render: 'name', title_render: 'code'});
  const trucks = useSearchItem({url: URL_SCALE_LARGE.TRUCK, title_render: 'license_plate'});
  const truckTypes = useDropdownItem({url: URL_SCALE_LARGE.TRUCK_TYPE});
  const transactionTypes = useDropdownItem({url: URL_SCALE_LARGE.TRANSACTION_TYPE});
  const courtyards = useDropdownItem({url: URL_LOG_WOOD.COURTYARD});
  const logWoodTypes = useDropdownItem({url: URL_LOG_WOOD.LOG_WOOD_TYPE});
  const logWoodSpeciesChoices = useDropdownItem({url: URL_LOG_WOOD.LOG_WOOD_SPECIES});
  const logWoodEvaluateLengths = useDropdownItem({url: URL_LOG_WOOD.LOG_WOOD_EVALUATE_LENGTH});
  const cutWeightChoices = useDropdownItem({url: URL_LOG_WOOD.CUT_WEIGHT});
  const logWoodEvaluateSizeChoices = useDropdownItem({url: URL_LOG_WOOD.LOG_WOOD_EVALUATE_SIZE});
  const woodPlotChoices = useDropdownItem({url: URL_LOG_WOOD.WOOD_PLOT});

  // detail update api hook for objects
  const truckTransactionDetail = useItemDetail({
    url: URL_SCALE_LARGE.TRUCK_TRANSACTION,
    id: truckTransaction ? truckTransaction.id : null,
    manual: true
  });

  const logWoodEvaluationDataDetail = useItemDetail({
    url: URL_LOG_WOOD.LOG_WOOD_EVALUATION_DATA,
    id: logWoodEvaluationData ? logWoodEvaluationData.id : null,
    manual: true
  });

  const truckDetail = useItemDetail({
    url: URL_SCALE_LARGE.TRUCK,
    id: truckTransaction ? truckTransaction.truck : null,
    manual: true
  });

  const truckCompanyDetail = useItemDetail({
    url: URL_SCALE_LARGE.TRUCK_COMPANY,
    id: truckTransaction ? truckTransaction.truck_company : null,
    manual: true,
    readOnly: true,
  });

  const truckProductDetail = useItemDetail({
    url: URL_SCALE_LARGE.TRUCK_PRODUCT,
    id: truckTransaction ? truckTransaction.truck_product : null,
    manual: true,
    readOnly: true,
  });

  const cutWeightEvaluateDetail = useItemDetail({
    url: URL_LOG_WOOD.CUT_WEIGHT_EVALUATE,
    manual: true,
  })

  const evaluateDiameterDetail = useItemDetail({
    url: URL_LOG_WOOD.LOG_WOOD_EVALUATE_DIAMETER,
    manual: true,
  })

  const speciesEvaluateDetail = useItemDetail({
    url: URL_LOG_WOOD.LOG_WOOD_SPECIES_EVALUATE,
    manual: true,
  })
  
  // validation schema for update objects
  const truckTransactionValidationSchema = Yup.object().shape({
    transaction_type: Yup.mixed().required(t('error_messages.validation.key_not_found')),
    truck: Yup.mixed().required(t('error_messages.validation.key_not_found')),
    truck_company: Yup.string().required(t('error_messages.validation.key_not_found')),
    truck_product: Yup.mixed().required(t('error_messages.validation.key_not_found')),
  });

  const logWoodEvaluationDataValidationSchema = Yup.object().shape({
    courtyard: Yup.string().required(t('error_messages.validation.required')),
    log_wood_type: Yup.mixed().required(t('error_messages.validation.key_not_found')),
    eval_length: Yup.mixed().required(t('error_messages.validation.key_not_found')),
    buy_price: Yup.string().required(t('error_messages.validation.required')),
    return_wood: Yup.number().required(t('error_messages.validation.required')),
    location_code: Yup.string().required(t('error_messages.validation.required')),
    length_meet_criteria: Yup.number().required(t('error_messages.validation.required')),
    length_under_criteria: Yup.number().required(t('error_messages.validation.required')),
    length_over_criteria: Yup.number().required(t('error_messages.validation.required')), 
  });

  // form and state for objects
  const truckTransactionFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: truckTransactionValidationSchema,
    initialValues: truckTransaction || defaultTruckTransactionDetail,
  });

  const logWoodEvaluationDataFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: logWoodEvaluationDataValidationSchema,
    initialValues: logWoodEvaluationData || defaultLogWoodEvaluationDataDetail,
  });

  const truckFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: truckDetail.response || defaultTruckDetail,
  });
  
  const truckCompanyFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: truckCompanyDetail.response || defaultTruckCompanyDetail,
  });

  const truckProductFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: truckProductDetail.response || defaultTruckProductDetail,
  });

  const [cutWeights, setCutWeights] = React.useState([]);
  const [evaluateDiameters, setEvaluateDiameters] = React.useState([]);
  const [speciesList, setSpeciesList] = React.useState([]);
  const [diameterTableValidationErrorMessages, setDiameterTableValidationErrorMessages] = React.useState(false);
  const [speciesTableValidationErrorMessages, setSpeciesTableValidationErrorMessages] = React.useState(false);

  const {net_weight, std_buy_price, cut_weight, buy_amount} = useLogWoodEvaluationCalculation({
    truckTransaction: truckTransactionFormik.values,
    logWoodEvaluationData: {
      ...logWoodEvaluationDataFormik.values,
      log_wood_evaluate_diameter_list: evaluateDiameters,
      log_wood_cut_weight_evaluate_list: cutWeights,
    },
  });

  const validateForm = async () => {
    const logWoodEvaluationDataFormikValidation = await logWoodEvaluationDataFormik.validateForm();
    const isLogWoodEvaluationDataValid = Object.keys(logWoodEvaluationDataFormikValidation).length === 0;
    const truckTransactionFormikValidation = await truckTransactionFormik.validateForm();
    const isTruckTransactionValid = Object.keys(truckTransactionFormikValidation).length === 0;
    Object.keys(logWoodEvaluationDataFormikValidation).map(key => {
      logWoodEvaluationDataFormik.setFieldError(key, logWoodEvaluationDataFormikValidation[key])
    })
    Object.keys(truckTransactionFormikValidation).map(key => {
      truckTransactionFormik.setFieldError(key, truckTransactionFormikValidation[key])
    })
    if (!isLogWoodEvaluationDataValid || !isTruckTransactionValid) {
      return false;
    }
    return true
  }

  React.useImperativeHandle(ref, () => ({
    handleSaveDetail: async () => {
      // validate general data
      const isFormValid = await validateForm();
      if( !isFormValid ) {
        return false;
      }

      // validate table data
      setDiameterTableValidationErrorMessages(null);
      setSpeciesTableValidationErrorMessages(null);
      const evaluateDiameterSumRatio = evaluateDiameters.reduce((pre, cur) => pre + Number(cur.percent || 0), 0)
      if (evaluateDiameterSumRatio !== 100) {
        let messages = `${t('error_messages.log_wood_po.evaluate_diameter_validation_error')} (${evaluateDiameterSumRatio}%)`
        Toast.error(messages)
        setDiameterTableValidationErrorMessages(messages);  
        return;
      }
      const speciesEvaluateSumRatio = speciesList.reduce((pre, cur) => pre + Number(cur.percent || 0), 0)
      if (speciesEvaluateSumRatio !== 100) {
        let messages = `${t('error_messages.log_wood_po.species_evaluate_validation_error')} (${speciesEvaluateSumRatio}%)`
        Toast.error(messages)
        setSpeciesTableValidationErrorMessages(messages);  
        return;
      }
      // submit truck, company, and product first so truck transaction can get related objects

      // check if data changed (assume changed data is not exist in back-end)
      // created data with following data to back-end
      const isSaveTruckCompanySuccess = (truckCompanyFormik.values.code !== truckTransaction.truck_company) ? 
        await truckCompanyDetail.handleCreate(truckCompanyFormik.values) :
        true;
      if (!isSaveTruckCompanySuccess) return false; 

      const isSaveTruckProductSuccess = (truckProductFormik.values.id !== truckTransaction.truck_product) ? 
        await truckProductDetail.handleCreate(truckProductFormik.values) :
        true;
      if (!isSaveTruckProductSuccess) return false; 

      const isSaveTruckSuccess = (truckFormik.values.id !== truckTransaction.truck) ? 
        await truckDetail.handleCreate(truckFormik.values) : 
        await truckDetail.handleSave(truckFormik.values)
      if (!isSaveTruckSuccess) return false; 

      const isSaveTruckTransactionSuccess = await truckTransactionDetail.handleSave(truckTransactionFormik.values);
      if (!isSaveTruckTransactionSuccess) return false;

      // submit cut weight data, diameter and species first 
      // so log wood eval data on grails server will update once
      const isSaveCutWeightSuccess = await cutWeightEvaluateDetail.handleListCreate({
        log_wood_evaluation_data: logWoodEvaluationData.id,
        data: cutWeights.filter(e => e.weight != 0)
      });

      const isSaveLogWoodDiameterSuccess = await evaluateDiameterDetail.handleListCreate({
        log_wood_evaluation_data: logWoodEvaluationData.id,
        data: evaluateDiameters.filter(e => e.percent > 0)
      })

      const isSaveSpeciesEvaluateSuccess = await speciesEvaluateDetail.handleListCreate({
        log_wood_evaluation_data: logWoodEvaluationData.id,
        data: speciesList.filter(e => e.percent > 0)
      });

      const isSaveLogWoodEvaluateDataSuccess = await logWoodEvaluationDataDetail.handleSave(logWoodEvaluationDataFormik.values);
      if (!isSaveLogWoodEvaluateDataSuccess) return false;

      return (
        isSaveTruckCompanySuccess &&
        isSaveTruckProductSuccess &&
        isSaveTruckSuccess &&
        isSaveTruckTransactionSuccess &&
        isSaveLogWoodEvaluateDataSuccess &&
        isSaveCutWeightSuccess &&
        isSaveLogWoodDiameterSuccess &&
        isSaveSpeciesEvaluateSuccess
      );
    },
    detail: {
      truck_transaction: truckTransactionFormik.values,
      log_wood_evaluation_data: {
        ...logWoodEvaluationDataFormik.values,
        log_wood_evaluate_diameter_list: evaluateDiameters,
        log_wood_cut_weight_evaluate_list: cutWeights,
      },
    }
  }));

  // Calculate length ratio
  const getLengthRatio = (amount) => {
    let ratio = formatComma(
      amount * 100 / (
        Number(logWoodEvaluationDataFormik.values.length_meet_criteria || 0) + 
        Number(logWoodEvaluationDataFormik.values.length_over_criteria || 0) + 
        Number(logWoodEvaluationDataFormik.values.length_under_criteria || 0)
      )
    )
    return ratio ? `${ratio} %` : "0 %";
  }

  React.useEffect(() => {
    truckTransactionFormik.setFieldValue('net_weight', net_weight);
  }, [net_weight]);

  React.useEffect(() => {
    logWoodEvaluationDataFormik.setFieldValue('std_buy_price', std_buy_price.toFixed(6));
  }, [std_buy_price]);

  React.useEffect(() => {
    logWoodEvaluationDataFormik.setFieldValue('cut_weight', cut_weight);
  }, [cut_weight]);

  React.useEffect(() => {
    logWoodEvaluationDataFormik.setFieldValue('buy_amount', buy_amount.toFixed(4));
  }, [buy_amount]);

  React.useEffect(() => {
    if (truckTransaction) {
      truckDetail.fetchData();
      truckProductDetail.fetchData();
      truckCompanyDetail.fetchData();
    }
  }, [truckTransaction]);

  React.useEffect(() => {
    setDetailLoading(
      truckCompanyDetail.isLoading ||
      truckProductDetail.isLoading ||
      truckDetail.isLoading ||
      truckTransactionDetail.isLoading ||
      cutWeightEvaluateDetail.isLoading ||
      evaluateDiameterDetail.isLoading ||
      logWoodEvaluationDataDetail.isLoading ||
      speciesEvaluateDetail.isLoading
    );
  }, [
    truckCompanyDetail.isLoading,
    truckProductDetail.isLoading,
    truckDetail.isLoading,
    truckTransactionDetail.isLoading,
    cutWeightEvaluateDetail.isLoading,
    evaluateDiameterDetail.isLoading,
    logWoodEvaluationDataDetail.isLoading,
    speciesEvaluateDetail.isLoading,
  ])

  React.useEffect(() => {
    if (cutWeightChoices.response && logWoodEvaluationData.id) {
      const newCutWeight = cutWeightChoices.response.map(choice => {
        const result = logWoodEvaluationData.log_wood_cut_weight_evaluate_list.find(cutWeight => cutWeight.choice === choice.id)
        return result ? {...result, choice_name: choice.name, weight: decimalDigitFormatter(result.weight)} : {
          choice: choice.id,
          choice_name: choice.name,
          weight: 0,
        }
      });
      setCutWeights(newCutWeight);
    }
      
    if (logWoodEvaluateSizeChoices.response && logWoodEvaluationData.id) {
      const newEvaluateDiameters = logWoodEvaluateSizeChoices.response.map(choice => {
        const result = logWoodEvaluationData.log_wood_evaluate_diameter_list.find(evaluateDiameter => {
          return (String(evaluateDiameter.name).localeCompare(String(choice.name)) === 0)
        });
        return result ? 
          {
            ...result,
            percent: decimalDigitFormatter(result.percent),
            choice: choice.id,
            name: choice.name, // set name base on new server
          } : 
          {
            name: choice.name,
            choice: choice.id,
            percent: 0,
            trade_std: choice.trade_std,
            weight_std: choice.weight_std,
            cost_std: choice.cost_std,
            sell_price: choice.sell_price,
          }
      });
      setEvaluateDiameters(newEvaluateDiameters);
    }

    if (logWoodSpeciesChoices.response && logWoodEvaluationData.id) {
      const newSpeciesEvaluate = logWoodSpeciesChoices.response.map(choice => {
        const result = logWoodEvaluationData.log_wood_species_evaluate_list.find(speciesEvaluate => speciesEvaluate.choice === choice.id)
        return result ? {...result, choice_name: choice.name, percent: decimalDigitFormatter(result.percent)} : {
          choice: choice.id,
          choice_name: choice.name,
          percent: 0,
        }
      })
      setSpeciesList(newSpeciesEvaluate);
    }

  }, [
    logWoodEvaluationData,
    cutWeightChoices.response,
    logWoodEvaluateSizeChoices.response,
    logWoodSpeciesChoices.response,
  ]);

  return (
    <Grid widths={1}>
      <Grid.Column>
        { (
            truckTransactionDetail.errorMessages ||
            logWoodEvaluationDataDetail.errorMessages || 
            truckDetail.errorMessages || 
            truckCompanyDetail.errorMessages ||
            truckProductDetail.errorMessages ||
            cutWeightEvaluateDetail.errorMessages ||
            evaluateDiameterDetail.errorMessages ||
            speciesEvaluateDetail.errorMessages
          ) &&
          <Message error>
            <Message.Header>{t('error')}!</Message.Header>
            <label>{ truckTransactionDetail.errorMessages }</label>
            <label>{ logWoodEvaluationDataDetail.errorMessages }</label>
            <label>{ truckDetail.errorMessages }</label>
            <label>{ truckCompanyDetail.errorMessages }</label>
            <label>{ truckProductDetail.errorMessages }</label>
            <label>{ cutWeightEvaluateDetail.errorMessages }</label>
            <label>{ evaluateDiameterDetail.errorMessages }</label>
            <label>{ speciesEvaluateDetail.errorMessages }</label>
          </Message>
        }
        { active &&
          <Form>
            <Form.Group widths='equal'>
              <Form.Input
                fluid
                label={t('truck_transactions.in_date_time')}
                transparent
                readOnly
                value={new Date(truckTransactionFormik.values.in_date_time).toLocaleString('en-GB')}
              />
              <Form.Input
                fluid
                label={t('truck_transactions.out_date_time')}
                transparent
                readOnly
                value={
                  truckTransactionFormik.values.out_date_time ? 
                  new Date(truckTransactionFormik.values.out_date_time).toLocaleString('en-GB'):
                  '-'
                }
              />
              <Form.Field
                fluid
                name='license_plate'
                label={t("trucks.license_plate")}
                placeholder={`${t('search')} ${t('trucks.license_plate')}...`}
                minCharacters={2}
                control={Search}
                results={trucks.response}
                showNoResults={enableEdit}
                onSearchChange={(_ ,data) => {
                  if (!enableEdit) {
                    return;
                  }
                  trucks.fetchData({search: data.value})
                  if (truckFormik.values.id) {
                    truckFormik.setValues(defaultTruckDetail);
                    truckTransactionFormik.setFieldValue('truck', null)
                  }
                  truckFormik.setFieldValue('license_plate', data.value)
                }}
                onResultSelect={(_, data) => {
                  truckFormik.setValues(data.result);
                  truckTransactionFormik.setFieldValue('truck', data.result.id)
                }}
                value={truckFormik.values.license_plate}
                error={truckTransactionFormik.errors.truck}
                loading={trucks.isLoading}
              />
              <Form.Dropdown
                fluid
                name='type'
                label={t('trucks.type')}
                placeholder={t('trucks.type')}
                selection
                selectOnBlur={false}
                options={truckTypes.response}
                value={truckFormik.values.type}
                error={truckFormik.errors.type}
                onChange={(_, data) => {
                  if (!enableEdit) {
                    return;
                  }
                  truckFormik.setFieldValue('type', data.value)
                }}
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field
                fluid
                label={t("truck_transactions.company")}
                placeholder={`${t('search')} ${t('searchs.fields.code')} ${t('or')} ${t('searchs.fields.name')}...`}
                minCharacters={2}
                control={Search}
                results={truckCompanies.response}
                showNoResults={enableEdit}
                onSearchChange={(_ ,data) => {
                  if (!enableEdit) {
                    return;
                  }
                  truckCompanies.fetchData({search: data.value})
                  if (truckCompanyFormik.values.id) {
                    truckCompanyFormik.setValues(defaultTruckCompanyDetail);
                    truckTransactionFormik.setFieldValue('truck_company', null)
                  }
                  truckCompanyFormik.setFieldValue('name', data.value)
                }}
                onResultSelect={(_, data) => {
                  truckCompanyFormik.setValues(data.result);
                  truckTransactionFormik.setFieldValue('truck_company', data.result.code)
                }}
                value={truckCompanyFormik.values.code ? `[${truckCompanyFormik.values.code}] - ${truckCompanyFormik.values.name}` : truckCompanyFormik.values.name}
                error={truckTransactionFormik.errors.truck_company}
                loading={truckCompanies.isLoading}
              />
              <Form.Field
                fluid
                label={t("truck_transactions.product")}
                placeholder={`${t('search')} ${t('searchs.fields.code')} ${t('or')} ${t('searchs.fields.name')}...`}
                minCharacters={2}
                control={Search}
                results={truckProducts.response}
                showNoResults={enableEdit}
                onSearchChange={(_ ,data) => {
                  if (!enableEdit) {
                    return;
                  }
                  truckProducts.fetchData({search: data.value})
                  if (truckProductFormik.values.id) {
                    truckProductFormik.setValues(defaultTruckProductDetail);
                    truckTransactionFormik.setFieldValue('truck_product', null)
                  }
                  truckProductFormik.setFieldValue('name', data.value)
                }}
                onResultSelect={(_, data) => {
                  truckProductFormik.setValues(data.result);
                  truckTransactionFormik.setFieldValue('truck_product', data.result.id)
                }}
                value={truckProductFormik.values.name}
                error={truckTransactionFormik.errors.truck_product}
                loading={truckProducts.isLoading}
              />
              <Form.Dropdown
                fluid
                label={t('truck_transactions.type')}
                selection
                options={transactionTypes.response}
                value={truckTransactionFormik.values.transaction_type}
                error={truckTransactionFormik.errors.transaction_type}
                onChange={(_, data) => {
                  if (!enableEdit) {
                    return;
                  }
                  truckTransactionFormik.setFieldValue('transaction_type', data.value)
                }} 
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Dropdown
                fluid
                name='courtyard'
                label={t('log_wood_evaluation_datas.courtyard')}
                selection
                selectOnBlur={false}
                options={courtyards.response}
                value={logWoodEvaluationDataFormik.values.courtyard}
                error={logWoodEvaluationDataFormik.errors.courtyard}
                onChange={(_, data) => {
                  if (!enableEdit) {
                    return;
                  }
                  logWoodEvaluationDataFormik.setFieldValue('courtyard', data.value)
                }}
              />
              <Form.Input
                fluid
                readOnly={!enableEdit}
                name='location_code'
                label={t('log_wood_evaluation_datas.location_code')}
                value={logWoodEvaluationDataFormik.values.location_code}
                error={logWoodEvaluationDataFormik.errors.location_code}
                onChange={logWoodEvaluationDataFormik.handleChange}
              />
              <Form.Dropdown
                fluid
                name='log_wood_type'
                label={t('log_wood_evaluation_datas.log_wood_type')}
                selection
                selectOnBlur={false}
                options={logWoodTypes.response}
                value={logWoodEvaluationDataFormik.values.log_wood_type}
                error={logWoodEvaluationDataFormik.errors.log_wood_type}
                onChange={(_, data) => {
                  if (!enableEdit) {
                    return;
                  }
                  logWoodEvaluationDataFormik.setFieldValue('log_wood_type', data.value)
                }}
              />
              <Form.Dropdown
                fluid
                name='eval_length'
                label={t('log_wood_evaluation_datas.log_wood_length')}
                selection
                selectOnBlur={false}
                options={logWoodEvaluateLengths.response}
                value={logWoodEvaluationDataFormik.values.eval_length}
                error={logWoodEvaluationDataFormik.errors.eval_length}
                onChange={(_, data) => {
                  if (!enableEdit) {
                    return;
                  }
                  logWoodEvaluationDataFormik.setFieldValue('eval_length', data.value)
                }}
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Input
                label={t('truck_transactions.in_weight')}
                transparent
                readOnly
                value={`${formatComma(truckTransactionFormik.values.in_weight)} ${t('units.kilo_gram')}`}
              />
              <Form.Input
                label={t('truck_transactions.out_weight')}
                transparent
                readOnly
                value={`${formatComma(truckTransactionFormik.values.out_weight)} ${t('units.kilo_gram')}`}
              />
              <Form.Input
                label={t('log_wood_evaluation_datas.cut_weight')}
                transparent
                readOnly
                value={`${formatComma(logWoodEvaluationDataFormik.values.cut_weight)} ${t('units.kilo_gram')}`}
              />
              <Form.Input
                label={t('truck_transactions.net_weight')}
                transparent
                readOnly
                value={`${formatComma(truckTransactionFormik.values.net_weight)} ${t('units.kilo_gram')}`}
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field error={logWoodEvaluationDataFormik.errors.buy_price}>
                <label>{t('log_wood_evaluation_datas.buy_price')}</label>
                <Input
                  readOnly={!enableEdit}
                  fluid
                  label={{ basic: true, content: `${t('units.baht')}/${t('units.kilo_gram')}` }}
                  name='buy_price'
                  labelPosition='right'
                  value={logWoodEvaluationDataFormik.values.buy_price}
                  onBlur={_ => {
                    logWoodEvaluationDataFormik.setFieldValue('buy_price', decimalDigitFormatter(logWoodEvaluationDataFormik.values.buy_price))
                  }}
                  onChange={logWoodEvaluationDataFormik.handleChange}
                />
              </Form.Field>
              <Form.Field error={logWoodEvaluationDataFormik.errors.buy_amount}>
                <label>{t('log_wood_evaluation_datas.buy_amount')}</label>
                <Input
                  readOnly
                  fluid
                  label={{ basic: true, content: `${t('units.baht')}/${t('units.kilo_gram')}` }}
                  name='buy_amount'
                  labelPosition='right'
                  value={formatComma(logWoodEvaluationDataFormik.values.buy_amount)}
                />
              </Form.Field>
              <Form.Field error={logWoodEvaluationDataFormik.errors.return_wood}>
                <label>{t('log_wood_evaluation_datas.return_wood')}</label>
                <Input
                  readOnly={!enableEdit}
                  fluid
                  label={{ basic: true, content: t('units.log_wood') }}
                  name='return_wood'
                  labelPosition='right'
                  value={logWoodEvaluationDataFormik.values.return_wood}
                  onChange={(_, data) => {
                    logWoodEvaluationDataFormik.setFieldValue('return_wood', decimalDigitFormatter(data.value, 0))
                  }}
                />
              </Form.Field>
            </Form.Group>
            <Form.TextArea
              readOnly={!enableEdit}
              name='wood_quality'
              placeholder={t('log_wood_evaluation_datas.wood_quality')}
              label={t('log_wood_evaluation_datas.wood_quality')}
              value={logWoodEvaluationDataFormik.values.wood_quality}
              error={logWoodEvaluationDataFormik.errors.wood_quality}
              onChange={logWoodEvaluationDataFormik.handleChange}
            />
            <Form.Group widths='3'>
              <Form.Dropdown
                fluid
                name='wood_plot'
                label={t('log_wood_evaluation_datas.wood_plot')}
                selection
                selectOnBlur={false}
                options={woodPlotChoices.response}
                value={logWoodEvaluationDataFormik.values.wood_plot}
                error={logWoodEvaluationDataFormik.errors.wood_plot}
                onChange={(_, data) => {
                  if (!enableEdit) {
                    return;
                  }
                  logWoodEvaluationDataFormik.setFieldValue('wood_plot', data.value)
                }}
              />
            </Form.Group>
            {/* เกณฑ์ความยาวไม้ */}
            <Form.Field style={{marginTop: '3rem'}}>
              <label>{t('log_wood_evaluation_datas.wood_length_criteria')}</label>
              <Table unstackable celled definition>
                {/* Header */}
                <Table.Header>
                  <Table.Row textAlign={"center"}>
                    <Table.HeaderCell/>
                    <Table.HeaderCell>{t('datas.amount')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('datas.ratio')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                {/* Body */}
                <Table.Body>
                  <Table.Row textAlign={"center"}>
                    <Table.Cell content={t('log_wood_evaluation_datas.length_meet_criteria')} />
                    <Table.Cell>
                      <Form.Field inline>
                        <input
                          readOnly={!enableEdit}
                          name='length_meet_criteria'
                          style={{textAlign: "center", width: 100}}
                          value={logWoodEvaluationDataFormik.values.length_meet_criteria}
                          onChange={(e) => {
                            logWoodEvaluationDataFormik.setFieldValue('length_meet_criteria', decimalDigitFormatter(e.target.value, 0))
                          }}
                          onBlur={() => { 
                            if(!logWoodEvaluationDataFormik.values.length_meet_criteria || 
                                isNaN(logWoodEvaluationDataFormik.values.length_meet_criteria)
                              ) {
                              logWoodEvaluationDataFormik.setFieldValue('length_meet_criteria', 0)
                            }
                          }}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                      {getLengthRatio(logWoodEvaluationDataFormik.values.length_meet_criteria)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row textAlign={"center"}>
                    <Table.Cell content={t('log_wood_evaluation_datas.length_under_criteria')} />
                    <Table.Cell>
                      <Form.Field inline>
                        <input
                          readOnly={!enableEdit}
                          name='length_under_criteria'
                          style={{textAlign: "center", width: 100}}
                          value={logWoodEvaluationDataFormik.values.length_under_criteria}
                          onChange={(e) => {
                            logWoodEvaluationDataFormik.setFieldValue('length_under_criteria', decimalDigitFormatter(e.target.value, 0))
                          }}
                          onBlur={() => { 
                            if(!logWoodEvaluationDataFormik.values.length_under_criteria || 
                                isNaN(logWoodEvaluationDataFormik.values.length_under_criteria)
                              ) {
                              logWoodEvaluationDataFormik.setFieldValue('length_under_criteria', 0)
                            }
                          }}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                      {getLengthRatio(logWoodEvaluationDataFormik.values.length_under_criteria)}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row textAlign={"center"}>
                    <Table.Cell content={t('log_wood_evaluation_datas.length_over_criteria')} />
                    <Table.Cell>
                      <Form.Field inline>
                        <input
                          readOnly={!enableEdit}
                          name='length_over_criteria'
                          style={{textAlign: "center", width: 100}}
                          value={logWoodEvaluationDataFormik.values.length_over_criteria}
                          onChange={(e) => {
                            logWoodEvaluationDataFormik.setFieldValue('length_over_criteria', decimalDigitFormatter(e.target.value, 0))
                          }}
                          onBlur={() => { 
                            if(!logWoodEvaluationDataFormik.values.length_over_criteria || 
                                isNaN(logWoodEvaluationDataFormik.values.length_over_criteria)
                              ) {
                              logWoodEvaluationDataFormik.setFieldValue('length_over_criteria', 0)
                            }
                          }}
                        />
                      </Form.Field>
                    </Table.Cell>
                    <Table.Cell>
                      {getLengthRatio(logWoodEvaluationDataFormik.values.length_over_criteria)}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Form.Field>
            {/* พันธุ์ */}
            <Form.Field style={{marginTop: '3rem'}} inline>
              <label>{t('log_wood_evaluation_datas.log_wood_specie')}</label>
              { speciesTableValidationErrorMessages &&
                <Label basic color='red' pointing='left'>{speciesTableValidationErrorMessages}</Label>
              }
              {/* Mobile */}
              <Media at="sm">
                <Table unstackable celled definition>
                  <Table.Header>
                    <Table.HeaderCell width={7} />
                    <Table.HeaderCell textAlign="center" width={9}>{t('datas.ratio')} (%)</Table.HeaderCell>
                  </Table.Header>
                  {/* Body */}
                  <Table.Body>
                    {
                      speciesList.map((species, index) => {
                        return (
                          <Table.Row textAlign={"center"} key={index.toString()}>
                            <Table.Cell 
                              key={`${species.choice_name}-header`} 
                              content={species.choice_name} />
                            <Table.Cell 
                              key={`species-cell-${index}`} 
                              error={speciesTableValidationErrorMessages}>
                              <Form.Field>
                                <input
                                  readOnly={!enableEdit}
                                  style={{textAlign: "center", width: 100}}
                                  value={species.percent}
                                  onChange={(e) => {
                                    let newSpeciesEvaluate = speciesList;
                                    newSpeciesEvaluate[index].percent = e.target.value;
                                    setSpeciesList([...newSpeciesEvaluate]);
                                    setSpeciesTableValidationErrorMessages(false);
                                  }}
                                  onBlur={() => {
                                    if (!speciesList[index].percent || isNaN(speciesList[index].percent)) {
                                      let newSpeciesEvaluate = speciesList;
                                      newSpeciesEvaluate[index].percent = 0;
                                      setSpeciesList([...newSpeciesEvaluate]);
                                    }
                                  }}
                                />
                              </Form.Field>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })
                    }
                  </Table.Body>
                </Table>
              </Media>
              {/* Desktop */}
              <Media greaterThan="sm">
                <Grid columns='2'>
                  {[[...speciesList.slice(0, Math.ceil(speciesList.length/2))], [...speciesList.slice(Math.ceil(speciesList.length/2))]].map((segment, isSecondHalf) => (
                    <Grid.Column>
                      <Table unstackable celled definition>
                        <Table.Header>
                          <Table.HeaderCell width={7} />
                          <Table.HeaderCell textAlign="center" width={9}>{t('datas.ratio')} (%)</Table.HeaderCell>
                        </Table.Header>
                        {/* Body */}
                        <Table.Body>
                          {
                            segment.map((species, index) => {
                              const speciesIndex = isSecondHalf ? Math.ceil(speciesList.length/2) + index : index;
                              return (
                                <Table.Row textAlign={"center"} key={speciesIndex.toString()}>
                                  <Table.Cell 
                                    key={`${species.choice_name}-header`} 
                                    content={species.choice_name} />
                                  <Table.Cell 
                                    key={`species-cell-${speciesIndex}`} 
                                    error={speciesTableValidationErrorMessages}>
                                    <Form.Field>
                                      <input
                                        readOnly={!enableEdit}
                                        style={{textAlign: "center", width: 100}}
                                        value={species.percent}
                                        onChange={(e) => {
                                          let newSpeciesEvaluate = speciesList;
                                          console.log(speciesList)
                                          newSpeciesEvaluate[speciesIndex].percent = e.target.value;
                                          setSpeciesList([...newSpeciesEvaluate]);
                                          setSpeciesTableValidationErrorMessages(false);
                                        }}
                                        onBlur={() => {
                                          if (!speciesList[speciesIndex].percent || isNaN(speciesList[speciesIndex].percent)) {
                                            let newSpeciesEvaluate = speciesList;
                                            newSpeciesEvaluate[speciesIndex].percent = 0;
                                            setSpeciesList([...newSpeciesEvaluate]);
                                          }
                                        }}
                                      />
                                    </Form.Field>
                                  </Table.Cell>
                                </Table.Row>
                              )
                            })
                          }
                        </Table.Body>
                      </Table>
                    </Grid.Column>

                  ))}
                </Grid>
              </Media>
            </Form.Field>
            {/* ขนาดไม้ */}
            <Form.Field style={{marginTop: '3rem'}} inline>
              <label>{t('log_wood_evaluation_datas.log_wood_diameter')}</label>
              { diameterTableValidationErrorMessages &&
                <Label basic color='red' pointing='left'>{diameterTableValidationErrorMessages}</Label>
              }
              {/* Mobile */}
              <Media at="sm">
                <Table unstackable celled definition>
                  <Table.Header>
                    <Table.HeaderCell width={7} />
                    <Table.HeaderCell textAlign="center" width={9}>{t('datas.ratio')} (%)</Table.HeaderCell>
                  </Table.Header>
                  {/* Body */}
                  <Table.Body>
                    {
                      evaluateDiameters.map((evaluateDiameter, index) => {
                        return (
                          <Table.Row textAlign={"center"} key={index.toString()}>
                            <Table.Cell 
                              key={`${evaluateDiameter.name}-header`} 
                              content={evaluateDiameter.name} />
                            <Table.Cell 
                              key={`diameters-cell-${index}`} 
                              error={diameterTableValidationErrorMessages}>
                              <Form.Field>
                                <input
                                  readOnly={!enableEdit}
                                  style={{textAlign: "center", width: 100}}
                                  value={evaluateDiameter.percent}
                                  onChange={(e) => {
                                    let newEvaluateDiameters = evaluateDiameters;
                                    newEvaluateDiameters[index].percent = e.target.value;
                                    setEvaluateDiameters([...newEvaluateDiameters]);
                                    setDiameterTableValidationErrorMessages(false);
                                  }}
                                  onBlur={() => {
                                    if (!evaluateDiameters[index].percent || isNaN(evaluateDiameters[index].percent)) {
                                      let newEvaluateDiameters = evaluateDiameters;
                                      newEvaluateDiameters[index].percent = 0;
                                      setEvaluateDiameters([...newEvaluateDiameters]);
                                    }
                                  }}
                                />
                              </Form.Field>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })
                    }
                  </Table.Body>
                </Table>
              </Media>
              {/* Desktop */}
              <Media greaterThan="sm">
                <Table celled definition>
                  <Table.Header>
                    <Table.Row textAlign='center'>
                      <Table.HeaderCell/>
                      {
                        logWoodEvaluateSizeChoices.response.map(choice => (
                          <Table.HeaderCell key={`${choice.name}-header`}>
                            {choice.name}
                          </Table.HeaderCell>
                        ))
                      }
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row error={diameterTableValidationErrorMessages} textAlign='center'>
                      <Table.Cell>{t('datas.ratio')} (%)</Table.Cell>
                      {
                        evaluateDiameters.map((evaluateDiameter, index) => {
                          return (
                            <Table.Cell key={`diameters-cell-${index}`}>
                              <Form.Field>
                                <input
                                  readOnly={!enableEdit}
                                  style={{textAlign: "center", width: 100}}
                                  value={evaluateDiameter.percent}
                                  onChange={(e) => {
                                    let newEvaluateDiameters = evaluateDiameters;
                                    newEvaluateDiameters[index].percent = e.target.value;
                                    setEvaluateDiameters([...newEvaluateDiameters]);
                                    setDiameterTableValidationErrorMessages(false);
                                  }}
                                  onBlur={() => {
                                    if (!evaluateDiameters[index].percent || isNaN(evaluateDiameters[index].percent)) {
                                      let newEvaluateDiameters = evaluateDiameters;
                                      newEvaluateDiameters[index].percent = 0;
                                      setEvaluateDiameters([...newEvaluateDiameters]);
                                    }
                                  }}
                                />
                              </Form.Field>
                            </Table.Cell>
                          )
                        })
                      }
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Media>
            </Form.Field>

            {/* น้ำหนักหัก */}
            <Form.Field style={{marginTop: '3rem'}}>
              <label>{t('log_wood_evaluation_datas.cut_weight')}</label>
              {/* Mobile */}
              <Media at="sm">
                <Table unstackable celled definition>
                  <Table.Header>
                    <Table.HeaderCell width={7} />
                    <Table.HeaderCell textAlign="center" width={9}>
                      {t('log_wood_evaluation_datas.cut_weight')} ({t('units.kilo_gram')})
                    </Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    {
                      cutWeights.map((cutWeight, index) => {
                        return (
                          <Table.Row textAlign={"center"} key={index.toString()}>
                            <Table.Cell key={`${cutWeight.choice_name}-header`} content={cutWeight.choice_name} />
                            <Table.Cell key={`cut-weight-cell-${index}`}>
                              <Form.Field>
                                <input
                                  readOnly={!enableEdit}
                                  style={{textAlign: "center", width: 100}}
                                  value={cutWeight.weight}
                                  onChange={(e) => {
                                    let newCutWeight = cutWeights;
                                    newCutWeight[index].weight = e.target.value;
                                    setCutWeights([...newCutWeight]);
                                  }}
                                  onBlur={() => {
                                    if (!cutWeights[index].weight || isNaN(cutWeights[index].weight)) {
                                      let newCutWeight = cutWeights;
                                      newCutWeight[index].weight = 0;
                                      setCutWeights([...newCutWeight]);
                                    }
                                  }}
                                />
                              </Form.Field>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })
                    }
                    <Table.Row textAlign='center'>
                      <Table.Cell content={t('datas.total')}/>
                      <Table.Cell>
                        <Form.Field>
                          <input
                            readOnly
                            style={{textAlign: 'center', border: 'white'}}
                            value={formatComma(cutWeights.reduce((prev, curr) => prev + Number(curr.weight), Number(0)))}
                          />
                        </Form.Field>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Media>
              {/* Desktop */}
              <Media greaterThan="sm"> 
                <Table celled definition>
                  <Table.Header>
                    <Table.Row textAlign='center'>
                      <Table.HeaderCell/>
                      {
                        cutWeightChoices.response.map(choice => (
                          <Table.HeaderCell key={`${choice.name}-header`}>
                            {choice.name}
                          </Table.HeaderCell>
                        ))
                      }
                      <Table.HeaderCell>
                        {t('datas.total')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row textAlign='center'>
                      <Table.Cell>{t('log_wood_evaluation_datas.cut_weight')} ({t('units.kilo_gram')})</Table.Cell>
                      {
                        cutWeights.map((cutWeight, index) => {
                          return (
                            <Table.Cell key={`cut-weight-cell-${index}`}>
                              <Form.Field>
                                <input
                                  readOnly={!enableEdit}
                                  style={{textAlign: "center", width: 100}}
                                  value={cutWeight.weight}
                                  onChange={(e) => {
                                    let newCutWeight = cutWeights;
                                    newCutWeight[index].weight = e.target.value;
                                    setCutWeights([...newCutWeight]);
                                  }}
                                  onBlur={() => {
                                    if (!cutWeights[index].weight || isNaN(cutWeights[index].weight)) {
                                      let newCutWeight = cutWeights;
                                      newCutWeight[index].weight = 0;
                                      setCutWeights([...newCutWeight]);
                                    }
                                  }}
                                />
                              </Form.Field>
                            </Table.Cell>
                          )
                        })
                      }
                      <Table.Cell>
                        <Form.Field>
                          <input
                            readOnly
                            style={{textAlign: 'center', border: 'white'}}
                            value={formatComma(cutWeights.reduce((prev, curr) => prev + Number(curr.weight), Number(0)))}
                          />
                        </Form.Field>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Media>
            </Form.Field>
          </Form>
        }
      </Grid.Column>
    </Grid>
  );
});

export default LogWoodPODetail;


LogWoodPODetail.defaultProps = {
  truckTransaction: {},
  enableEdit: false,
  logWoodEvaluationData: {},
  active: false,
  setDetailLoading: () => null,
};

LogWoodPODetail.propTypes = {
  truckTransactionId: propTypes.object,
  enableEdit: propTypes.bool,
  logWoodEvaluationDatatruckTransactionId: propTypes.object,
  active: propTypes.bool,
  setDetailLoading: propTypes.func,
};
