import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Header, Icon, List, Segment, Table } from "semantic-ui-react";
import * as _ from "lodash";
import { formatComma } from "../../utils/stringFormat";
import { URL_POST_BAKING } from "../../constance/urls";
import { DELETE } from "../../utils/HttpClient";
import { Toast } from "../../utils/Toast";
import ConfirmModal from "../../components/common/ConfirmModal";


export default function PostBakingWasteWoodCountList (props) {
  const {
    style,
    readOnly,
    dataList,
    onEdit,
    fetchData,
  } = props;

  const { t } = useTranslation();
  const [deleteTarget, setDeleteTarget] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleDelete = async () => {
    setLoading(true);
    try {
      await DELETE(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT_WOOD_SIZE_AMOUNT_MAP}${deleteTarget}/`);
      setDeleteTarget(null);
      fetchData();
    } catch (error) {
      Toast.error(`${t('delete')} ${t('error')} : ${error.errorMessages}`)
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <div style={{ margin: '4rem auto', width: 'fit-content', ...style }}>
        {dataList.length ? (
          <List relaxed>
            { dataList.map(data => (
              <List.Item>
                <Segment color='blue'>
                  <Header as='h5' style={{ display: 'flex', columnGap: '1rem', alignItems: 'center', justifyContent: 'space-between' }}>
                    <span>
                      {_.get(data, 'wood_code', '-')} : {formatComma(_.get(data, 'amount', 0))} {t('units.wood')}
                    </span>
                    {!readOnly && (
                      <span>
                        <Button
                          loading={loading}
                          basic color='blue' icon='edit'
                          size='mini' content={t('edit')}
                          onClick={() => onEdit(_.get(data, 'id', null))}/>
                        <Button
                          loading={loading}
                          basic color='red' icon='trash'
                          size='mini' onClick={() => setDeleteTarget(_.get(data, 'id', null))}/>
                      </span>
                    )}
                  </Header>
                </Segment> 
              </List.Item>
            )) }
          </List>
        ) : (
          <div>
            <Header textAlign='center' disabled>
              <Icon name='search'/> {t('empty')} 
            </Header>
          </div>
        )}
      </div>
      <ConfirmModal
        open={deleteTarget != null}
        onClose={() => setDeleteTarget(null)}
        title={`${t('confirms.delete_title')}`}
        content={`${t('confirms.delete_content')}`}
        onConfirm={handleDelete}/>
    </div>
  )
}

PostBakingWasteWoodCountList.propTypes = {
  style: PropTypes.bool,
  readOnly: PropTypes.bool,
  fullDetail: PropTypes.bool,
  dataList: PropTypes.array,
  onEdit: PropTypes.func,
  fetchData: PropTypes.func,
}

PostBakingWasteWoodCountList.defaultProps = {
  style: {},
  readOnly: false,
  fullDetail: false,
  dataList: [],
  onEdit: () => null,
  fetchData: () => null,
}