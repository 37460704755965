import { 
  defaultLogWoodEvaluationDataDetail,
  defaultTruckTransactionDetail,
  defaultLogWoodEvaluationDataLogSummary,
} from "."
import { defaultWoodImportTransactionDetail } from "./barcode"


export const defaultLogWoodPOWorkflowDetail = {
  assignee: [],
  id: '',
  memo: '',
  ref_timeout_date: null,
  state_name: '',
  truck_transaction: defaultTruckTransactionDetail,
  log_wood_evaluation_data: defaultLogWoodEvaluationDataDetail,
  workflow_created: '',
  workflow_created_user: null,
  workflow_actions: [],
  workflow_params: {},
  workflow_state_date: '',
  workflow_timeout: 0,
  log_summary: defaultLogWoodEvaluationDataLogSummary,
}

export const defaultWoodImportWorkflowDetail = {
  assignee: [],
  id: '',
  memo: '',
  ref_timeout_date: null,
  state_name: '',
  wood_import: defaultWoodImportTransactionDetail,
  workflow_created: '',
  workflow_created_user: null,
  workflow_actions: [],
  workflow_params: {},
  workflow_state_date: '',
  workflow_timeout: 0,
}

export const WORKFLOW_ACTION_BUTTON_COLOR = {
  input: 'green',
  approve: 'green',
  reject: 'red',
}

export const WORKFLOW_ACTION_BUTTON_ICON = {
  input: 'edit',
  approve: 'check',
  reject: 'cancel',
}