import React from 'react';
import * as _ from 'lodash';
import Cookies from 'js-cookie';
import { defaultUserModel, TOKEN_KEY, USER_KEY } from '../constance/dataTypes';



const UserContext = React.createContext({});

export function useUserContext() {
  return React.useContext(UserContext);
}

export default function UserSettingProvider({ children }) {
  // global state สำหรับใช้จัดการ feature ที่ขึ้นกับ user
  // component นี้ ถูกเรียกบน App.js ให้ component ล่าง ๆ เรียก useContext ได้
  // component อื่น ๆ เรียกใช้ state จาก useStoreContext
  // เช่น const { setting, setStaff, fetchStoreSetting, removeStoreSetting } = useStoreContext();
  const [user, setUser] = React.useState({
    ...defaultUserModel, 
    ...JSON.parse(Cookies.get(USER_KEY) ? Cookies.get(USER_KEY) : null)
  })

  const setUserData = (userData) => {
    Cookies.set(USER_KEY, JSON.stringify(userData), { expires: 30 })
    setUser({...defaultUserModel, ...userData})
  }

  const removeUserData = () => {
    Cookies.remove(USER_KEY)
    setUser(defaultUserModel)
  }

  const onLogin = (data) => {
    Cookies.set(TOKEN_KEY, _.get(data, TOKEN_KEY, null))
    setUserData(_.get(data, USER_KEY, {}))
  }

  const onLogout = () => {
    localStorage.removeItem('notification-token');
    Cookies.remove(TOKEN_KEY);
    Cookies.remove(USER_KEY);
    removeUserData();
  }

  return (
    <UserContext.Provider value={{ user, onLogin, onLogout, setUserData, removeUserData }}>
      {children}
    </UserContext.Provider>
  )
}