export const defaultTruckCompanyDetail = {
  id: null,
  code: '',
  name: '',
  remark: '',
  address: '',
};

export const defaultTruckProductDetail = {
  id: null,
  code: '',
  name: '',
  rate: 0,
};

export const defaultTruckDetail = {
  id: null,
  license_plate: '',
  type: null,
};

export const defaultTruckTransactionDetail = {
  id: null,
  transaction_type: null,
  truck: null,
  truck_company: null,
  truck_product: null,
  truck_sub_con: null,
  truck_user: null,
  in_date_time: '',
  in_weight: 0,
  out_date_time: '',
  out_weight: 0,
  net_weight: 0,
  ticket1: '',
  ticket2: '',
  batch_id: null,
  is_evaluated: false,
  is_printed: false,
  is_interfaced: false,
  license_plate: '',
  truck_type: '',
  truck_company_detail: {
    ...defaultTruckCompanyDetail
  },
  truck_product_name: '',
  transaction_type_name: '',
  created: null,
  updated: null,
};

