import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'semantic-ui-react';
import { PATH } from '../../../routes/Routes';
import { URL_WORKFLOW } from '../../../constance/urls';
import { useTranslation } from 'react-i18next';
import MobileList from '../../common/MobileList';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';


export default function WoodImportMobileList() {

  const history = useHistory();
  
  const { t } = useTranslation();
  const [date, setDate] = React.useState('');
  const tableRef = React.useRef();

	const content = {
		header: {
			icon: 'file alternate outline',
			detail: (item) => `${t('wood_imports.order_no')}: ${item.wood_import.order_no}`,
		},
		descriptions: [
			{
				icon: 'user',
				header: (item) => t('truck_transactions.company'),
				detail: (item) => item.wood_import.truck_company_name
			},
      {
				icon: 'truck',
				header: (item) => t('trucks.type'),
				detail: (item) => item.wood_import.truck_type_name
			},
		],
		onClick: (item) => history.push(`${PATH.WOOD_IMPORT}${item.id}`)
	}

  const dateFilterComponent = (
    <Form.Field
      label={t('searchs.fields.date')}
      locale='en-US'
      format='DD/MM/YY'
      showToday={true}
      placeholder={t('searchs.placeholders.date')}
      control={SemanticDatepicker}
      onChange={(_, data) => {
        if (data.value) {
          setDate(moment(data.value).format('YYYY-MM-DD'))
        } else {
          setDate('')
        }
      }}
    />
  )

  return (
    <div>
      <MobileList
        fetchUrl={URL_WORKFLOW.WOOD_IMPORT_WORKFLOW}
				content={content}
				showFilterHeader
				extraFilter={dateFilterComponent}
				extraFilterParams={{date: date}}
      />
    </div>
  );
}