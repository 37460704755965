import React from 'react'
import { useTranslation } from 'react-i18next'


export default function useLanguage() {
  const { i18n } = useTranslation();

  const [language, changeLanguage] = React.useState(null)

  const setLanguage = (lang) => {
    if (lang && (lang != i18n.language)) {
      i18n.changeLanguage(lang.toLocaleLowerCase());
      changeLanguage(lang);
    }
  }

  React.useEffect(() => {
    changeLanguage(localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'th')
  }, [language])

  return { language, setLanguage }
}