import React from 'react'
import PropTypes from 'prop-types';
import moment from 'moment';
import useDropdownItem from '../../hooks/useDropdownItem';
import useSearchItem from '../../hooks/useSeatchItem';
import { URL_SCALE_LARGE } from '../../constance/urls';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Form, Search } from 'semantic-ui-react';


const ReportLogWoodFilters = React.forwardRef((props, ref) => {

  const { t } = useTranslation();

  const truckCompanies = useSearchItem({ url: URL_SCALE_LARGE.TRUCK_COMPANY, description_render: 'name', title_render: 'code' });
  const transactionTypes = useDropdownItem({ url: URL_SCALE_LARGE.TRANSACTION_TYPE });

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      dateRange: Yup.array().min(1, 'required'),
    }),
    initialValues: {
      dateRange: [],
      truckCompany: '',
      truckCompanyCode: null,
      transactionTypeId: null,
    },
    onSubmit: (values) => {
      const startDate = moment(values.dateRange[0]).format('YYYY-MM-DD');
      const endDate = moment(values.dateRange[1]).format('YYYY-MM-DD');
      let queryString = `date_after=${startDate}&date_before=${endDate}`;
      if (values.truckCompanyCode != null) {
        queryString = `${queryString}&truck_company_code=${values.truckCompanyCode}`;
      }
      if (values.transactionTypeId != null) {
        queryString = `${queryString}&transaction_type_id=${values.transactionTypeId}`;
      }
      props.onFiltered(queryString)
    }
  });

  React.useImperativeHandle(ref, () => ({
    getParams: () => {
      const startDate = moment(formik.values.dateRange[0]).format('YYYY-MM-DD');
      const endDate = moment(formik.values.dateRange[1]).format('YYYY-MM-DD');
      let queryString = `date_after=${startDate}&date_before=${endDate}`;
      if (formik.values.truckCompanyCode != null) {
        queryString = `${queryString}&truck_company_code=${formik.values.truckCompanyCode}`;
      }
      if (formik.values.transactionTypeId != null) {
        queryString = `${queryString}&transaction_type_id=${formik.values.transactionTypeId}`;
      }
      return queryString;
    },
	}));

  return(
    <Form autoComplete="off" style={{marginTop: 24, marginBottom: 24}}>
      <Form.Group>
        <Form.Field
          id='form-input-control-dateRange'
          name='dateRange'
          inline
          label={"ตัวกรอง"}
          control={SemanticDatepicker}
          locale="en-US"
          type="range"
          format="DD/MM/YY"
          showToday={true}
          placeholder='เลือกช่วงเวลา'
          values={formik.values.dateRange}
          error={formik.errors.dateRange != null}
          onChange={(_, data) => {
            formik.setFieldValue('dateRange', data.value);
          }}
        />
        { !props.hideCustomerFilter &&        
          <Form.Field
            name='truckCompany'
            width={4}
            placeholder={`${t('search')} ${t('truck_transactions.company')}...`}
            minCharacters={2}
            control={Search}
            results={truckCompanies.response}
            showNoResults={true}
            onSearchChange={(_, data) => {
              truckCompanies.fetchData({ search: data.value })
              formik.setFieldValue('truckCompany', data.value);
              formik.setFieldValue('truckCompanyCode', '');
            }}
            onResultSelect={(_, data) => {
              formik.setFieldValue('truckCompany', data.result.name);
              formik.setFieldValue('truckCompanyCode', data.result.code);
            }}
            value={formik.values.truckCompany}
            error={formik.errors.truckCompany}
            loading={truckCompanies.isLoading}
          />
        }
        <Form.Dropdown
          placeholder='แหล่งที่มา'
          clearable={true}
          width={4}
          selection
          options={transactionTypes.response}
          value={formik.values.transactionTypeId}
          error={formik.errors.transactionTypeId}
          onChange={(_, data) => formik.setFieldValue('transactionTypeId', data.value)}
        />
        <Form.Button primary type='submit' onClick={formik.handleSubmit}>ค้นหา</Form.Button>
      </Form.Group>
    </Form>
  )
})

export default ReportLogWoodFilters;

ReportLogWoodFilters.propTypes = {
  onFiltered: PropTypes.func,
  style: PropTypes.object,
  hideCustomerFilter: PropTypes.bool,
}

ReportLogWoodFilters.defaultProps = {
  onFiltered: () => {},
  style: {},
  hideCustomerFilter: false,
}