import React from "react";
import { OVEN_TYPE_SELECT } from "../../constance/dataTypes";
import PropTypes, { object } from "prop-types";
import { Checkbox, Dimmer, Form, Header, Icon, Input, List, Loader, Segment } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import ErrorMessage from "../common/ErrorMessage";
import { GET } from "../../utils/HttpClient";


const OvenForm = React.forwardRef((props, ref) => {
  const { 
    style,
    readOnly,
    setUrl,
    params,
    formik,
  } = props;

  const { t } = useTranslation(); 
  const ovenNoRef = React.useRef();
  const [ovenTypeSelect, setovenTypeSelect] = React.useState(null);
  const [ovenSetList, setOvenSetList] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  React.useImperativeHandle(ref, () => ({
    initial: initialOvenTypeSelect
  }))

  const fetchOvenSet = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(setUrl, params)
      setOvenSetList(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const initialOvenTypeSelect = (ovens) => {
    if (!ovens || !ovens.length) {
      setovenTypeSelect(OVEN_TYPE_SELECT.ALL)
    } else if (ovens.length == 1) {
      setovenTypeSelect(OVEN_TYPE_SELECT.SPECIFIC)
    } else if (ovens.length > 1) {
      setovenTypeSelect(OVEN_TYPE_SELECT.SET)
    }
  }

  React.useEffect(() => {
    if (ovenTypeSelect == OVEN_TYPE_SELECT.SET) {
      fetchOvenSet()
    } else {
      setOvenSetList([]);
    }
  }, [ovenTypeSelect])

  return (
    <Form style={style}>
      <div style={{ width: 'fit-content', margin: 'auto', textAlign: 'left', marginTop: '2rem', marginBottom: '2rem' }}>
        <Form.Field>
          <Checkbox
            radio
            readOnly={readOnly}
            label={t('post_baking_waste_wood_count.all_oven')}
            value={OVEN_TYPE_SELECT.ALL}
            checked={ovenTypeSelect == OVEN_TYPE_SELECT.ALL}
            onChange={(__, data) => {
              setovenTypeSelect(data.value)
              formik.setFieldValue('ovens', [])
            }}/>
        </Form.Field>
        <Form.Group inline>
          <Form.Field>
            <Checkbox
              radio
              readOnly={readOnly}
              label={`${t('post_baking_waste_wood_count.specific_oven')} ${t('post_baking_waste_wood_count.oven_no')}`}
              value={OVEN_TYPE_SELECT.SPECIFIC}
              checked={ovenTypeSelect == OVEN_TYPE_SELECT.SPECIFIC}
              onChange={(__, data) => {
                setovenTypeSelect(data.value)
                if (ovenNoRef.current) {
                  ovenNoRef.current.focus()
                }
              }}/>
          </Form.Field>
          <Form.Field>
            <Input
              style={{ width: '70px' }}
              ref={ovenNoRef}
              type='number'
              readOnly={readOnly}
              placeholder={t('input')}
              value={formik.values.ovens}
              onChange={(__, data) => formik.setFieldValue('ovens', [data.value])}/>
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <Checkbox
            radio
            readOnly={readOnly}
            label={t('post_baking_waste_wood_count.oven_set')}
            value={OVEN_TYPE_SELECT.SET}
            checked={ovenTypeSelect == OVEN_TYPE_SELECT.SET}
            onChange={(__, data) => setovenTypeSelect(data.value)}/>
        </Form.Field>

        { ovenTypeSelect == OVEN_TYPE_SELECT.SET && (
          <Segment>
            <Header as='h4'>{t('select')} {t('post_baking_waste_wood_count.oven_set')}</Header>
            <Dimmer active={loading} inverted><Loader inverted/></Dimmer>
            { errorMessages && <ErrorMessage message={errorMessages}/> }
            { ovenSetList.length > 0 ? (
              <List>
                {ovenSetList.map(ovenSet => (
                  <List.Item>
                    <Form.Field>
                      <Checkbox
                        radio
                        label={ovenSet.join(', ')}
                        value={ovenSet}
                        checked={formik.values.ovens.join(',') == ovenSet.join(',')}
                        onChange={(_) => formik.setFieldValue('ovens', [...ovenSet])}/>
                    </Form.Field>
                  </List.Item>
                ))}
              </List>
            ) : (
              <Header disabled as='h4'>
                <Icon name='search'/>
                {t('empty')}
              </Header>
            ) }
          </Segment>        
        ) }
      </div>
    </Form>
  )
})

export default OvenForm;

OvenForm.propTypes = {
  style: PropTypes.object,
  setUrl: PropTypes.string,
  params: PropTypes.object,
  formik: PropTypes.object,
}

OvenForm.defaultProps = {
  style: {},
  setUrl: '',
  params: {},
  formik: {
    values: { },
    errors: { },
    setFieldValue: () => null,
    handleChange: () => null,
    handleSubmit: () => null,
  },
}