import React from 'react';
import propTypes from 'prop-types';
import { Button, Container, Grid } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import ConfirmModal from '../common/ConfirmModal';
import { WORKFLOW_ACTION_BUTTON_COLOR, WORKFLOW_ACTION_BUTTON_ICON } from '../../constance/dataTypes';


const ActionButton = (props) => {

  const {
    enableSave,
    enableCreate,
    actions,
    isLoading,
    handleSave,
    handleCreate,
    handleAction,
    disabled,
  } = props;

  const { t } = useTranslation();
  const [isConfirmVisible, setIsConfirmVisible] = React.useState(false);
  const [selectedAction, setSelectedAction] = React.useState('');

  return (
    <Grid>
      <Grid.Column textAlign='left' width={6}>
        { enableSave && 
          <Button
            type='submit'
            color='blue'
            icon='save'
            content={t('save')}
            disabled={disabled}
            loading={isLoading}
            onClick={handleSave}
          />
        }
      </Grid.Column>

      <Grid.Column textAlign='right' width={10}>
        {enableCreate && 
          <Button
            type='submit'
            color='green'
            icon='add'
            content={t('save')}
            disabled={disabled}
            loading={isLoading}
            onClick={handleCreate}
          />      
        }
        {actions.map(action => (
          <Button
            key={`${action}-button`}
            color={WORKFLOW_ACTION_BUTTON_COLOR[action.split('_').length > 1 ? action.split('_')[1] : null]}
            icon={WORKFLOW_ACTION_BUTTON_ICON[action.split('_').length > 1 ? action.split('_')[1] : null]}
            content={t(`workflows.actions.${action}`)}
            onClick={() => {
              setIsConfirmVisible(true);
              setSelectedAction(action);
            }}
            disabled={disabled}
            loading={isLoading}
          />
        ))}
      </Grid.Column>

      <ConfirmModal
        open={isConfirmVisible}
        onClose={() => {
          setIsConfirmVisible(false);
          setSelectedAction('');
        }}
        title={t('workflows.confirmation_title')}
        content={`${t('workflows.confirmation_content')}: [${selectedAction ? t(`workflows.actions.${selectedAction}`): ''}]?`}
        onConfirm={(memo) => handleAction({action: selectedAction, memo: memo})}
        showMemo
        isMemoRequired={selectedAction.split('_').find(a => a === 'reject') ? true : false}
      />
    </Grid>
  );
};

export default React.memo(ActionButton);

ActionButton.defaultProps = {
  disabled: false,
  enableSave: false,
  enableCreate: false,
	actions: [],
  isLoading: false,
  handleSave: () => null,
  handleCreate: () => null,
  handleAction: () => null,
  onActionSuccess: () => null,
}

ActionButton.propTypes = {
  disabled: propTypes.bool,
  enableSave: propTypes.bool,
  enableCreate: propTypes.bool,
	actions: propTypes.array,
  isLoading: propTypes.bool,
  handleSave: propTypes.func,
  handleCreate: propTypes.func,
  handleAction: propTypes.func,
  onActionSuccess: propTypes.func,
}
