import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dimmer, Divider, Header, Icon, Loader, Message, Modal, Popup, Segment } from 'semantic-ui-react';
import { GET, POST } from '../../utils/HttpClient';
import CustomTable from '../common/CustomTable';
import { Toast } from '../../utils/Toast';
import { URL_SYNC_DATA } from '../../constance/urls';
import { ellipsisString, formatComma } from '../../utils/stringFormat';
import * as _ from 'lodash';


export default function UpdateDataModal(props) {
  const {
    open,
    onClose,
    syncUrl,
    syncType,
    activeMenu,
  } = props;

  const ref = React.useRef();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [isHandleEnabled, setIsHandleEnabled] = React.useState(false);

  const columns = React.useMemo(() => [
    {
      Header: t('date'),
      accessor: 'date',
      Cell: ({ row }) => new Date(row.original.created).toLocaleString('en-GB')
    },
    {
      Header: t('sync_data_transactions.sync_count'),
      accessor: 'sync_count',
      Cell: ({ row }) => <div style={{ textAlign: 'center' }}>{formatComma(row.original.sync_count)}</div>
    },
    {
      Header: t('sync_data_transactions.is_sync_success'),
      accessor: 'is_sync_success',
      Cell: ({ row }) => row.original.is_sync_success == null ? t('progressing') : (
        <div style={{ textAlign: 'center' }}>{ row.original.is_sync_success ? <Icon color='green' name='check'/> : <Icon color='red' name='cancel'/> }</div>
      )
    },
    {
      Header: t('sync_data_transactions.is_update_success'),
      accessor: 'is_update_success',
      Cell: ({ row }) => (row.original.is_sync_success && row.original.is_update_success == null) || row.original.is_update_success == null ? t('progressing') : (
        <div style={{ textAlign: 'center' }}>{ row.original.is_update_success ? <Icon color='green' name='check'/> : <Icon color='red' name='cancel'/> }</div>
      )
    },
    {
      Header: t('detail'),
      accessor: 'error_message',
      Cell: ({ row }) => row.original.error_message ? (
        <div>
          <Popup inverted content={ellipsisString(row.original.error_message, 300)} trigger={<span>{ellipsisString(row.original.error_message, 30)}</span>}/>
        </div>
      ) : '-'
    }
  ])

  const handleProcess = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      await POST(syncUrl)
      Toast.success(t('progressing'))
      setIsHandleEnabled(false);
      if (ref.current) {
        ref.current.refetch();
      }
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }
  
  const checkSyncData = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_SYNC_DATA.CHECK_SYNC_DATA, { sync_type: syncType })
      setIsHandleEnabled(!_.get(response, 'data.is_working', false))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const refreshStatus = () => {
    checkSyncData();
    if (ref.current) {
      ref.current.refetch();
    }
  }

  React.useEffect(() => {
    if (open) {
      setErrorMessages(null);
      checkSyncData();
    }
  }, [open])

  return (
    <div>
      <Modal
        centered
        open={open}
        closeIcon
        onClose={() => { if (!isLoading) { onClose(); } }}>
        <Modal.Header>{ activeMenu ? t(`admins.${activeMenu}.title`) : '' }</Modal.Header>
        <Modal.Content>
          { errorMessages && <Message style={{ maxHeight: 300, overflow: 'auto' }} error>{errorMessages}</Message> }

          { isHandleEnabled ? (
            <Button primary loading={isLoading} onClick={handleProcess}>
              <Icon name='sync alternate'/>
              {t('sync_data_transactions.confirm')}
            </Button>
          ) : (
            <Button primary disabled>{t('progressing')}</Button>
          ) }

          <Divider/>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <Header floated='left'>{t('sync_data_tranaction')}</Header>
            <Button basic icon='redo' onClick={refreshStatus}/>
          </div>
          <CustomTable
            ref={ref}
            fetchUrl={URL_SYNC_DATA.SYN_DATA_TRANSACTION}
            extraFilterParams={{ sync_type: syncType }}
            columns={columns}
            showPagination/>
        </Modal.Content>
      </Modal>
    </div>
  )
}