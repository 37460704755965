import React from "react";
import { Button, Container, Dimmer, Form, Header, Loader } from "semantic-ui-react";
import MainHeader from "../../components/common/MainHeader";
import { useTranslation } from "react-i18next";
import { URL_POST_BAKING } from "../../constance/urls";
import * as _ from 'lodash';
import * as Yup from "yup";
import moment from "moment";
import { isDateValid } from "../../utils/stringFormat";
import { useHistory, useParams } from "react-router-dom";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { GET, PUT } from "../../utils/HttpClient";
import { Toast } from "../../utils/Toast";
import { defaultPostBakingWasteWoodInputDetail } from "../../constance/dataTypes";
import { useFormik } from "formik";
import ErrorMessage from "../../components/common/ErrorMessage";
import WasteWoodSizeAmountMapList from "../../components/post-baking-waste-wood-input/WasteWoodSizeAmountMapList";
import WasteWoodModal from "../../components/post-baking-waste-wood-input/WasteWoodModal";


export default function PostBakingWasteWoodInputDetailView() {

  const history = useHistory();
  const { t } = useTranslation();
  const { id } = useParams();

  const ovenFormRef = React.useRef();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [inputTarget, setInputTarget] = React.useState(null);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: defaultPostBakingWasteWoodInputDetail,
    validationSchema: Yup.object().shape({
      post_baking_group_date: Yup.string().required(t('error_messages.validation.required')),
      input_date: Yup.string().required(t('error_messages.validation.required')),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setErrorMessages(null)
      try {
        const response = await GET(URL_POST_BAKING.CHECK_POST_BAKING_GROUP, 
          { date: _.get(values, 'post_baking_group_date', ''), ovens: _.get(values, 'ovens', []).join(',') })
        if (!_.get(response, 'data.exists', false)) {
          Toast.error(t('error'))
          setErrorMessages(t('post_baking_waste_wood_count.errors.no_wood'))
          return;
        }  
        await PUT(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}${id}/`, values)
        fetchData();
        Toast.success(t('succes'))
      } catch (error) {
        Toast.error(t('error'))
        setErrorMessages(error.errorMessages)
      } finally {
        setLoading(false)
      }
    }
  });

  const isDone = _.get(formik, 'values.is_done', true)
  const editable = !_.get(formik, 'values.interfaced', false)

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}${id}/`)
      formik.setValues(response.data)
      if (ovenFormRef.current){
        ovenFormRef.current.initial(_.get(response, 'data.ovens', []))
      }
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [id])

  return (
    <div>
      <Container style={{ marginTop: '7em' }}>
        <MainHeader/>
        <Header as='h2'>{t('post_baking_waste_wood_input.detail')}</Header>
        <ErrorMessage message={errorMessages} length={100}/>
        <Dimmer active={loading} inverted>
          <Loader inverted>{t('loading')}</Loader>
        </Dimmer>
        <Form>
          <Form.Field
            inline
            readOnly={!editable}
            style={{ width: '100%' }}
            label={t('post_baking_waste_wood_count.group_date')}
            locale='en-US'
            format='DD/MM/YYYY'
            showToday={true}
            clearable={false}
            placeholder={t('post_baking_waste_wood_count.group_date')}
            control={SemanticDatepicker}
            value={isDateValid(formik.values.post_baking_group_date) ? new Date(formik.values.post_baking_group_date) : null}
            error={formik.errors.post_baking_group_date}
            onChange={(_, data) => {
              formik.setFieldValue('post_baking_group_date', moment(data.value).format('YYYY-MM-DD'))
            }}
          />
          <Form.Field
            inline
            readOnly={!editable}
            style={{ width: '100%' }}
            label={t('post_baking_waste_wood_input.input_date')}
            locale='en-US'
            format='DD/MM/YYYY'
            showToday={true}
            clearable={false}
            placeholder={t('post_baking_waste_wood_input.input_date')}
            control={SemanticDatepicker}
            value={isDateValid(formik.values.input_date) ? new Date(formik.values.input_date) : null}
            error={formik.errors.input_date}
            onChange={(_, data) => {
              formik.setFieldValue('input_date', moment(data.value).format('YYYY-MM-DD'))
            }}
          />
        </Form>

        <WasteWoodSizeAmountMapList
          readOnly={!editable}
          dataList={formik.values.wood_size_amount_map_list}
          onEdit={setInputTarget}
          fetchData={fetchData}/>

        {(!isDone && editable) && 
          <Button
            style={{ marginTop: '1rem' }}
            basic
            color='green'
            icon='plus'
            content={t('add')}
            onClick={() => setInputTarget('add')}/>
        }

        <WasteWoodModal
          transaction={id}
          open={inputTarget != null}
          onClose={() => {
            setInputTarget(null);
            fetchData();
          }}
          target={inputTarget}/>

        <Form>
          <Form.Group inline style={{ justifyContent: 'center' }}>
            { editable &&
              <Form.Button
                style={{ marginTop: '2rem' }}
                color='green'
                icon='save'
                content={t('save')}
                loading={loading}
                onClick={formik.handleSubmit}/>
            }
            <Form.Button
              style={{ marginTop: '2rem' }}
              content={t('back')}
              loading={loading}
              onClick={() => history.goBack()}/>
        </Form.Group>
        </Form>
      </Container>
    </div>
  )
}

