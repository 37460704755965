import firebase from 'firebase/app';
import 'firebase/messaging';
import { URL_AUTH } from './constance/urls';
import { POST } from './utils/HttpClient';


export const initializeFirebase = () => {
  firebase.initializeApp({
    apiKey: "AIzaSyDxytS9pOO6oraOw8o7vkZv-YLCK9sIcjI",
    authDomain: "qrcode-19c34.firebaseapp.com",
    projectId: "qrcode-19c34",
    messagingSenderId: "157647672229",
    appId: "1:157647672229:web:19ef2ee14ff36c31278e4c",
  });
}

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const notification_token = await messaging.getToken();
    console.log(notification_token)
    localStorage.setItem("notification-token", notification_token);
    
    await POST(URL_AUTH.REGISTER_NOTIFICATION_TOKEN, {notification_token});
  } catch (error) {
    console.error('get notification token error: ', error);
  }
};
