import React from "react";
import PropTypes from "prop-types";
import { Button, Dimmer, Grid, Header, List, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { URL_POST_BAKING } from "../../constance/urls";
import { GET } from "../../utils/HttpClient";
import ErrorMessage from "../common/ErrorMessage";
import * as _ from "lodash";
import { formatComma } from "../../utils/stringFormat";


export default function OvenSetDetail (props) {
  const {
    groupDate,
    ovens,
    onBack,
  } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [detailList, setDetailList] = React.useState([]);

  const fetchOvenSetDetail = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_POST_BAKING.OVEN_SET_DETAIL, { date: groupDate, ovens: ovens.join(',') })
      setDetailList(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (groupDate && ovens && ovens.length) {
      fetchOvenSetDetail()
    }
  }, [groupDate, ovens])

  return (
    <div style={{ marginTop: '3rem' }}>
      <Header as='h4'>{t('post_baking_waste_wood_count.oven_set_wood_status')}</Header>
      <Header as='h4' style={{ marginTop: 0 }}>{ovens.join(', ')}</Header>
      <ErrorMessage message={errorMessages}/>
      <Dimmer inverted active={loading}>
        <Loader/>
      </Dimmer>

      <List>
        {detailList.map(detail => (
          <List.Item>{_.get(detail, 'wood_code', '')} : {formatComma(_.get(detail, 'all_amount', 0))} {t('units.wood')} {t('datas.remain')} {formatComma(_.get(detail, 'left_amount', 0))} {t('units.wood')}</List.Item>
        ))}
      </List>

      <Button style={{ marginTop: '2rem' }} content={t('back')} onClick={onBack}/>
    </div>
  )
}


OvenSetDetail.propTypes = {
  groupDate: PropTypes.string,
  ovens: PropTypes.array,
  onBack: PropTypes.func,
}

OvenSetDetail.defaultProps = {
  groupDate: null,
  ovens: [],
  onBack: () => null,
}