import React from 'react';
import { Modal, Button, Form, Message, Input, List, Label, Header, Divider, Popup } from 'semantic-ui-react';
import propTypes from 'prop-types';
import { defaultTruckTransactionDetail, defaultFactoryWoodExportTransactionDetail, FACTORY_CHOICES } from '../../constance/dataTypes';
import { useTranslation } from 'react-i18next';
import useItemDetail from '../../hooks/useItemDetail';
import useItemListDetail from '../../hooks/useItemListDetail';
import { URL_BARCODE, URL_SCALE_LARGE } from '../../constance/urls';
import { useFormik } from 'formik';
import { Toast } from '../../utils/Toast';
import { ellipsisString, formatComma } from '../../utils/stringFormat';
import ConfirmModal from '../common/ConfirmModal';
import { PATCH } from '../../utils/HttpClient';
import SelectTruckTransactionModal from '../truck-transaction/SelectTruckTransactionModal';
import * as _ from 'lodash';
import CustomTable from '../common/CustomTable';


function FactoryWoodExportDetailModal (props) {

  const {
    open,
    onClose,
    detail,
  } = props;

  const { t } = useTranslation();
  
  const woodExportForm = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    initialValues: defaultFactoryWoodExportTransactionDetail,
    onSubmit: (values) => woodExport.handleSave(values)
  })

  const woodExport = useItemDetail({
    enableReinitialize: true,
    url: URL_BARCODE.WOOD_EXPORT_TRANSACTION,
    id: detail,
    manual: true,
    onSuccess: (type) => {
      switch(type) {
        case 'save':
          Toast.success(`${t('save')} ${t('success')}`);
          break;
        case 'export':
          Toast.success(`${t('factory_wood_exports.export')} ${t('success')}`);
          onClose();
          break
        default:
          Toast.success(`${t('success')}`);
          break
      }
    }
  })

  const truckTransactionItems = useItemListDetail({
    url: URL_SCALE_LARGE.TRUCK_TRANSACTION,
    idList: woodExportForm.values.truck_transactions,
    manual: true,
  })

  const columns = React.useMemo(() => [
    {
      Header: t('barcodes.barcode_id'),
      accessor: 'barcode_no',
    },
    {
      Header: t('barcodes.amount'),
      accessor: 'wood_size_amount_map_list',
      Cell: ({ row }) => (
        <div>
          <List as='ul'>
            { row.original.wood_size_amount_map_list.map(wood_size_amount_map => 
              <List.Item as='li'>{wood_size_amount_map.wood_code} - {formatComma(wood_size_amount_map.amount)} {t('units.wood')}</List.Item>
            )}
            <List.Item>
              {t('datas.total')} - {formatComma(
                row.original.wood_size_amount_map_list.reduce((prev, cur) => prev + Number(cur.amount), Number(0))
              )} {t('units.wood')}
            </List.Item>
          </List>
        </div>
      )
    },
  ], [])

  const [truckTransactions, setTruckTransactions] = React.useState([])
  const [isSelectTruckTransactionOpen, setIsSelectTruckTransactionOpen] = React.useState(false)
  const [isExportConfirmVisible, setIsExportConfirmVisible] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const handleSave = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      await truckTransactions.map( async (tt) => await PATCH(
        `${URL_SCALE_LARGE.TRUCK_COMPANY}${tt.truck_company}/`,
        { address: tt.truck_company_address }
      ))
      woodExportForm.handleSubmit();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const renderTruckTransactions = (truckTransaction) => {
    const license_plate = truckTransaction.license_plate
    const truck_company_name = _.get(truckTransaction, 'truck_company_detail.name', '')
    const in_date_time = new Date(truckTransaction.in_date_time).toLocaleString('en-GB')

    return (
      <Form.Group key={truckTransaction.id}>
        <Form.Field width='10'>
          <label>{t('trucks.license_plate')}</label>
          <Input
            readOnly
            value={`${license_plate}] - ${truck_company_name} (${in_date_time})`}/>
        </Form.Field>
        <Form.Field width='6'>
          <label>{t('trucks.address')}</label>
          <Input
            fluid
            value={truckTransaction.truck_company_address}
            onChange={(_, data) => {
              setTruckTransactions(truckTransactions.map(tt => tt.id !== truckTransaction.id ? tt : {
                ...tt, 
                truck_company_address: data.value
              }))
            }}
            action={{
              color: 'red',
              icon: 'delete',
              basic: true,
              onClick: () => setTruckTransactions(truckTransactions.filter(tt => tt.id !== truckTransaction.id))
            }}
          />
        </Form.Field>
      </Form.Group>
    )
  }

  React.useEffect(() => {
    woodExportForm.resetForm();
    woodExport.clearErrorMessages();
    setTruckTransactions([]);
    if (open && detail) {
      woodExport.fetchData();
    }
  }, [open])

  React.useEffect(() => {
    woodExportForm.setValues({ ...woodExportForm.values, ...woodExport.response})
  }, [woodExport.response])

  React.useEffect(() => {
    if (truckTransactionItems.response) {
      setTruckTransactions(
        truckTransactionItems.response
        .filter(item => item.errorMessages === "")
        .map(item => item.data)
      )
    }
  }, [truckTransactionItems.response])

  React.useEffect(() => {
    if (woodExportForm.values.truck_transactions.length > 0 && truckTransactions.length === 0) {
      // Only fetch data on first enter page
      truckTransactionItems.fetchData()
    }
  }, [woodExportForm.values.truck_transactions])

  React.useEffect(() => {
    if (truckTransactions != null)
      woodExportForm.setFieldValue('truck_transactions', truckTransactions.map(tt => tt.id))
  }, [truckTransactions])

  return (
    <div>
      <ConfirmModal
        open={isExportConfirmVisible}
        onClose={() => {
          setIsExportConfirmVisible(false);
        }}
        title={t('factory_wood_exports.export')}
        content={`${t('workflows.confirmation_content')}: [${t('factory_wood_exports.export')}]?`}
        onConfirm={() => {
          woodExport.handleAction('post', true, 'export')
          setIsExportConfirmVisible(false);
        }}
      />

      <SelectTruckTransactionModal
        open={isSelectTruckTransactionOpen}
        onClose={() => setIsSelectTruckTransactionOpen(false)}
        onSelect={tt => {
          if(truckTransactions.find( t => t.id === tt.id) == null) {
            setTruckTransactions([...truckTransactions, tt])
          }
        }}
        filterParams={{ transaction_type: '2', mapped: false }}
      />
      
      <Modal
        centered={false}
        open={open}
        onClose={onClose}
        closeIcon
      >
        <Modal.Header>{t('factory_wood_exports.detail')} - [{new Date(woodExportForm.values.created).toLocaleString('en-GB')}]</Modal.Header>
        <Modal.Content>
          {
            (
              woodExport.errorMessages || 
              errorMessages
            ) &&
            <Message error>
              <Message.Header>{t('error')}!</Message.Header>
              <label>{woodExport.errorMessages}</label>
              <label>{errorMessages}</label>
            </Message>
          }
          <Form>
            <Form.Group> 
              <Form.Input
                width={5}
                fluid
                label={t('date')}
                transparent
                readOnly
                value={new Date(woodExportForm.values.created).toLocaleString('en-GB')}
              />
              <Form.Input
                width={5}
                fluid
                label={t('factory_wood_exports.order_no')}
                transparent
                readOnly
                value={woodExportForm.values.order_no}
              />
              { woodExportForm.values.is_exported ? 
                (
                  <Form.Input
                    fluid
                    width={6}
                    label={t('factory_wood_exports.destination_factory')}
                    transparent
                    readOnly
                    value={`${woodExportForm.values.destination_factory} (${woodExportForm.values.destination_factory_display})`}
                  />
                ) : (
                  <Form.Dropdown
                    fluid
                    width={6}
                    label={t('factory_wood_exports.destination_factory')}
                    selection
                    selectOnBlur={false}
                    options={FACTORY_CHOICES}
                    value={woodExportForm.values.destination_factory}
                    error={woodExportForm.errors.destination_factory}
                    onChange={(_, data) => {
                      woodExportForm.setFieldValue('destination_factory', data.value)
                    }}
                  />
                )
              }
            </Form.Group>

            {woodExportForm.values.is_exported ? null :
              <Form.Field>
                <Button
                  basic
                  color='blue'
                  icon='add'
                  content={t('factory_wood_exports.add_truck_transaction')}
                  onClick={() => { 
                    setIsSelectTruckTransactionOpen(true); 
                  }}/>
              </Form.Field>
            }
            
            {truckTransactions.map(ttItem => renderTruckTransactions(ttItem))}

            <Form.Group widths='16'>
              <Form.Field width='16'>
                <label>{t('factory_wood_exports.export_products')}</label>
                <CustomTable
                  fetchUrl={`${URL_BARCODE.WOOD_EXPORT}${detail}/`}
                  columns={columns}
                  showPagination
                  extraFilterParams={{ page_size: 5 }}/>
              </Form.Field>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          { !woodExportForm.values.is_exported && truckTransactions.length > 0 &&
            <Button
              icon='truck'
              positive
              content={t('factory_wood_exports.export')}
              loading={woodExport.isLoading || isLoading}
              floated='left'
              onClick={() => setIsExportConfirmVisible(true)}
            />
          }
          <Button
            icon='cancel'
            content={t('cancel')}
            loading={woodExport.isLoading || isLoading}
            onClick={onClose}
          />
          { !woodExportForm.values.is_exported && 
            <Button
              primary
              icon='save'
              content={t('save')}
              loading={woodExport.isLoading || isLoading}
              onClick={() => handleSave()}
            />
          }
        </Modal.Actions>
      </Modal>
    </div>
  )
}

FactoryWoodExportDetailModal.defaultProps = {
  open: false,
  onClose: () => null,
  detail: defaultFactoryWoodExportTransactionDetail,
}

FactoryWoodExportDetailModal.propTypes = {
  open: propTypes.bool,
  onClose: propTypes.func,
  detail: propTypes.object,
}

export default React.memo(FactoryWoodExportDetailModal)