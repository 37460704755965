import React from "react";
import { useTranslation } from "react-i18next";
import ReportLogWoodDetail from "../../components/reports/ReportLogWoodDetail";
import { URL_LOG_WOOD } from "../../constance/urls";

function ReportLogWoodLengthByCompanyView() {

  const { t } = useTranslation();
  const columns = React.useMemo(() => [
    { 
      Header: "ลำดับที่", 
      columns: [{Header: "", accessor: "index", width: 80}] 
    },
    { 
      Header: "รหัสลูกค้า", 
      columns: [{ Header: "", accessor: "truck_company_code", width: 120 }]
    },
    { 
      Header: "ชื่อลูกค้า", 
      columns: [{Header: "", accessor: "truck_company_name", width: 220}] 
    },
    { 
      Header: "จำนวน (ท่อน)", 
      columns: [
        { Header: "สุ่มตรวจ", accessor: "report.sum_amount" },
        { Header: "ตามเกณฑ์", accessor: "report.meet_criteria" },
        { Header: "ยาวเกิน", accessor: "report.over_criteria" },
        { Header: "สั้นเกิน", accessor: "report.under_criteria" },
      ]
    },
    { 
      Header: "สัดส่วน (%)", 
      columns: [
        { 
          Header: "ตามเกณฑ์", 
          accessor: "report.meet_criteria_percent", 
          Cell: ({value}) => (<p>{parseFloat(value).toFixed(2)}</p>) 
        },
        { 
          Header: "ยาวเกิน", 
          accessor: "report.over_criteria_percent",
          Cell: ({value}) => (<p>{parseFloat(value).toFixed(2)}</p>)
        },
        { 
          Header: "สั้นเกิน", 
          accessor: "report.under_criteria_percent",
          Cell: ({value}) => (<p>{parseFloat(value).toFixed(2)}</p>)
        },
      ]
    },
    { 
      Header: "หมายเหตุ",
      columns: [{Header: "", accessor: "note", width: 160}]
    },
  ])

  return (
    <>
			<ReportLogWoodDetail 
			  title={t('menus.report_menus.log_wood_menus.log_wood_length_by_customer')}
        url={URL_LOG_WOOD.REPORT_LOG_WOOD_LENGTH_CUSTOMER}
        tableColumns={columns}
        exportUrl={URL_LOG_WOOD.REPORT_LOG_WOOD_LENGTH_CUSTOMER_EXPORT}
        hideCustomerFilter
      />
    </>
  )
}

export default ReportLogWoodLengthByCompanyView;