import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css';
import App from './App';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { initializeFirebase } from './push-notification';
import { LIFF_PATH } from "./routes/Routes";
import { LiffProvider } from 'react-liff-v2';

function getLiffId() {
  const paths = window.location.href.split('/');
  const path = paths[paths.indexOf('liff') + 1];
  const host = window.location.host;

  if (host === "wu.al8m.com") {
    if (path === LIFF_PATH.LOG_WOOD_PO) {
      return '1656532197-L3ORNgaR';
    }
    else {
      return '1656532197-L3ORNgaR';  // Default path
    }
  }
  else if (host === "wa.al8m.com") {
    if (path === LIFF_PATH.LOG_WOOD_PO) {
      return '1657084148-46g8kKrl';
    }
    else {
      return '1657084148-46g8kKrl';  // Default path
    }
  }
  else if (host === "wb.al8m.com") {
    if (path === LIFF_PATH.LOG_WOOD_PO) {
      return '1657095930-mvKkaaAb';
    }
    else {
      return '1657095930-mvKkaaAb';  // Default path
    }
  }
  else if (host === "wc.al8m.com") {
    if (path === LIFF_PATH.LOG_WOOD_PO) {
      return '1657022538-XMMe62jV';
    }
    else {
      return '1657022538-XMMe62jV';
    }
  }
  else if (host === "wn.al8m.com") {
    if (path === LIFF_PATH.LOG_WOOD_PO) {
      return '1657098811-MeWYBR1b';
    }
    else {
      return '1657098811-MeWYBR1b';
    }
  }
  else if (host === "wo.al8m.com") {
    if (path === LIFF_PATH.LOG_WOOD_PO) {
      return '1657089998-rl0gxgQ8';
    }
    else {
      return '1657089998-rl0gxgQ8';
    }
  }
  else if (host === "wp.al8m.com") {
    if (path === LIFF_PATH.LOG_WOOD_PO) {
      return '1657089967-X8RqLq9p';
    }
    else {
      return '1657089967-X8RqLq9p';
    }
  }
  else if (host === "wq.al8m.com") {
    if (path === LIFF_PATH.LOG_WOOD_PO) {
      return '1657084159-0ZR4b7We';
    }
    else {
      return '1657084159-0ZR4b7We';
    }
  }
  else if (host === "wt.al8m.com") {
    if (path === LIFF_PATH.LOG_WOOD_PO) {
      return '1657089949-kBm7a7KX';
    }
    else {
      return '1657089949-kBm7a7KX';
    }
  }
  else {
    // Unknown host redirect to wg server
    if (path === LIFF_PATH.LOG_WOOD_PO) {
      return '1656047547-q5R6558l';
    }
    else {
      return '1656047547-q5R6558l';  // Default path
    }
  }
}

ReactDOM.render(
  <React.StrictMode>
    <LiffProvider liffId={getLiffId()} stubEnabled={process.env.NODE_ENV !== 'production'}>
      <App />
    </LiffProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// initial firebase app for push notification
initializeFirebase();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
