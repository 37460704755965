import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message, Modal, Search } from 'semantic-ui-react';
import { URL_BARCODE, URL_WOOD_EXPORT } from '../../constance/urls';
import useSearchItem from '../../hooks/useSeatchItem';
import * as Yup from 'yup';
import { defaultBarcodeDetail, defaultExportItemDetail, PACKING_TYPE } from '../../constance/dataTypes';
import { Toast } from '../../utils/Toast';
import { POST } from '../../utils/HttpClient';
import * as _ from 'lodash';


export default function AddExportBarcodeModal(props) {
  const {
    open,
    onClose,
    fetchData,
    saleLineTarget,
  } = props;

  const { t } = useTranslation();
  const barcodeList = useSearchItem({ url: URL_BARCODE.BARCODE, title_render: 'barcode_no'})
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const closeModal = () => {
    fetchData();
    onClose();
  }

  const packingTypeChoices = Object.entries(PACKING_TYPE).map(
    ([_, value]) => ({ id: value, value: value, text: t(`export_transactions.packing_types.${value}`) })
  )

  const barcodeFormik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: defaultBarcodeDetail,
  })

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: { ...defaultExportItemDetail, export_sale_line: saleLineTarget },
    validationSchema: Yup.object().shape({
      packing_type: Yup.string().required(t('error_messages.validation.required')),
      humidity: Yup.string().required(t('error_messages.validation.required'))
    }),
    onSubmit: async (values) => {
      setErrorMessages(null);
      setIsLoading(true);
      try {
        let params = values
        if (!barcodeFormik.values.id) {
          const barcodeResponse = await POST(URL_BARCODE.BARCODE_PARSING, barcodeFormik.values)
          params['barcode'] = _.get(barcodeResponse, 'data.id',  null)
        }
        await POST(URL_WOOD_EXPORT.EXPORT_ITEM, params)
        Toast.success(t('success'))
        closeModal();
      } catch (error) {
        console.log(error)
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false);
      }
    }
  })

  React.useEffect(() => {
    if(open) {
      setErrorMessages(null);
      barcodeFormik.resetForm();
      formik.resetForm();
    }
  }, [open])

  return (
    <div>
      <Modal
        open={open}
        onClose={closeModal}
        closeIcon
        size='large'
        centered={false}
        closeOnDimmerClick={false}>
        <Modal.Header>{t('export_transactions.add')}</Modal.Header>
        <Modal.Content>
          { errorMessages && <Message error>{errorMessages}</Message> }
          <Form>
            <Form.Field
              fluid
              label={t('barcodes.barcode_id')}
              placeholder={`${t('search')} ${t('barcodes.barcode_id')}...`}
              minCharacters={2}
              control={Search}
              results={barcodeList.response}
              showNoResults={true}
              onSearchChange={(_ ,data) => {
                barcodeList.fetchData({search: data.value, export_available: true})
                if (formik.values.barcode) {
                  formik.setFieldValue('barcode', null)
                }
                barcodeFormik.setValues({...defaultBarcodeDetail, barcode_no: data.value})
              }}
              onResultSelect={(__, data) => {
                barcodeFormik.setValues(data.result);
                formik.setFieldValue('barcode', _.get(data, 'result.id', null))
              }}
              value={barcodeFormik.values.barcode_no}
              loading={barcodeList.isLoading}
            />
            <Form.Group widths='equal'>
              <Form.Select
                name='packing_type'
                label={t('export_transactions.packing_type')}
                options={packingTypeChoices}
                defaultValue={formik.values.packing_type}
                error={formik.errors.gradpacking_typee}
                onChange={(_, data) => {
                  formik.setFieldValue('packing_type', data.value);
                }}/>
              <Form.Input
                name='humidity'
                fluid
                label={t('export_transactions.humidity')}
                placeholder={`${t('export_transactions.humidity')}...`}
                value={formik.values.humidity}
                error={formik.errors.humidity}
                onChange={formik.handleChange}/>
            </Form.Group>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon='cancel'
            content={t('cancel')}
            loading={isLoading}
            onClick={onClose}/>
          <Button
            positive
            icon='add'
            content={t('add')}
            loading={isLoading}
            onClick={formik.handleSubmit}/>
        </Modal.Actions>
      </Modal>
    </div>
  )
}