import React from 'react';
import propTypes from 'prop-types';
import loading from '../../assets/lottie_report.json';
import Lottie from "lottie-react";
import { Button, Modal } from 'semantic-ui-react';
import { GET } from '../../utils/HttpClient';
import { Toast } from '../../utils/Toast';


export default function ReportExportButton(props) {
  const [showModal, setShowModal] = React.useState(false)
  const [blobData, setBlobData] = React.useState(null)

  React.useEffect(() => {
    if (showModal && blobData != null) {
      window.open(URL.createObjectURL(blobData));
      setShowModal(false);
      setBlobData(null);
    }
  }, [blobData])

  const generateReport = async () => {
    if (showModal) {
      return;  // Do nothing if modal already displayed
    }
    else if (props.url == null) {
      Toast.error("No url parameter")
      return;
    }

    setShowModal(true)
    try {
      props.onStarted()
      const response = await GET(props.url, props.params, false, true) // Download as blob
      setBlobData(response.data)
    } catch (error) {
      setShowModal(false);
      Toast.error(`Error exporting : ${error.errorMessages}`)
    } finally {
      props.onFinished()
    }
  }

  return(
    <Modal
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
      closeOnEscape={false}
      closeOnDimmerClick={false}
      open={showModal}
      size="mini"
      trigger={
        <Button
          type='button'
          basic={props.basic}
          color={props.color ? props.color : props.type === "excel" ? 'green' : 'red'}
          icon={props.icon ? props.icon : props.type === "excel" ? 'file excel' : 'file pdf'}
          content={props.content ? props.content : props.type === "excel" ? 'Excel' : 'PDF'}
          onClick={generateReport}
          size={props.size}
          disabled={props.disabled}
        />
      }
    >
      <Modal.Header>{props.title}</Modal.Header>
      <Modal.Content>
        <Lottie animationData={loading} loop={true} />
      </Modal.Content>
    </Modal>
  )
}

ReportExportButton.defaultProps = {
  url: "",
  type: "", // excel or pdf
  params: {},
  title: "",
  size: "mini",
  basic: false,
  content: null,
  color: null,
  icon: null,
  disabled: false,
  onStarted: () => null,
  onFinished: () => null,  // notify when printing finished
}

ReportExportButton.propTypes = {
  url: propTypes.string,
  type: propTypes.string, // excel or pdf
  params: propTypes.object,
  title: propTypes.string,
  size: propTypes.string,
  basic: propTypes.bool,
  content: propTypes.string,
  color: propTypes.string,
  icon: propTypes.string,
  disabled: propTypes.bool,
  onStarted: propTypes.func, 
  onFinished: propTypes.func,
}