export const INTERFACE_TYPE = {
  LOG_WOOD_PO: 'log_wood_po',
  PROCESSED_WOOD_PO: 'processed_wood_po',
  MOVEMENT_ISSUE: 'movement_issue',
  MOVEMENT_RECEIPT: 'movement_receipt',
  BOM_TRANSACTION: 'bom_transaction',
  EXPORT_TRANSACTION: 'export_transaction',
  POST_BAKING_WASTE_WOOD_COUNT: 'post_baking_waste_wood_count',
  WASTE_WOOD_ORDER: 'waste_wood_order',
  WASTE_WOOD_ORDER_GRADING: 'waste_wood_order_grading',
  BOM_WASTE_WOOD: 'bom_waste_wood',
};

export const defaultInterfaceTransactionDetail = {
  id: null,
  is_success: false,
  url: '',
  response_status_code: '',
  response_text: '',
  generate_data_datetime: '',
  request_datetime: '',
  response_datetime: '',
  finish_datetime: '',
  request_body: '',
  created: '',
}
