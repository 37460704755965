import React from 'react';
import { GET, POST } from '../utils/HttpClient';


export default function useWorkflowDetail ({ workflowUrl, id, onSuccess, manual=false, multiple=false }) {
  const [response, setResponse] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${workflowUrl}${id}/`)
      setResponse(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAction = async (args) => {
    let isSuccess = false;
    setIsLoading(true);
    setErrorMessages(null);
    if (!multiple) {
      try {
        const {
          action,
          memo,
        } = args;
        const response = await POST(`${workflowUrl}${id}/action/${action}/`, {memo});

        isSuccess = true;
        onSuccess(action);
      } catch (error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        const {
          action,
          memo,
        } = args;
        for (let workflowId of id) {
          const response = await POST(`${workflowUrl}${workflowId}/action/${action}/`, {memo});
        }

        isSuccess = true;
        onSuccess(action);
      } catch (error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setIsLoading(false);
      }
    }

    return isSuccess;
  }

  React.useEffect(() => {
    if (!manual) {
      fetchData();
    }
  }, []);

  return { response, fetchData, handleAction, isLoading, errorMessages };
}