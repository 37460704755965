import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dimmer, Form, Header, Icon, List, Loader, Message, Modal, Popup, Search, Tab, Table } from 'semantic-ui-react';
import { URL_BARCODE, URL_BOM } from '../../constance/urls';
import useSearchItem from '../../hooks/useSeatchItem';
import * as _ from 'lodash';
import { formatComma } from '../../utils/stringFormat';
import { Toast } from '../../utils/Toast';
import { POST } from '../../utils/HttpClient';
import { defaultBarcodeDetail } from '../../constance/dataTypes';


const DEBOUNCE_DELAY_MILLI_SEC = 500;
const BARCODE_LENGTH = 14;

export default function BOMModal(props) {
  const {
    open,
    onClose,
    transaction,
  } = props;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [selectedBarcodes, setSelectedBarcodes] = React.useState([]);

  // search barcode
  const [search, setSearch] = React.useState('');

  // detail barcode
  const barcodeList = useSearchItem({ url: URL_BARCODE.BARCODE })
  const [showDetailBarcode, setShowDetailBarcode] = React.useState(null)
  const [barcodeDetail, setBarcodeDetail] = React.useState(defaultBarcodeDetail)

  const handleSubmit = async () => {
    setErrorMessages(null);
    setIsLoading(true);
    try {
      await POST(`${URL_BOM.BOM_TRANSACTION}${transaction}${URL_BOM.ADD_BARCODE}`, { 'barcodes': selectedBarcodes.join(',') })
      Toast.success(t('success'))
      onClose()
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const fetchBarcodeDetail = async (barcode) => {
    setErrorMessages(null);
    setIsLoading(true);
    setBarcodeDetail(defaultBarcodeDetail);
    try {
      await barcodeList.fetchData({ search: barcode })
      setBarcodeDetail(_.get(barcodeList, 'response.0', {}))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleAddBarcode = () => {
    if (search && search.length >= BARCODE_LENGTH) {
      setSearch('')
      setSelectedBarcodes([...selectedBarcodes, search])
    }
  }

  React.useEffect(() => {
    if (!open) {
      setSearch('');
      setSelectedBarcodes([]);
    }
  }, [open])

  React.useEffect(() => {
    handleAddBarcode()
  }, [search])

  React.useEffect(() => {
    if (showDetailBarcode) {
      fetchBarcodeDetail(showDetailBarcode)
    }
  }, [showDetailBarcode])

  return (
    <div>
      <Modal
        size='large'
        centered={false}
        open={open}
        onClose={onClose}
        closeIcon
        closeOnDimmerClick={false}>
        <Modal.Header>{t(`bom_transactions.boms.add`)}</Modal.Header>
        <Modal.Content>
          {errorMessages && <Message error>{errorMessages}</Message>}
          <Form>
            <Form.Input
              label={t('barcodes.barcode_id')}
              fluid
              action={{
                content: t('export_transactions.add'),
                icon: 'plus',
                color: 'blue',
                onClick: handleAddBarcode,
              }}
              value={search}
              onChange={(_, data) => {
                setSearch(data.value)
              }}/>
          </Form>
          { selectedBarcodes.length ? (
            <List size='large'>
              <Header>{t('barcodes.list')}</Header>
              { selectedBarcodes.map((selectedBarcode, idx) => (
                <List.Item>
                  <List.Content>
                    <Icon name='caret right'/>
                    <span style={{ marginRight: '1rem' }}>
                      {selectedBarcode}
                    </span>
                    <Button
                      basic
                      color='blue'
                      icon='eye'
                      size='small'
                      onClick={() => setShowDetailBarcode(selectedBarcode)}/>
                    <Button
                      basic
                      negative
                      icon='trash'
                      size='small'
                      onClick={() => {
                        setSelectedBarcodes(selectedBarcodes.filter((_, i) => i != idx))
                      }}/>
                  </List.Content>
                </List.Item>
              )) }
            </List>
          ) : null }
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon='cancel'
            content={t('cancel')}
            loading={isLoading}
            onClick={onClose}/>
          <Button
            color='green'
            icon='add'
            disabled={!selectedBarcodes.length}
            content={t('add')}
            loading={isLoading}
            onClick={handleSubmit}/>
        </Modal.Actions>
      </Modal>
      <Modal
        centered={false}
        open={showDetailBarcode != null}
        closeIcon
        onClose={() => setShowDetailBarcode(null)}>
        <Modal.Header><Icon name='qrcode'/> {t(`barcodes.barcode_id`)}: {showDetailBarcode}</Modal.Header>
        <Modal.Content>
          <Dimmer inverted active={isLoading}>
            <Loader inverted/>
          </Dimmer>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t('wood_imports.wood_code')}</Table.HeaderCell>
                <Table.HeaderCell>{t('barcodes.amount')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.get(barcodeDetail, 'wood_size_amount_map_list', []).map(e => (
                <Table.Row>
                  <Table.Cell>{_.get(e, 'wood_code', '')}</Table.Cell>
                  <Table.Cell>{formatComma(_.get(e, 'amount', 0))}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            icon='close'
            content={t('close')}
            loading={isLoading}
            onClick={() => setShowDetailBarcode(null)}/>
        </Modal.Actions>
      </Modal>
    </div>
  )
}