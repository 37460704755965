import React from "react";
import { useTranslation } from 'react-i18next';
import MainHeader from "../../components/common/MainHeader";
import { Button, Container, Form, Header, Icon, Label, List, Menu, Popup, Tab } from "semantic-ui-react";
import SemanticDatepicker from "react-semantic-ui-datepickers";
import { useFormik } from "formik";
import * as _ from "lodash";
import moment from "moment";
import * as Yup from "yup";
import { GET, POST } from "../../utils/HttpClient";
import { URL_INTERFACE_AX, URL_POST_BAKING } from "../../constance/urls";
import { Toast } from "../../utils/Toast";
import ErrorMessage from "../../components/common/ErrorMessage";
import CustomTable from "../../components/common/CustomTable";
import InterfaceAXHistoryModal from "../../components/interface_ax/InterfaceAXHistoryModal";
import { INTERFACE_TYPE, WASTE_WOOD_ORDER_TYPE } from "../../constance/dataTypes";
import { formatComma } from "../../utils/stringFormat";

export default function InterfaceAxWasteWoodOrderView () {

  const { t } = useTranslation();
  const countRef = React.useRef();
  const inputRef = React.useRef();
  const [countTotal, setCountTotal] = React.useState(0);
  const [inputTotal, setInputTotal] = React.useState(0);
  const [allowInterfaceAX, setAllowInterfaceAX] = React.useState(false);
  const [openHistory, setOpenHistory] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [activeIndex, setAciveIndex] = React.useState()

  const fetchTable = () => {
    if (countRef.current) {
      countRef.current.refetch();
    }
    if (inputRef.current) {
      inputRef.current.refetch();
    }
  }

  const resetTable = () => {
    setCountTotal(0)
    if (countRef.current) {
      countRef.current.reset();
    }
    setInputTotal(0)
    if (inputRef.current) {
      inputRef.current.reset();
    }
  }

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      date: Yup.date().required(t('error_messages.validation.required')).nullable()
    }),
    initialValues: { date: null },
    onSubmit: () => {
      handleFetch();
    }
  })

  const countColumns = React.useMemo(() => [
    {
      Header: t('post_baking_waste_wood_count.group_date'),
      accessor: 'post_baking_group_date',
      Cell: ({ value }) => value ? moment(value).format('DD/MM/yyyy') : '-',
    },
    {
      Header: t('post_baking_waste_wood_count.oven'),
      accessor: 'ovens',
      Cell: ({ value }) => {
        if (!value || !value.length) {
          return t('post_baking_waste_wood_count.all_oven')
        }
        return value.join(', ')
      },
    },
    {
      Header: t('wood_size'),
      Cell: ({ row }) => {
        const value = _.get(row, 'original.wood_size_amount_map_list', [])
          .filter(el => _.get(el, 'waste_wood_order_type', '') == WASTE_WOOD_ORDER_TYPE.SHAPING_TYPE)
        return value ? (
            <List>
               { value.filter(data => _.get(data, 'amount', 0)).map(data => (
                 <List.Item>{_.get(data, 'wood_code', '')} - {`(${formatComma(_.get(data, 'amount', 0))})`} {formatComma(_.get(data, 'volume', 0))}</List.Item>
               )) }
            </List>
        ) : '-'
      }
    },
    {
      Header: t('interface_ax.is_interfaced'),
      accessor: 'is_interfaced_order',
      Cell: ({ row }) => {
        const value = _.get(row, 'original.wood_size_amount_map_list', [])
          .filter(el => _.get(el, 'waste_wood_order_type', '') == WASTE_WOOD_ORDER_TYPE.SHAPING_TYPE)
        return value ? (
            <List>
               { value.filter(data => _.get(data, 'amount', 0)).map(data => (
                 <List.Item>
                    <Icon name={data.is_interfaced_order ? 'check' : 'cancel'} color={data.is_interfaced_order ? 'green' : 'red'}/>
                 </List.Item>
               )) }
            </List>
        ) : '-'
      }
    },
  ])

  const inputColumns = React.useMemo(() => [
    {
      Header: t('barcodes.barcode_id'),
      accessor: 'barcode',
    },
    {
      Header: t('post_baking_waste_wood_count.group_date'),
      accessor: 'post_baking_group_date',
      Cell: ({ value }) => value ? moment(value).format('DD/MM/yyyy') : '-',
    },
    {
      Header: t('wood_size'),
      Cell: ({ row }) => {
        const value = _.get(row, 'original.wood_size_amount_map_list', [])
          .filter(el => _.get(el, 'waste_wood_order_type', '') == WASTE_WOOD_ORDER_TYPE.SHAPING_TYPE)
        return value ? (
            <List>
               { value.filter(data => _.get(data, 'amount', 0)).map(data => (
                 <List.Item>{_.get(data, 'wood_code', '')} - {`(${formatComma(_.get(data, 'amount', 0))})`} {formatComma(_.get(data, 'volume', 0))}</List.Item>
               )) }
            </List>
        ) : '-'
      }
    },
    {
      Header: t('interface_ax.is_interfaced'),
      accessor: 'is_interfaced_order',
      Cell: ({ row }) => {
        const value = _.get(row, 'original.wood_size_amount_map_list', [])
          .filter(el => _.get(el, 'waste_wood_order_type', '') == WASTE_WOOD_ORDER_TYPE.SHAPING_TYPE)
        return value ? (
            <List>
               { value.filter(data => _.get(data, 'amount', 0)).map(data => (
                 <List.Item>
                    <Icon name={data.is_interfaced_order ? 'check' : 'cancel'} color={data.is_interfaced_order ? 'green' : 'red'}/>
                 </List.Item>
               )) }
            </List>
        ) : '-'
      }
    },
  ])

  const handleDoInterfaceAX = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      await POST(URL_INTERFACE_AX.WASTE_WOOD_ORDER, { date: moment(formik.values.date).format('YYYY-MM-DD') })
      resetTable();
      Toast.info(t('progressing'))
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const handleFetch = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const countResponse = await GET(
        `${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT}count/`,
        { 
          date_before: moment(formik.values.date).format('YYYY-MM-DD'),
          date_after: moment(formik.values.date).format('YYYY-MM-DD'),
          order_type: WASTE_WOOD_ORDER_TYPE.SHAPING_TYPE,
        }
      )
      setCountTotal(_.get(countResponse, 'data.count', 0))
      const inputResponse = await GET(
        `${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}count/`,
        { 
          date_before: moment(formik.values.date).format('YYYY-MM-DD'), 
          date_after: moment(formik.values.date).format('YYYY-MM-DD'),
          order_type: WASTE_WOOD_ORDER_TYPE.SHAPING_TYPE,
        }
      ) 
      setInputTotal(_.get(inputResponse, 'data.count', 0))
      fetchTable();
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }

  }

  React.useEffect(() => {
    setAllowInterfaceAX(countTotal > 0 && inputTotal > 0);
  }, [countTotal, inputTotal])

  React.useEffect(() => {
    if (allowInterfaceAX) {
      setAllowInterfaceAX(false);
    }
  }, [formik.values])

  React.useEffect(() => {
    console.log(activeIndex)
    if (formik.values.date) {
      fetchTable()
    }
  }, [activeIndex])

  return (
    <div>
      <MainHeader/>
			<Container style={{ marginTop: '7em' }}>
        <Header as='h2'>
          {t('menus.interface_ax')}: {t('menus.interface_ax_menus.waste_wood_order')}
        </Header>

        <Form>
          <Form.Group inline style={{ justifyContent: 'center' }}>
            <Form.Field
              id='form-input-control-dateRange'
              name='dateRange'
              label={t('post_baking_waste_wood_count.group_date')}
              control={SemanticDatepicker}
              locale="en-US"
              format="DD/MM/YYYY"
              showToday={true}
              placeholder={t('searchs.fields.date')}
              value={formik.values.date}
              error={formik.errors.date}
              onChange={(_, data) => formik.setFieldValue('date', data.value)}
            />
            <Form.Button
              primary
              icon='search'
              content={t('search')}
              onClick={formik.handleSubmit}/>
            <Button
              type='button'
              primary
              basic
              icon='file alternate outline'
              content={t('interface_ax.history')}
              onClick={() => setOpenHistory(true)}/>
          </Form.Group>
        </Form>

        <Popup
          inverted
          content={`${t('select')} ${t('searchs.fields.date')}`}
          on='hover'
          position='bottom center'
          trigger={
            <span>
              <Button
                basic
                primary
                loading={loading}
                disabled={!allowInterfaceAX}
                content={t('interface_ax.submit')}
                onClick={() => handleDoInterfaceAX()}/>
            </span>
          }/>

        <ErrorMessage message={errorMessages} length={100}/>

        <Tab 
          menu={{ secondary: true, pointing: true }} 
          panes={[
            { 
              menuItem: (
                <Menu.Item>
                  <Icon name='sort numeric down'/>
                  {t('menus.wood_menus.post_baking_waste_wood_count')}
                  <Label>{countTotal}</Label>
                </Menu.Item>
              ),
              render: () => (
                <CustomTable
                  ref={countRef}
                  columns={countColumns}
                  fetchUrl={URL_POST_BAKING.POST_BAKING_WASTE_WOOD_COUNT}
                  manual
                  showPagination
                  // showSize
                  extraFilterParams={{ 
                    date_before: moment(formik.values.date).format('YYYY-MM-DD'),
                    date_after: moment(formik.values.date).format('YYYY-MM-DD'),
                    order_type: WASTE_WOOD_ORDER_TYPE.SHAPING_TYPE,
                  }}/>
              )
            },
            {
              menuItem: (
                <Menu.Item>
                  <Icon name='sign-in alternate'/>
                  {t('menus.wood_menus.post_baking_waste_wood_input')}
                  <Label>{inputTotal}</Label>
                </Menu.Item>
              ),
              render: () => (
                <CustomTable
                  ref={inputRef}
                  columns={inputColumns}
                  fetchUrl={URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}
                  manual
                  showPagination
                  // showSize
                  extraFilterParams={{ 
                    date_before: moment(formik.values.date).format('YYYY-MM-DD'),
                    date_after: moment(formik.values.date).format('YYYY-MM-DD'),
                    order_type: WASTE_WOOD_ORDER_TYPE.SHAPING_TYPE,
                  }}/>
              )
            }
          ]}
          onTabChange={(__, { activeIndex }) => setAciveIndex(activeIndex)}/>

			</Container>
      <InterfaceAXHistoryModal
        open={openHistory}
        onClose={() => setOpenHistory(false)}
        type={INTERFACE_TYPE.WASTE_WOOD_ORDER}/>
    </div>
  )
}