import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Header, Icon, Input, Modal } from "semantic-ui-react";
import { useDebounce } from "use-debounce";
import ErrorMessage from "../common/ErrorMessage";
import { POST } from "../../utils/HttpClient";
import * as _ from "lodash";
import { QrReader } from 'react-qr-reader';


const DEFAULT_ON_SUBMIT_LENGTH = 14;

const BarcodeInput = React.forwardRef((props, ref) => {
  const {
    submitUrl,
    onNext,
  } = props;

  const { t } = useTranslation(); 
  const inputRef = React.useRef();
  const [barcodeInput, setBarcodeInput] = React.useState('')
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [barcodeSearch] = useDebounce(barcodeInput, 500);
  const [scanModal, setScanModal] = React.useState(false);

  const handleSubmit = async (barcode) => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await POST(submitUrl, { barcode });
      onNext(_.get(response, 'data.id', ''));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  const handleOpenQRCode = () => {
    if (window.JSInterface) {
      // if open in ww factory then use interface
      window.JSInterface.scanBarcode();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    } else {
      // if https then go normal library scan qr
      setScanModal(true);
    }
  }

  React.useEffect(() => {
    if (barcodeSearch.length == DEFAULT_ON_SUBMIT_LENGTH) {
      handleSubmit(barcodeSearch);
    }
  }, [barcodeSearch])

  return (
    <div style={{ marginTop: '7rem' }}>
      <ErrorMessage message={errorMessages}/>
      <Header as='h4'>{t('barcodes.barcode_input')}</Header>
      <Input ref={inputRef} loading={loading} disabled={loading} value={barcodeInput} onChange={(__, data) => setBarcodeInput(data.value)}/>

      <div style={{ marginTop: '3rem' }}>
        <Button primary icon='qrcode' size='big' content={t('scan')} onClick={handleOpenQRCode} />  
      </div>

      <Modal open={scanModal} onClose={() => setScanModal(false)} closeIcon>
        <Modal.Header><Icon name='qrcode'/> {t('barcodes.barcode_input')}</Modal.Header>
        <Modal.Content>
          <QrReader
            constraints={{ facingMode: "environment" }}
            onResult={(result, e) => {
              if (result) {
                setBarcodeInput(_.get(result, 'text', ''));
                setScanModal(false);
              }
            }}
            style={{ width: '100%' }}/>
        </Modal.Content>
      </Modal>
    </div>
  )
})

export default BarcodeInput;

BarcodeInput.propTypes = {
  submitUrl: PropTypes.string,
  onNext: PropTypes.func,
}

BarcodeInput.defaultProps = {
  submitUrl: '',
  onNext: () => null,
}