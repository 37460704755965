import React from 'react';
import { useTranslation } from 'react-i18next';
import { defaultTruckCompanyDetail, defaultWoodImportTransactionDetail, defaultWoodSizeGradeMapDetail, WOOD_IMPORT_GRADE_DISPLAY } from '../../constance/dataTypes';
import * as Yup from 'yup';
import propTypes from 'prop-types';
import { Button, Form, Search, Segment, Table, Message, Tab, Input, Divider, Grid, Label, Dropdown, Item } from 'semantic-ui-react';
import useSearchItem from '../../hooks/useSeatchItem';
import useDropdownItem from '../../hooks/useDropdownItem';
import { URL_BARCODE, URL_SCALE_LARGE } from '../../constance/urls';
import { useFormik } from 'formik';
import { useDebounce } from 'use-debounce';
import useItemDetail from '../../hooks/useItemDetail';
import { formatComma } from '../../utils/stringFormat';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';
import * as _ from 'lodash';
import SelectTruckTransactionModal from '../truck-transaction/SelectTruckTransactionModal';
import CreateTruckTransactionModal from '../truck-transaction/CreateTruckTransactionModal';

const NumberInput = ({readOnly, fieldName, formik, woodSizeGradeMap, onChange, index}) => {
  return (
    <Form.Input
      fluid
      type='number'
      min='0'
      readOnly={readOnly}
      value={woodSizeGradeMap[fieldName]}
      error={_.get(formik.errors, `wood_size_grade_map_list.${index}.${fieldName}`, null)}
      onChange={(e) => onChange(index, fieldName, e.target.value, formik)}
      onBlur={(e) => {
        if (!e.target.value) {
          onChange(index, fieldName, 0, formik)
        }
      }}
    />
  )
}

const WoodImportDetail = React.forwardRef((props, ref) => {

  const {
    detail,
    enableEdit,
    setDetailLoading
  } = props;

  const { t } = useTranslation();

  const [truckCompanySearchTxt, setTruckCompanySearchTxt] = React.useState("")
  const [debouncedText] = useDebounce(truckCompanySearchTxt, 500);

  const sawWoodTypes = useDropdownItem({url: URL_BARCODE.SAW_WOOD_TYPE});
  const truckTypes = useDropdownItem({ url: URL_SCALE_LARGE.TRUCK_TYPE, text: "name" });
  const truckCompanies = useSearchItem({ url: URL_SCALE_LARGE.TRUCK_COMPANY, description_render: 'name', title_render: 'code' });

  const woodImportTransactionDetail = useItemDetail({
    url: URL_BARCODE.WOOD_IMPORT_TRANSACTION,
    id: detail ? detail.id : null,
    manual: true,
    returnData: true,
  })

  const woodImportTransactionValidationSchema = Yup.object().shape({
    order_no: Yup.string().required(t('error_messages.validation.required')),
    truck_transaction_list: Yup.array().of(
      Yup.object().shape({
        truck_company: Yup.string().required(t('error_messages.validation.required')),
        truck_type: Yup.mixed().required(t('error_messages.validation.key_not_found')),
        truck_license_plate: Yup.string().required(t('error_messages.validation.required')),
        in_weight: Yup.string().nullable(),
      })
    ),
    wood_size_grade_map_list: Yup.array().of(
      Yup.object().shape({
        wood_code: Yup.string()
          .required(t('error_messages.validation.required'))
          .length(12, `${t('error_messages.validation.length')} (12 ${t('units.character')})`),
        barcode_ref: Yup.string().required(t('error_messages.validation.required')).nullable(),
      })
    ),
    produce_date: Yup.date().required(t('error_messages.validation.required')),
  })

  const woodImportFormik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: woodImportTransactionValidationSchema,
    initialValues: detail || defaultWoodImportTransactionDetail,
  })

  const [isSelectTruckTransactionOpen, setIsSelectTruckTransactionOpen] = React.useState(false)
  const [isCreateTruckTransactionOpen, setIsCreateTruckTransactionOpen] = React.useState(false)
  const [defaultSawWoodType, setDefaultSawWoodType] = React.useState(null)

  const validateForm = async () => {
    const formikValidation = await woodImportFormik.validateForm();
    const isWoodImportTransacitonFormikValid = Object.keys(formikValidation).length === 0;
    return [isWoodImportTransacitonFormikValid, formikValidation];
  }

  const updateWoodSizeGradeMapField = (index, field, data, formik=woodImportFormik) => {
    let newList = formik.values.wood_size_grade_map_list;
    newList[index][field] = data;
    formik.setFieldValue('wood_size_grade_map_list', [...newList])
  }

  const updateWoodImportFormik = (index, field, data) => {
    let truck_transaction_list = woodImportFormik.values.truck_transaction_list.slice()
    truck_transaction_list[index][field] = data
    woodImportFormik.setFieldValue('truck_transaction_list', truck_transaction_list)
  }

  const addNewGradeMap = () => {
    woodImportFormik.setFieldValue('wood_size_grade_map_list', [
      ...woodImportFormik.values.wood_size_grade_map_list, 
      {...defaultWoodSizeGradeMapDetail, saw_wood_type: defaultSawWoodType}
    ])
  }

  const removeGradeMap = (index) => {
    let newList = woodImportFormik.values.wood_size_grade_map_list;
    newList.splice(index, 1)
    woodImportFormik.setFieldValue('wood_size_grade_map_list', [...newList])
  }

  const removeTruckTransaction = (index) => {
    woodImportFormik.setFieldValue(
      'truck_transaction_list',
      woodImportFormik.values.truck_transaction_list.filter((__, idx) => idx != index)
    )
  }

  const addTruckTransactionList = (params) => {
    const inputData = {
      truck_company: params.truck_company,
      truck_company_name: params.truck_company_name,
      truck_type: params.truck_type,
      truck_type_name: params.truck_type_name,
      truck_license_plate: params.license_plate,
      in_weight: params.net_weight,
    }

    const target = woodImportFormik.values.truck_transaction_list.findIndex(
      tt => tt.exported_truck_license_plate && tt.exported_truck_license_plate == params.license_plate
    )
    if (target != -1) {
      let newList = woodImportFormik.values.truck_transaction_list.map((tt, index) => index == target ? ({...tt, ...inputData}) : tt)
      woodImportFormik.setFieldValue('truck_transaction_list', [...newList])
    } else {
      woodImportFormik.setFieldValue('truck_transaction_list', [...woodImportFormik.values.truck_transaction_list, inputData])
    }
  }

  React.useImperativeHandle(ref, () => ({
    handleSaveDetail: async () => {
      let isCreate = detail.id === null
      let returnData = defaultWoodImportTransactionDetail;
      let isSuccess = false;

      const [isFormValid, data] = await validateForm();
      if( !isFormValid ) {
        return [false, false, data];
      }

      if (isCreate) {
        let [createResponse, isCreateSuccess] = await woodImportTransactionDetail.handleCreate(woodImportFormik.values);
        returnData = { ...returnData, ...createResponse};
        isSuccess = isCreateSuccess;
      } else {
        let [saveResponse, isSaveSuccess] = await woodImportTransactionDetail.handleSave(woodImportFormik.values);
        returnData = { ...returnData, ...saveResponse};
        isSuccess = isSaveSuccess;
      }

      return [isCreate, isSuccess, returnData];
    }
  }))

  React.useEffect(() => {
    truckCompanies.fetchData()
  }, [])

  React.useEffect(() => {
    if (debouncedText) {
      truckCompanies.fetchData({ search: debouncedText })
    }
  }, [debouncedText])

  React.useEffect(() => {
    const targetSawWoodType = sawWoodTypes.response.find(e => e.code == '11')
    setDefaultSawWoodType(_.get(targetSawWoodType, 'id', null))
    if (!detail.id) {
      woodImportFormik.setFieldValue('wood_size_grade_map_list', [
        {...defaultWoodSizeGradeMapDetail, saw_wood_type: _.get(targetSawWoodType, 'id', null)}
      ])
    }
  }, [sawWoodTypes.response])

  return (
    <Segment>
      { woodImportTransactionDetail.errorMessages &&
        <Message error>
          <Message.Header>{t('error')}!</Message.Header>
          <label>{ woodImportTransactionDetail.errorMessages }</label>
        </Message>
      }
      <Form>
        <Form.Group>
          <Form.Input
            width={4}
            fluid
            readOnly={!enableEdit}
            name='order_no'
            label={t('wood_imports.order_no')}
            value={woodImportFormik.values.order_no}
            error={woodImportFormik.errors.order_no}
            onChange={woodImportFormik.handleChange}
          />
          <SemanticDatepicker
            width={4}
            label={t('wood_imports.produce_date')}
            locale='en-US'
            format='DD/MM/YYYY'
            readOnly={!enableEdit}
            clearable={false}
            showToday={true}
            placeholder={t('searchs.placeholders.date')}
            value={new Date(woodImportFormik.values.produce_date)}
            onChange={(_, data) => woodImportFormik.setFieldValue('produce_date', moment(data.value).format())}
            />
          {enableEdit &&
            <Form.Field>
              <label style={{ color: 'white' }}>-</label>
              <Form.Button
                icon='clipboard list'
                color='blue'
                basic
                content={t('wood_imports.map_from_truck_transaction')}
                onClick={() => setIsSelectTruckTransactionOpen(true)} />
            </Form.Field>
          }
          {enableEdit &&
            <Form.Field>
              <label style={{ color: 'white' }}>-</label>
              <Form.Button
                icon='add'
                color='green'
                basic
                content={t('wood_imports.map_from_manual')}
                onClick={() => setIsCreateTruckTransactionOpen(true)} />
            </Form.Field>
          }
        </Form.Group>
        {woodImportFormik.values.truck_transaction_list.map((tt, idx) => (
          <Form.Group>
            <Form.Field
              fluid
              label={t('truck_transactions.company')}
              placeholder={`${t('search')} ${t('searchs.fields.code')} ${t('or')} ${t('searchs.fields.name')}...`}
              minCharacters={2}
              control={Search}
              results={truckCompanies.response}
              showNoResults={enableEdit}
              onSearchChange = {(_, data) => {
                if (!enableEdit) {
                  return
                }
                if (tt.truck_company) {
                  updateWoodImportFormik(idx, 'truck_company', null)
                }
                setTruckCompanySearchTxt(data.value)
                updateWoodImportFormik(idx, 'truck_company_name', data.value)
              }}
              onResultSelect={(__, data) => {
                if (!enableEdit) {
                  return
                }
                updateWoodImportFormik(idx, 'truck_company_name', _.get(data, 'result.name', ''))
                updateWoodImportFormik(idx, 'truck_company', data.result.code)
              }}
              onBlur={() => {
                if (!tt.truck_company) {
                  updateWoodImportFormik(idx, 'truck_company_name', '')
                }
              }}
              value={tt.truck_company ? `[${tt.truck_company}] - ${tt.truck_company_name}` : tt.truck_company_name}
              error={_.get(woodImportFormik.errors, `truck_transaction_list.${idx}.truck_company`)}
              loading={truckCompanies.isLoading}
            />
            <Form.Input
              width={2}
              label={t('wood_imports.truck_license_plate')}
              type='text'
              readOnly={!enableEdit}
              value={tt.truck_license_plate}
              error={_.get(woodImportFormik.errors, `truck_transaction_list.${idx}.truck_license_plate`)}
              onChange={(e) => updateWoodImportFormik(idx, 'truck_license_plate', e.target.value)}
            />
            <Form.Dropdown
              search
              selection
              label={t('trucks.type')}
              options={truckTypes.response}
              readOnly={!enableEdit}
              value={tt.truck_type}
              error={_.get(woodImportFormik.errors, `truck_transaction_list.${idx}.truck_type`)}
              onChange={(_, data) => updateWoodImportFormik(idx, 'truck_type', data.value)}
            />
            <Form.Input
              width={3}
              label={t('wood_imports.in_weight')}
              labelPosition='right'
              thousandseparator={','}
              type='number'
              readOnly={!enableEdit}
              value={Math.abs(tt.in_weight)}
              error={_.get(woodImportFormik.errors, `truck_transaction_list.${idx}.in_weight`)}
              onChange={(e) => updateWoodImportFormik(idx, 'in_weight', e.target.value)}
            >
              <input />
              <Label basic>{t('units.kg')}</Label>
            </Form.Input>
            {enableEdit &&
              <Form.Field>
                <label>Action</label>
                <Button
                  width={2}
                  basic
                  negative
                  icon='cancel'
                  onClick={() => removeTruckTransaction(idx)}
                />
              </Form.Field>
            }
            {/* issued it as input as intended */}
            <Form.Field width={2}>
              <label>{t('wood_imports.truck_license_plate_out')}</label>
              <Input value={tt.exported_truck_license_plate ? tt.exported_truck_license_plate : '-'} readOnly/>
            </Form.Field>
            <Form.Field width={3}>
              <label>{t('wood_imports.exported_net_weight')}</label>
              <Input value={`${tt.exported_net_weight !== 0 ? formatComma(Math.abs(tt.exported_net_weight)) : "-"} ${t('units.kg')}`} readOnly/>
            </Form.Field>
          </Form.Group>
        ))}

        <label style={{fontWeight: 'bold'}}>{t('wood_imports.wood_size_grade_map_list')}</label>

        { woodImportFormik.values.wood_size_grade_map_list.map((woodSizeGradeMap, index) => (
          <Segment color='blue'>
            { enableEdit && 
              <div style={{ textAlign: 'right' }}>
                <Button
                  basic
                  negative
                  icon='cancel'
                  size='tiny'
                  onClick={() => removeGradeMap(index)}/>
              </div>
            }
            <Form.Group widths='3'>
              <Form.Input
                fluid
                label={t('wood_imports.wood_code')}
                type='text'
                readOnly={!enableEdit}
                value={woodSizeGradeMap.wood_code}
                error={_.get(woodImportFormik.errors, `wood_size_grade_map_list.${index}.wood_code`)}
                onChange={(e) => updateWoodSizeGradeMapField(index, 'wood_code', e.target.value.substr(0, 12))}/>
              <Form.Dropdown
                search
                selection
                clearable
                label={t('wood_imports.saw_wood_type')}
                options={sawWoodTypes.response}
                value={woodSizeGradeMap.saw_wood_type}
                error={_.get(woodImportFormik.errors, `wood_size_grade_map_list.${index}.saw_wood_type`)}
                onChange={(_, data) => {
                  if (!enableEdit) return;
                  updateWoodSizeGradeMapField(index, 'saw_wood_type', data.value)
                }}/>
              <Form.Input
                fluid
                label={t('wood_imports.barcode_ref')}
                type='text'
                readOnly={!enableEdit}
                value={woodSizeGradeMap.barcode_ref}
                error={_.get(woodImportFormik.errors, `wood_size_grade_map_list.${index}.barcode_ref`)}
                onChange={(e) => updateWoodSizeGradeMapField(index, 'barcode_ref', e.target.value)}/>
            </Form.Group>
            <Form.Group widths='7'>
              { WOOD_IMPORT_GRADE_DISPLAY.map(grade => (
                <Form.Field>
                  <label>{t(`wood_imports.grades.${grade}`)}</label>
                  <NumberInput
                    index={index}
                    readOnly={!enableEdit}
                    fieldName={grade}
                    woodSizeGradeMap={woodSizeGradeMap}
                    formik={woodImportFormik}
                    onChange={updateWoodSizeGradeMapField}/>
                </Form.Field>
              ))}
              <Form.Field>
                <label>{t('wood_imports.grades.waste')}</label>
                <NumberInput
                  index={index}
                  readOnly={!enableEdit}
                  fieldName={'waste'}
                  woodSizeGradeMap={woodSizeGradeMap}
                  formik={woodImportFormik}
                  onChange={updateWoodSizeGradeMapField}/>
              </Form.Field>
              <Form.Field>
                <label>{t('wood_imports.grades.over')}</label>
                <NumberInput
                  index={index}
                  readOnly={!enableEdit}
                  fieldName={'over'}
                  woodSizeGradeMap={woodSizeGradeMap}
                  formik={woodImportFormik}
                  onChange={updateWoodSizeGradeMapField}/>
              </Form.Field>
              <Form.Field>
                <label>{t('datas.total')}</label>
                <input 
                  value={
                    formatComma(
                      Object.keys(woodSizeGradeMap).reduce((prev, cur) => 
                        WOOD_IMPORT_GRADE_DISPLAY.includes(cur) ? prev + Number(woodSizeGradeMap[cur]) : prev, Number(0)
                      )) + '  ' + t('units.wood')
                  }
                  style={{ border: 0 }}/>
              </Form.Field>
            </Form.Group>
          </Segment>
        )) }
        { enableEdit &&
          <Grid columns='4' textAlign='center'>
            <Grid.Column>
              <Button
                basic
                fluid
                positive
                icon='add'
                onClick={addNewGradeMap}/>
            </Grid.Column>
          </Grid>
        }
      </Form>
      <SelectTruckTransactionModal
        open={isSelectTruckTransactionOpen}
        onClose={() => setIsSelectTruckTransactionOpen(false)}
        onSelect={(tt) => addTruckTransactionList(tt)}
      />
      <CreateTruckTransactionModal
        open={isCreateTruckTransactionOpen}
        onClose={() => setIsCreateTruckTransactionOpen(false)}
        onFinish={(tt) => addTruckTransactionList(tt)}/>

    </Segment>
  )

});

export default WoodImportDetail;


WoodImportDetail.defaultProps = {
  detail: defaultWoodImportTransactionDetail,
  enableEdit: true,
  setDetailLoading: () => null,
};

WoodImportDetail.propTypes = {
  detail: propTypes.object,
  enableEdit: propTypes.bool,
  setDetailLoading: propTypes.func,
};
