import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Form, Icon, Popup } from 'semantic-ui-react';
import { getBOMTranscationStatus } from '../../constance/dataTypes';
import { URL_BOM } from '../../constance/urls';
import { PATH } from '../../routes/Routes';
import { ellipsisString } from '../../utils/stringFormat';
import CustomTable from '../common/CustomTable';


export default function BOMList(props) {

  const history = useHistory();
  const { t } = useTranslation();
  const [date, setDate] = React.useState('');
  const [statusFilter, setStatusFilter] = React.useState('ALL')
  const tableRef = React.useRef();

  const columns = React.useMemo(() => [
    {
      Header: t('date'),
      accessor: 'date',
      Cell: ({ row }) => new Date(row.original.date).toLocaleString('en-GB')
    },
    {
      Header: t('bom_transactions.order_no'),
      accessor: 'order_no',
    },
    {
      Header: t('bom_transactions.description'),
      accessor: 'description',
      Cell: ({row}) => <Popup trigger={<div>{ellipsisString(row.original.description)}</div>} inverted content={row.original.description}/>
    },
    {
      Header: t('status'),
      Cell: ({row}) => {
        return t(`bom_transactions.statuses.${getBOMTranscationStatus(row.original)}`)
      }
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({ row }) => (
        <div style={{textAlign: 'center'}}>
          <Button
            basic
            primary
            content={t('detail')}
            onClick={() => history.push(`${PATH.BOM}${row.original.id}`)} />
        </div>
      )
    }
  ], []);

  const dateFilterComponent = (
    <Form.Field
      label={t('searchs.fields.date')}
      locale='en-US'
      format='DD/MM/YYYY'
      showToday={true}
      placeholder={t('searchs.placeholders.date')}
      control={SemanticDatepicker}
      onChange={(_, data) => {
        if (data.value) {
          setDate(moment(data.value).format('YYYY-MM-DD'))
        } else {
          setDate('')
        }
      }}
    />
  )

  const statusFilterComponent = (
    <Form.Select
      label={t('status')}
      options={[
        { key: 'ALL', value: 'ALL', text: t('datas.total') },
        { key: 'DRAFT', value: 'DRAFT', text: t('bom_transactions.statuses.draft') },
        { key: 'COMPLETED', value: 'COMPLETED', text: t('bom_transactions.statuses.completed') },
        { key: 'INTERFACED', value: 'INTERFACED', text: t('bom_transactions.statuses.interfaced') },
      ]}
      defaultValue={statusFilter}
      onChange={(_, data) => setStatusFilter(data.value)}
    />
  )

  return (
    <div>
      <CustomTable
        ref={tableRef}
        fetchUrl={URL_BOM.BOM_TRANSACTION}
        columns={columns}
        showSearch
        showSize
        showPagination
        extraFilter={[dateFilterComponent, statusFilterComponent]}
        extraFilterParams={{ date: date, status: statusFilter }}/>
    </div>
  )
}