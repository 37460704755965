import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Container, Dimmer, Divider, Form, Table, Header, Loader, Message, Segment } from 'semantic-ui-react';
import SubHeader from '../components/common/SubHeader';
import { defaultBOMTransactionModel, getBOMTranscationStatus } from '../constance/dataTypes';
import { URL_BOM } from '../constance/urls';
import { DELETE, GET, POST, PUT } from '../utils/HttpClient';
import * as Yup from 'yup';
import moment from 'moment';
import BOMModal from '../components/bom/BOMModal';
import { PATH } from '../routes/Routes';
import * as _ from 'lodash';
import ConfirmModal from '../components/common/ConfirmModal';
import { Toast } from '../utils/Toast';
import { formatComma } from '../utils/stringFormat';
import { calculateWoodSizeVolume } from '../utils/woodSize';


export default function BOMDetailView() {

  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const initialRef = React.useRef(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [addModalOpen, setAddModalOpen] = React.useState(false);
  const [isDeleteConFirmModalOpen, setIsDeleteConFirmModalOpen] = React.useState(false);
  const [isInterfaceConFirmModalOpen, setIsInterfaceConFirmModalOpen] = React.useState(false);
  const [deleteBOMTarget, setDeleteBOMTarget] = React.useState(null);

  const formik = useFormik({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: defaultBOMTransactionModel,
    validationSchema: Yup.object().shape({
      order_no: Yup.string().required(t('error_messages.validation.required')),
      date: Yup.string().required(t('error_messages.validation.required')),
      bom_list: Yup.array().of(Yup.object().shape({
        new_wood_size: Yup.string().required(t('error_messages.validation.required')),
        new_amount: Yup.number().moreThan(0, `${t('error_messages.validation.more_than')} 0`),
      }))
    }),
    onSubmit: async (values) => {
      setErrorMessages(null);
      setIsLoading(true);
      try {
        if (isCreate) {
          await createData(values);
        } else {
          await saveData(values);
          fetchData();
        }
        Toast.success(t('success'))
      } catch (error) {
        setErrorMessages(error.errorMessages);
      } finally {
        setIsLoading(false);
      }
    }
  })

  const createData = async (values) => {
    const response = await POST(URL_BOM.BOM_TRANSACTION, values)
    history.replace(`${PATH.BOM}${_.get(response.data, 'id')}`)
  }

  const saveData = async (values) => {
    await PUT(`${URL_BOM.BOM_TRANSACTION}${params.id}/`, values)
    await POST(`${URL_BOM.BOM}${URL_BOM.MULTIPLE_UPDATE}`, {'bom_list': values.bom_list})
  }

  const fetchData = async () => {
    formik.resetForm();
    setErrorMessages(null);
    setIsLoading(true);
    try {
      const bomTransactionResponse = await GET(`${URL_BOM.BOM_TRANSACTION}${params.id}/`)
      formik.setValues({...defaultBOMTransactionModel, ...bomTransactionResponse.data})
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const deleteData = async () => {
    setErrorMessages(null);
    setIsLoading(true);
    try {
      await DELETE(`${URL_BOM.BOM_TRANSACTION}${params.id}`)
      Toast.success(`${t('delete')} ${t('success')}`);
      history.replace(PATH.BOM)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const handleDoInterfaceAX = async () => {
    const formValidation = await formik.validateForm();
    if (Object.keys(formValidation).length) { 
      return null 
    };
    setErrorMessages(null);
    setIsLoading(true);
    try {
      await saveData(formik.values);
      await POST(`${URL_BOM.BOM_TRANSACTION}${params.id}/interface/`)
      history.replace(PATH.BOM)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const handleDeleteBOM = async () => {
    setErrorMessages(null);
    setIsLoading(true);
    const deleteTarget = deleteBOMTarget;
    setDeleteBOMTarget(null);
    try {
      await DELETE(`${URL_BOM.BOM}${deleteTarget}/`)
      Toast.success(`${t('delete')} ${t('success')}`);
      fetchData();
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const calculatedSummary = () => {
    const results = [];
    _.get(formik.values, 'bom_list', []).forEach(bom => {
      if (!_.get(bom, 'new_wood_size', null) || !_.get(bom, 'new_amount', null)) {
        return;
      }
      // check if there already new wood size in results
      let target = results.find(result => 
        _.get(result, 'new_wood_size') == _.get(bom, 'new_wood_size', null) && 
        _.get(result, 'pallet_quantity') == _.get(bom, 'new_amount')
      )
      if (target) {
        const newPallet = target.pallet + 1
        target.pallet = newPallet
        target.total_wood = target.pallet_quantity * newPallet
        target.total_volume = calculateWoodSizeVolume(target.new_wood_size) * target.pallet_quantity * newPallet
      } else {
        results.push({
          new_wood_size: bom.new_wood_size,
          pallet: 1,
          pallet_quantity: bom.new_amount,
          total_wood: bom.new_amount,
          total_volume: calculateWoodSizeVolume(bom.new_wood_size) * bom.new_amount
        })
      }
    });

    return results
  }

  React.useEffect(() => {
    if (params.id != 'add') {
      fetchData();
    }
  }, [params.id])

  React.useLayoutEffect(() => {
    if (initialRef.current) {
      initialRef.current = false
      return;
    }
    if (!addModalOpen) {
      fetchData();
    }
  }, [addModalOpen])

  const isCreate = params.id === 'add'; 
  const editable = !formik.values.is_completed && !formik.values.is_interfaced;

  return (
    <div>
      <Dimmer active={isLoading}>
        <Loader>{t('loading')}</Loader>
      </Dimmer>
      <SubHeader/>
      <Container style={{ marginTop: '7em' }} textAlign='left'>
        { errorMessages && 
          <Message error>
            <Message.Header>{t('error')}</Message.Header>
            {errorMessages}
          </Message>
        }

        { !isCreate && editable &&
          <Button
            basic
            icon='trash'
            color='red'
            size='tiny'
            content={t('delete')}
            floated='right'
            onClick={() => setIsDeleteConFirmModalOpen(true)}/>
        }
        <Header as='h2'>{params.id === 'add' ? t('bom_transactions.add') : t('bom_transactions.detail')}</Header>
        <Divider/>

        <Segment>
          <Form>
            <Form.Group widths='equal'>
              <Form.Input
                name='order_no'
                label={t('bom_transactions.order_no')}
                readOnly={!editable}
                value={formik.values.order_no}
                error={formik.errors.order_no}
                onChange={formik.handleChange}/>
              <SemanticDatepicker
                label={t('bom_transactions.date')}
                locale='en-US'
                format='DD/MM/YYYY'
                readOnly={!editable}
                showToday={true}
                clearable={false}
                placeholder={t('searchs.placeholders.date')}
                value={new Date(formik.values.date)}
                error={formik.errors.date}
                onChange={(_, data) => formik.setFieldValue('date', moment(data.value).format())}/>
              <Form.Input
                label={t('bom_transactions.status')}
                value={t(`bom_transactions.statuses.${getBOMTranscationStatus(formik.values)}`)}/>
            </Form.Group>
            <Form.TextArea
              name='description'
              label={t('bom_transactions.description')}
              readOnly={!editable}
              value={formik.values.description}
              error={formik.errors.description}
              onChange={formik.handleChange}/>

            { editable && 
              <div style={{ textAlign: 'right' }}>
                { !isCreate && _.get(formik.values, 'bom_list', []).length > 0 && (
                  <Button
                    basic
                    color='blue'
                    icon='external alternate'
                    content={t('menus.interface_ax')}
                    onClick={() => setIsInterfaceConFirmModalOpen(true)}/>
                )}
                <Button
                  color={isCreate ? 'green' : 'blue'}
                  icon={isCreate ? 'add' : 'save'}
                  content={isCreate ? t('add') : t('save')}
                  onClick={formik.handleSubmit}/>              
              </div>
            }

            { !isCreate && 
              <div>
                <Divider/>
                { editable && (
                  <Button
                    size='mini'
                    floated='right'
                    icon='add'
                    positive
                    content={t('add')}
                    onClick={() => setAddModalOpen(true)}/>
                )}
                <Header textAlign='center'>
                  {t('bom_transactions.bom')}
                </Header>
                <Table singleLine striped>
                  <Table.Header>
                    <Table.HeaderCell width={3}>{t('bom_transactions.boms.original_wood_size')}</Table.HeaderCell>
                    <Table.HeaderCell width={3}>{t('bom_transactions.boms.new_wood_size')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('bom_transactions.boms.original_amount')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('bom_transactions.boms.new_amount')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('bom_transactions.boms.original_volume')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('bom_transactions.boms.new_volume')}</Table.HeaderCell>
                    <Table.HeaderCell width={2}>{t('barcodes.barcode_id')}</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    { _.get(formik.values, 'bom_list', []).map((bom, idx) => (
                      <Table.Row>
                        <Table.Cell>{bom.original_wood_size}</Table.Cell>
                        <Table.Cell>
                          <Form.Input
                            fluid
                            name='new_wood_size'
                            maxLength='12'
                            placeholder={t('bom_transactions.boms.new_wood_size')}
                            readOnly={!editable}
                            value={bom.new_wood_size}
                            error={_.get(formik.errors, `bom_list.${idx}.new_wood_size`, null)}
                            onChange={(_, data) => {
                              formik.setFieldValue(`bom_list.${idx}.new_wood_size`, data.value)
                            }}/>
                        </Table.Cell>
                        <Table.Cell>{bom.original_amount}</Table.Cell>
                        <Table.Cell>
                          <Form.Input
                            fluid
                            type='number'
                            min='0'
                            name='new_wood_size'
                            placeholder={t('bom_transactions.boms.new_amount')}
                            readOnly={!editable}
                            value={bom.new_amount}
                            error={_.get(formik.errors, `bom_list.${idx}.new_amount`, null)}
                            onChange={(_, data) => {
                              formik.setFieldValue(`bom_list.${idx}.new_amount`, data.value)
                            }}
                            onBlur={(e) => {
                              if (!e.target.value) {
                                formik.setFieldValue(`bom_list.${idx}.new_amount`, 0)
                              }
                            }}/>
                        </Table.Cell>
                        <Table.Cell>{formatComma(bom.original_volume, 4)}</Table.Cell>
                        <Table.Cell>{formatComma(calculateWoodSizeVolume(bom.new_wood_size) * bom.new_amount, 4)}</Table.Cell>
                        <Table.Cell>{bom.barcode_no}</Table.Cell>
                        <Table.Cell>
                          { editable && (
                            <Button
                              basic
                              color='red'
                              size='mini'
                              icon='trash'
                              onClick={() => setDeleteBOMTarget(bom.id)}/>
                          )}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </div>
            }
          </Form>
        </Segment>
        <Segment>
          <Header>สรุป</Header>
          <Table>
            <Table.Header>
              <Table.HeaderCell width={5}>{t('bom_transactions.summary.wood_size')}</Table.HeaderCell>
              <Table.HeaderCell>{t('bom_transactions.summary.pallet')}</Table.HeaderCell>
              <Table.HeaderCell>{t('bom_transactions.summary.pallet_quantity')}</Table.HeaderCell>
              <Table.HeaderCell>{t('bom_transactions.summary.total_wood')}</Table.HeaderCell>
              <Table.HeaderCell>{t('bom_transactions.summary.total_volume')}</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              { calculatedSummary().map(summary => (
                <Table.Row>
                  <Table.Cell>{summary.new_wood_size}</Table.Cell>
                  <Table.Cell>{formatComma(summary.pallet)}</Table.Cell>
                  <Table.Cell>{formatComma(summary.pallet_quantity)}</Table.Cell>
                  <Table.Cell>{formatComma(summary.total_wood)}</Table.Cell>
                  <Table.Cell>{formatComma(summary.total_volume, 4)}</Table.Cell>
                </Table.Row>
              )) }
            </Table.Body>
          </Table>
        </Segment>
      </Container>

      <BOMModal
        open={addModalOpen}
        transaction={formik.values.id}
        onClose={() => setAddModalOpen(false)}/>

      <ConfirmModal
        open={isInterfaceConFirmModalOpen}
        onClose={() => setIsInterfaceConFirmModalOpen(false)}
        title={t('confirms.interface_title')}
        content={t('confirms.interface_content')}
        onConfirm={() => handleDoInterfaceAX()}
      />

      <ConfirmModal
        open={isDeleteConFirmModalOpen}
        onClose={() => setIsDeleteConFirmModalOpen(false)}
        title={t('confirms.delete_title')}
        content={t('confirms.delete_content')}
        onConfirm={() => {
          setIsDeleteConFirmModalOpen(false);
          deleteData();
        }}
      />

      <ConfirmModal
        open={deleteBOMTarget}
        onClose={() => setDeleteBOMTarget(false)}
        title={t('confirms.delete_title')}
        content={t('confirms.delete_content')}
        onConfirm={() => handleDeleteBOM()}
      />
    </div>
  )
}