import React from 'react';
import { Form, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import CustomTable from '../common/CustomTable';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import moment from 'moment';
import { URL_WORKFLOW } from '../../constance/urls';
import { useHistory } from 'react-router-dom';
import { PATH } from '../../routes/Routes';


export default function WoodImportList() {

  const history = useHistory();
  
  const { t } = useTranslation();
  const [date, setDate] = React.useState('');
  const [typeFilter, setTypeFilter] = React.useState('ALL')
  const tableRef = React.useRef();

  const columns = React.useMemo(() => [
    {
      Header: t('date'),
      accessor: 'wood_import.created',
      Cell: ({ row }) => new Date(row.original.wood_import.produce_date).toLocaleString('en-GB')
    },
    {
      Header: t('wood_imports.order_no'),
      accessor: 'wood_import.order_no',
    },
    {
      Header: t('truck_transactions.company'),
      accessor: 'wood_import.truck_company_name',
    },
    {
      Header: t('trucks.type'),
      accessor: 'wood_import.truck_type_name',
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({ row }) => (
        <div style={{textAlign: 'center'}}>
          <Button
            basic
            primary
            content={t('detail')}
            onClick={() => {
              history.push(`${PATH.WOOD_IMPORT}${row.original.id}`)
            }}
          />
        </div>
      )
    }
  ], []);

  const dateFilterComponent = (
    <Form.Field
      label={t('searchs.fields.date')}
      locale='en-US'
      format='DD/MM/YY'
      showToday={true}
      placeholder={t('searchs.placeholders.date')}
      control={SemanticDatepicker}
      onChange={(_, data) => {
        if (data.value) {
          setDate(moment(data.value).format('YYYY-MM-DD'))
        } else {
          setDate('')
        }
      }}
    />
  )

  const typeFilterComponent = (
    <Form.Select
      label={t('wood_imports.vat')}
      options={[
        { key: 'ALL', value: 'ALL', text: t('datas.total') },
        { key: 'FACTORY', value: 'FACTORY', text: t('wood_imports.types.factory') },
        { key: 'CUSTOMER', value: 'CUSTOMER', text: t('wood_imports.types.customer') },
      ]}
      defaultValue={typeFilter}
      onChange={(_, data) => setTypeFilter(data.value)}
    />
  )

  return (
    <div>
      <CustomTable
        ref={tableRef}
        fetchUrl={URL_WORKFLOW.WOOD_IMPORT_WORKFLOW}
        columns={columns}
        showSearch
        showSize
        showPagination
        extraFilter={[dateFilterComponent, typeFilterComponent]}
        extraFilterParams={{ date: date, type: typeFilter }}
      />
    </div>
  );
}