import React from "react";
import { URL_LOG_WOOD } from "../../constance/urls";
import { dateTimeToString } from "../../utils/stringFormat";
import ReportLogWoodDetail from "../../components/reports/ReportLogWoodDetail";
import { useTranslation } from "react-i18next";

function ReportLogWoodLengthView() {

  const { t } = useTranslation();
  const columns = React.useMemo(() => [
    { 
      Header: "ลำดับที่", 
      columns: [{Header: "", accessor: "index", width: 80}] 
    },
    {  
      Header: "ว/ด/ป", 
      columns: [
        {
          Header: "", 
          accessor: "in_date_time", 
          width: 140, 
          Cell: ({value}) => (<p>{dateTimeToString(value)}</p>)
        }
      ] 
    },
    { 
      Header: "ทะเบียนรถ", 
      columns: [{ Header: "", accessor: "license_plate", width: 120 }]
    },
    { 
      Header: "ชื่อลูกค้า", 
      columns: [{Header: "", accessor: "truck_company_name", width: 220}] 
    },
    { 
      Header: "จำนวน (ท่อน)", 
      columns: [
        { Header: "สุ่มตรวจ", accessor: "report.sum_amount" },
        { Header: "ตามเกณฑ์", accessor: "report.meet_criteria" },
        { Header: "ยาวเกิน", accessor: "report.over_criteria" },
        { Header: "สั้นเกิน", accessor: "report.under_criteria" },
      ]
    },
    { 
      Header: "สัดส่วน (%)", 
      columns: [
        { 
          Header: "ตามเกณฑ์", 
          accessor: "report.meet_criteria_percent", 
          Cell: ({value}) => (<p>{parseFloat(value).toFixed(2)}</p>) 
        },
        { 
          Header: "ยาวเกิน", 
          accessor: "report.over_criteria_percent",
          Cell: ({value}) => (<p>{parseFloat(value).toFixed(2)}</p>)
        },
        { 
          Header: "สั้นเกิน", 
          accessor: "report.under_criteria_percent",
          Cell: ({value}) => (<p>{parseFloat(value).toFixed(2)}</p>)
        },
      ]
    },
    { 
      Header: "หมายเหตุ",
      columns: [{Header: "", accessor: "note", width: 160}]
    },
  ])

  return (
    <>
    <ReportLogWoodDetail 
			title={t('menus.report_menus.log_wood_menus.log_wood_length')}
			url={URL_LOG_WOOD.REPORT_LOG_WOOD_LENGTH}
			tableColumns={columns}
			exportUrl={URL_LOG_WOOD.REPORT_LOG_WOOD_LENGTH_EXPORT}/>
    </>
  )
}

export default ReportLogWoodLengthView;