import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import * as _ from "lodash";
import moment from "moment";
import { formatComma } from "../../utils/stringFormat";
import { PATH } from "../../routes/Routes";
import { Button, Container, Divider, Form, Header, Popup } from "semantic-ui-react";
import { DELETE } from "../../utils/HttpClient";
import { Toast } from "../../utils/Toast";
import MainHeader from "../../components/common/MainHeader";
import ReportExportButton from "../../components/common/ReportExportButton";
import { URL_POST_BAKING, URL_REPORT } from "../../constance/urls";
import ConfirmModal from "../../components/common/ConfirmModal";
import CustomTable from "../../components/common/CustomTable";
import SemanticDatepicker from "react-semantic-ui-datepickers";


export default function PostBakingWasteWoodSummaryReportView() {

  const { t } = useTranslation();
  const [dateRange, setDateRange] = React.useState([]);
  const [ovenFilter, setOvenFilter] = React.useState(null)
  const haveDateRange = _.get(dateRange, 0, null) && _.get(dateRange, 1, null)

  return (
    <div>
      <Container style={{ marginTop: '7em' }}>
        <MainHeader/>
        <Header as='h2'>{t('menus.report_menus.waste_woods.post_baking_waste_wood_summary')}</Header>
        <Form style={{ marginTop: '3rem' }}>
          <Form.Group inline style={{ justifyContent: 'center' }}>
            <Form.Field
              id='form-input-control-dateRange'
              name='dateRange'
              inline
              label={t('post_baking_waste_wood_count.group_date')}
              control={SemanticDatepicker}
              locale="en-US"
              type="range"
              format="DD/MM/YYYY"
              showToday={true}
              placeholder={t('searchs.placeholders.date')}
              values={dateRange}
              onChange={(_, data) => {
                setDateRange(data.value)
              }}
            />
            <Form.Input
              label={t('post_baking_waste_wood_count.oven_no')}
              type='number'
              placeholder={t('all')}
              onChange={(_, data) => setOvenFilter(data.value)}/>
          </Form.Group>
        </Form>
        <Popup
          inverted
          content={t('searchs.placeholders.date')}
          on='hover'
          trigger={
            <span style={{ marginTop: '3rem auto', width: 'fit-content' }}>
              <ReportExportButton
                url={`${URL_REPORT.POST_BAKING_WASTE_WOOD_SUMMARY_REPORT}pdf/`}
                title={t('menus.report_menus.waste_woods.post_baking_waste_wood_summary')}
                type='pdf'
                disabled={!haveDateRange}
                params={{
                  oven: ovenFilter,
                  date_after: _.get(dateRange, 0, null) ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
                  date_before: _.get(dateRange, 1, null) ? moment(_.get(dateRange, 1)).format('YYYY-MM-DD') : null,
                }}/>
              <ReportExportButton
                url={`${URL_REPORT.POST_BAKING_WASTE_WOOD_SUMMARY_REPORT}excel/`}
                title={t('menus.report_menus.waste_woods.post_baking_waste_wood_summary')}
                type='excel'
                disabled={!haveDateRange}
                params={{
                  oven: ovenFilter,
                  date_after: _.get(dateRange, 0, null) ? moment(dateRange[0]).format('YYYY-MM-DD') : null,
                  date_before: _.get(dateRange, 1, null) ? moment(_.get(dateRange, 1)).format('YYYY-MM-DD') : null,
                }}/>
            </span>
          }/>
      </Container>
    </div>
  )
}

