import React from "react";
import propTypes from 'prop-types';
import { Container, Message, Header, Button, Icon, Dimmer, Loader, Segment } from 'semantic-ui-react';
import MainHeader, { MENU_REPORT } from "../common/MainHeader";
import ReportLogWoodFilters from "./ReportLogWoodFilters";
import TSReactTable from "../common/TSReactTable";
import { GET } from "../../utils/HttpClient";
import ReportExportButton from '../../components/common/ReportExportButton';


function ReportLogWoodDetail(props) {
	const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
	const [data, setData] = React.useState([]);
  const [exportParams, setExportParams] = React.useState(null);
  const filterRef = React.useRef();

	const handleFilterSelected = queryString => {
    fetchData(queryString);
    console.log(filterRef.current.getParams())
    setExportParams(filterRef.current.getParams())
  }

	const fetchData = async (params) => {
    if (!params) {
      setErrorMessages("กรณาเลือกช่วงเวลา");
			return;
    }

		if (!props.url) {
			setErrorMessages("ไม่พบ URL สำหรับดึงข้อมูล");
			return;
		}
		
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${props.url}?${params}`)
      let myData = response.data;
      setData(myData.map((item, index) => {
        return {
          ...item,
          index: index + 1,
        }
      }));
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  };

	return (
		<>
      <MainHeader activeItem={MENU_REPORT}/>
      <Container style={{ marginTop: '7em' }}>
        <Header as='h2'>{props.title}</Header>
        {errorMessages && (
          <Message 
            negative
            style={{textAlign: "left"}}
            header={"เกิดข้อผิดพลาด"}
            content={errorMessages}/>
        )}
        
        <ReportLogWoodFilters ref={filterRef} onFiltered={handleFilterSelected} hideCustomerFilter={props.hideCustomerFilter}/>
        <div style={{textAlign: "right", marginBottom: 8}}>

          {['pdf', 'excel'].map(type => (
            <ReportExportButton
              url={`${props.exportUrl}${type}/?${exportParams}`}
              type={type}
              title={`${props.title}...`}
              disabled={exportParams == null}
              size='mini'
            />)
          )}
        
        </div>
        
        {/* Segment for contain loading element */}
        <Segment basic style={{padding: 0}}>
          <Dimmer active={loading} inverted>
            <Loader inverted/>
          </Dimmer>
          <TSReactTable
            data={data}
            columns={props.tableColumns}
            pageSize={20}/>
        </Segment>

      </Container>
    </>
	)
}

export default ReportLogWoodDetail;

ReportLogWoodDetail.propTypes = {
	title: propTypes.string,
	url: propTypes.string,
	tableColumns: propTypes.array,
  exportUrl: propTypes.string,
  hideCustomerFilter: propTypes.bool
}

ReportLogWoodDetail.defaultProps = {
	title: "Title",
	url: null,
	tableColumns: [],
  exportUrl: "",
  hideCustomerFilter: false
}