import * as _ from 'lodash';
import { round } from 'lodash';


export function calculateWoodSizeVolume(woodCode) {
  if (!typeof(woodCode) === "string") {
    return 0
  }

  try {
    // 10 016 04 12 080
    let thick = Number(woodCode.substring(5, 7)); // 4
    let width = woodCode.substring(7, 9); // 12
    let length = Number(woodCode.substring(9, 12)); // 80

    let thickResult = parseInt(thick / 10) + ((thick % 10) / 8);
    let widthResult = 0;
    if(width && width.length == 1) {
      widthResult = width[0] / 8;
    } else if(width && width.length > 1) {
      widthResult = parseInt(width[0], 24) + (width[1] / 8)
    }
    let lengthResult = length / 100

    return round(0.0228 * thickResult * lengthResult * widthResult, 6);
  } catch {
    return 0
  }
}