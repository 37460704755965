import { useFormik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Container, Form, Header } from 'semantic-ui-react';
import MainHeader, { MENU_REPORT } from '../../components/common/MainHeader';
import * as Yup from 'yup';
import { WOOD_IMPORT_GRADE_DISPLAY } from '../../constance/dataTypes';
import moment from 'moment';
import ReportDetail from '../../components/reports/ReportDetail';
import { URL_BARCODE } from '../../constance/urls';
import { formatComma } from '../../utils/stringFormat';
import ReportExportButton from '../../components/common/ReportExportButton';


const GRADE_RENDER_LIST = [
  {key: 'ab', text: 'ab'},
  {key: 'c', text: 'c'},
  {key: 'p', text: 'p'},
  {key: 'pp', text: 'pp'},
  {key: 'other', text: 'อื่น ๆ'},
]

export default function ReportWoodImportByVolume() {

  const { t } = useTranslation();
  const [queryParams, setQueryParams] = React.useState(null)

  const ref = React.useRef();

  const columns = React.useMemo(() => [
    {
      Header: 'วันที่',
      accessor: 'date',
      width: 120,
    },
    ...GRADE_RENDER_LIST.map(grade => {
      return {
        Header: grade.text.toUpperCase(),
        accessor: grade.key,
        columns: [
          { 
            Header: 'ปริมาณ',
            accessor: `${grade.key}_volume`,
            Cell: ({ value }) => formatComma(value, 4)
          },
          { 
            Header: 'จำนวนเงิน',
            accessor: `${grade.key}_cost`,
            Cell: ({ value }) => formatComma(value)
          },
          { 
            Header: 'ราคาเฉลี่ย',
            accessor: `${grade.key}_cost_mean`,
            Cell: ({ value }) => formatComma(value)
          },      
        ]
      }
    }),
    {
      Header: 'รวมทุกเกรด',
      columns: [
        { 
          Header: 'ปริมาณ',
          accessor: 'sum_volume',
          Cell: ({ value }) => formatComma(value, 4)
        },
        { 
          Header: 'จำนวนเงินรวม',
          accessor: 'sum_cost',
          Cell: ({ value }) => formatComma(value)
        },
      ]
    },
  ], [])

  const wood_grade_options = WOOD_IMPORT_GRADE_DISPLAY.map(grade => { return { value: grade, key: grade, text: grade.toLocaleUpperCase() } })

  const getQueryParams = () => {
    const values = formik.values;
    const startDate = moment(values.dateRange[0]).format('YYYY-MM-DD');
    const endDate = moment(values.dateRange[1]).format('YYYY-MM-DD');
    const queryString = `date_after=${startDate}&date_before=${endDate}&grade=${values.grade}`
    
    return queryString
  }

  const formik = useFormik({
    validateOnChange: false,
    validationSchema: Yup.object().shape({
      dateRange: Yup.array().min(1, t('error_messages.validation.required')),
      grade: Yup.string().required(t('error_messages.validation.required'))
    }),
    initialValues: {
      dateRange: [],
      grade: 'ab'
    },
    onSubmit: () => {
      if (ref.current) {
        const params = getQueryParams()
        ref.current.refetch(params);
        setQueryParams(params)
      }
    }
  })

  return (
    <div>
      <MainHeader activeItem={MENU_REPORT}/>
      <Container style={{ marginTop: '7em' }}>
        <Header as='h2'>{t('menus.report_menus.wood_import_menus.wood_import_by_volume')}</Header>
        <Form autoComplete="off" style={{marginTop: 24, marginBottom: 24}}>
          <Form.Group inline>
            <Form.Field
              id='form-input-control-dateRange'
              name='dateRange'
              inline
              label={t('filter')}
              control={SemanticDatepicker}
              locale="en-US"
              type="range"
              format="DD/MM/YY"
              showToday={true}
              placeholder={t('searchs.placeholders.date')}
              values={formik.values.dateRange}
              error={formik.errors.dateRange != null}
              onChange={(_, data) => {
                formik.setFieldValue('dateRange', data.value);
              }}
            />
            <Form.Button primary type='submit' onClick={formik.handleSubmit}>{t('search')}</Form.Button>
          </Form.Group>
          <div style={{ textAlign: 'right' }}>
            {['pdf', 'excel'].map(type => (
              <ReportExportButton
                url={`${URL_BARCODE.REPORT_WOOD_IMPORT_VOLUME_EXPORT}${type}/?${queryParams}`}
                type={type}
                title={`${t('menus.report_menus.wood_import_menus.wood_import_by_volume')}.....`}
                disabled={queryParams==null}
              />)
            )}
          </div>
        </Form>
        <ReportDetail
          ref={ref}
          reportUrl={URL_BARCODE.REPORT_WOOD_IMPORT_VOLUME}
          columns={columns}
        />
      </Container>
    </div>
  )
}