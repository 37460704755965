import React from "react";
import PropTypes from "prop-types";
import { Button, Dimmer, Header, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { defaultPostBakingWasteWoodInputDetail } from "../../constance/dataTypes";
import { GET, PATCH, POST, PUT } from "../../utils/HttpClient";
import { URL_POST_BAKING } from "../../constance/urls";
import ErrorMessage from "../common/ErrorMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as _ from "lodash";
import { Toast } from "../../utils/Toast";
import moment from "moment";
import InputWasteWoodForm from "./InputWasteWoodForm";


export default function InputWasteWood (props) {
  const {
    target,
    transactionDetail,
    groupDate,
    inputDate,
    onBack,
  } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const isCreate = !target ? true : false;
  const transactionHasNoPostBakingGroup = !_.get(transactionDetail, 'post_baking_group_date', null);

  const formik = useFormik({
    validateOnChange: false,
    initialValues: defaultPostBakingWasteWoodInputDetail,
    validationSchema: Yup.object().shape({
      grade: Yup.string().required(t('error_messages.validation.required')),
      wood_code_dimension: Yup.string().required(t('error_messages.validation.required'))
        .length(10, t('error_messages.validation.length')),
      amount: Yup.number().required(t('error_messages.validation.required'))
        .moreThan(0, `${t('error_messages.validation.more_than')} 0`).nullable(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      setErrorMessages(null)
      try {
        if (isCreate) {
          await POST(URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT, values)
        } else {
          await PUT(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT}${target}/`, values)
        }
        if (transactionHasNoPostBakingGroup) {
          await PATCH(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT_TRANSACTION}${transactionDetail.id}/`,
            { post_baking_group_date: groupDate, input_date: inputDate }
          )
        }
        Toast.success(`${t('save')} ${t('success')}`)
        onBack();
      } catch (error) {
        setErrorMessages(error.errorMessages)
      } finally {
        setLoading(false)
      }
    }
  });

  const fetchData = async () => {
    setLoading(true);
    setErrorMessages(null)
    try {
      const response = await GET(`${URL_POST_BAKING.POST_BAKING_WASTE_WOOD_INPUT}${target}/`)
      formik.setValues(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    if (target) {
      fetchData()
    } else {
      formik.setFieldValue('transaction', transactionDetail.id)
    }
  }, [target])

  return (
    <div style={{ marginTop: '1rem' }}>
      <Header as='h4'>
        <div>
          {t('post_baking_waste_wood_count.group_date')}&nbsp;
          {moment(groupDate, 'yyyy-MM-DD').format('DD/MM/yyyy')}
        </div>
      </Header>
      <Dimmer inverted active={loading}>
        <Loader inverted content={t('loading')}/>
      </Dimmer>
      <ErrorMessage message={errorMessages}/>

      <InputWasteWoodForm formik={formik}/>

      <Button
        color={isCreate ? 'green' : 'blue'}
        icon={isCreate ? 'add' : 'edit'}
        content={isCreate ? t('add') : t('save')}
        onClick={formik.handleSubmit}/>
      <Button content={t('cancel')} onClick={onBack}/>
    </div>
  )
}


InputWasteWood.propTypes = {
  target: PropTypes.string,
  transactionDetail: PropTypes.objectOf(defaultPostBakingWasteWoodInputDetail),
  groupDate: PropTypes.string,
  onBack: PropTypes.func,
}

InputWasteWood.defaultProps = {
  target: null,
  transaction: defaultPostBakingWasteWoodInputDetail,
  groupDate: '',
  onBack: () => null,
}