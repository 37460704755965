import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Dimmer, Divider, Form, Header, Label, List, Loader, Message, Popup, Segment, Tab, Table } from 'semantic-ui-react';
import { URL_WOOD_EXPORT } from '../../constance/urls';
import { DELETE, GET } from '../../utils/HttpClient';
import { ellipsisString, formatComma } from '../../utils/stringFormat';
import { Toast } from '../../utils/Toast';
import ConfirmModal from '../common/ConfirmModal';
import AddExportBarcodeModal from './AddExportBarcodeModal';


export default function ExportItemList(props) {
  const {
    editable,
    exportTransactionId
  } = props;

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [saleLineList, setSaleLineList] = React.useState([]);
  const [saleLineTarget, setSaleLineTarget] = React.useState(null);
  const [deleteTarget, setDeleteTarget] = React.useState(null);

  const fetchData = async () => {
    setIsLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(`${URL_WOOD_EXPORT.EXPORT_TRANSACTION}${exportTransactionId}${URL_WOOD_EXPORT.EXPORT_SALE_LINES}`)
      setSaleLineList(response.data)
    } catch(error) {
      setErrorMessages(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  }

  const deleteData = async (target) => {
    setIsLoading(true);
    try {
      await DELETE(`${URL_WOOD_EXPORT.EXPORT_ITEM}${target}/`)
      Toast.success(`${t('delete')} ${t('success')}`)
      fetchData();
    } catch(error) {
      Toast.error(error.errorMessages)
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [exportTransactionId])

  return (
    <div>
      <Header>{t('export_transactions.sale_line')}</Header>
      { errorMessages && 
        <Message error>
          <Message.Header>{t('error')}</Message.Header>
          {errorMessages}
        </Message>
      }
      <Dimmer inverted active={isLoading}>
        <Loader>{t('loading')}</Loader>
      </Dimmer>
      {saleLineList.map(saleLine => (
        <Form>
          <Segment.Group>
            <Segment color='blue'>
              <Form.Group widths='equal' inline>
                <Form.Input
                  fluid
                  label={t('export_transactions.product_code')}
                  transparent
                  readOnly
                  value={_.get(saleLine, 'product_code') || '-'}/>
                <Form.Input
                  fluid
                  label={t('export_transactions.product_name')}
                  transparent
                  readOnly
                  value={_.get(saleLine, 'product_name') || '-'}/>
                <Form.Input
                  fluid
                  label={t('export_transactions.lot_id')}
                  transparent
                  readOnly
                  value={_.get(saleLine, 'lot_id') || '-'}/>
                <Form.Input
                  fluid
                  label={t('export_transactions.soj')}
                  transparent
                  readOnly
                  value={_.get(saleLine, 'soj') || '-'}/>
                <Form.Input
                  fluid
                  label={t('export_transactions.pallet')}
                  transparent
                  readOnly
                  value={formatComma(_.get(saleLine, 'pallet', 0), 0)}/>
                <Form.Input
                  fluid
                  label={t('export_transactions.amount')}
                  transparent
                  readOnly
                  value={formatComma(_.get(saleLine, 'amount', 0))}/>
                <Form.Input
                  fluid
                  label={_.get(saleLine, 'sale_unit') || '-'}
                  transparent
                  readOnly
                  value={formatComma(_.get(saleLine, 'volume', 0))}/>
              </Form.Group>
            </Segment>
            <Segment>
              <Form.Group inline>
                { editable && 
                  <Form.Button
                    label={t('barcodes.barcode_id')}
                    positive
                    icon='add'
                    size='mini'
                    content={t('add')}
                    onClick={() => setSaleLineTarget(saleLine.id)}/>
                }
              </Form.Group>
              { _.get(saleLine, 'export_items', []).length > 0 ? (
                <Table>
                  <Table.Header>
                    <Table.HeaderCell>{t('barcodes.barcode_id')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('export_transactions.product_code')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('export_transactions.packing_type')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('export_transactions.humidity')}</Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                  </Table.Header>
                  <Table.Body>
                    { _.get(saleLine, 'export_items', []).map(exportItem => (
                      <Table.Row>
                        <Table.Cell>{_.get(exportItem, 'barcode_no') || '-'}</Table.Cell>
                        <Table.Cell>
                          <List bulleted relaxed>
                            {_.get(exportItem, 'wood_size_amount_map_list', []).map(e => (
                              <List.Item>
                                <List.Content>
                                  <span style={{marginRight: '0.5em'}}>{_.get(e, 'wood_code', '')} x {formatComma(_.get(e, 'amount', 0))}</span>
                                  <Label basic color='blue'>{formatComma(_.get(e, 'volume', 0))} {t('units.cubic')} {t('units.ft')}</Label>
                                </List.Content>
                              </List.Item>
                            ))}
                          </List>
                        </Table.Cell>
                        <Table.Cell>
                          {_.get(exportItem, 'packing_type') ? t(`export_transactions.packing_types.${_.get(exportItem, 'packing_type')}`) : ''}
                        </Table.Cell>
                        <Table.Cell>
                          <Popup
                            content={_.get(exportItem, 'humidity', '')}
                            inverted
                            trigger={<div>{ellipsisString(_.get(exportItem, 'humidity'), 26)}</div>}
                            position='bottom left'/>
                        </Table.Cell>
                        <Table.Cell textAlign='center'>
                          { editable && 
                            <Button
                              negative
                              icon='trash'
                              basic
                              content={t('delete')}
                              onClick={() => setDeleteTarget(exportItem.id)}/> 
                          }
                        </Table.Cell>
                      </Table.Row>
                    )) }
                  </Table.Body>
                </Table>
              ) : null }
            </Segment>
          </Segment.Group>
        </Form>
      ))}
    
      <AddExportBarcodeModal
        saleLineTarget={saleLineTarget}
        open={saleLineTarget != null}
        onClose={() => setSaleLineTarget(null)}
        fetchData={fetchData}/>

      <ConfirmModal
        open={deleteTarget != null}
        onClose={() => setDeleteTarget(null)}
        title={t('confirms.delete_title')}
        content={t('confirms.delete_content')}
        onConfirm={() => {
          deleteData(deleteTarget);
          setDeleteTarget(null);
        }}
      />

    </div>
  )
}