import React from 'react';
import { useTable } from 'react-table';
import { Table, Dimmer, Loader, Message, Pagination, Form, Button, Grid, Input, Dropdown, Checkbox, Segment } from 'semantic-ui-react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { GET } from '../../utils/HttpClient';
import { FETCH_DATA_LIST } from '../../constance/dataTypes';


const PAGE_SIZE = 10;

const CustomTable = React.forwardRef((props, ref) => {
  const {
    columns,
    fetchUrl,
    manual,

    // pagination props
    showPagination,

    // filter props
    showSearch,
    showSize,
    extraFilter,
    extraFilterParams,

    // select props
    showSelect,
    extraActions,
    updateSelectedList,

    // handling parent state
    setIsEmpty
  } = props;

  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  
  const [data, setData] = React.useState(FETCH_DATA_LIST);
  const isInitialMount = React.useRef(true);

  // pagination
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(PAGE_SIZE);

  // filter
  const [searchValue, setSearchValue] = React.useState(null);
  const [searched, setSearched] = React.useState(null)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    dispatch,
    state,
  } = useTable(
    {
      columns,
      data: data.results,
      initialState: {
        selectedList: []
      },
      // handling selection reducer
      stateReducer: (newState, action, prevState) => {
        switch (action.type) {
          case 'all':
            return {
              ...newState,
              selectedList: [...newState.selectedList, ...data.results.map(e => e.id)]
            }
          case 'add':
            return { 
              ...newState, 
              selectedList: [...newState.selectedList, action.selectedItem]
            }
          case 'remove':
            return { 
              ...newState, 
              selectedList: [
                ...newState.selectedList.filter(e => e != action.selectedItem)
              ]
            }
          case 'reset':
            return {
              ...newState,
              selectedList: []
            }
          default:
            return newState
        }
      }
    },
    hooks => {
      if (showSelect) {
        hooks.visibleColumns.push(columns => [
          {
            id: 'selection',
            Header: ({ state, data }) => (
              <Checkbox
                checked={isSelectedAllInPage(state.selectedList, data)}
                onChange={() => {
                  if(isSelectedAllInPage(state.selectedList, data)) {
                    dispatch({ type: 'reset' })
                  } else {
                    dispatch({ type: 'all' })
                  }
                }}
              />
            ),
            Cell: ({ state, row }) => (
              <Checkbox
                checked={state.selectedList.includes(row.original.id)}
                onChange={() => {
                  if (state.selectedList.includes(row.original.id)) {
                    // do remove
                    dispatch({ selectedItem: row.original.id, type: 'remove' })
                  } else {
                    // do add
                    dispatch({ selectedItem: row.original.id, type: 'add' })
                  }
                }}
              />
            )
          },
          ...columns,
        ])
      }
    }
  );

  const isSelectedAllInPage = (selectedList, data) => {
    const count = data.reduce((p, c) => p + (selectedList.includes(c.id) ? 1 : 0), 0);
    return count === data.length;
  }

  const refetch = async (params) => {
    setIsLoading(true);
    setErrorMessages(null);
    setData(FETCH_DATA_LIST)
    try {
      params = {
        page: page,
        page_size: pageSize,
        ...params,
      }
      const response = await GET(fetchUrl, params);
      setData(response.data);
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSearch = () => {
    setPage(1);
    dispatch({type: 'reset'})
    setSearched(searchValue);
    refetch({page: 1, page_size: pageSize, search: searchValue, ...extraFilterParams})
  }

  const handleRefresh = () => {
    setPage(1)
    dispatch({type: 'reset'})
    refetch({page: 1, page_size: pageSize, search: searchValue, ...extraFilterParams});
  }

  const handlePageChange = (page) => {
    setPage(page);
    if (searchValue === searched) {
      refetch({page: page, page_size: pageSize, search: searchValue, ...extraFilterParams})
    } else {
      refetch({page: page, page_size: pageSize, ...extraFilterParams})
    }
  }

  const handlePageSizeChange = (pageSize) => {
    setPage(1)
    dispatch({type: 'reset'})
    setPageSize(pageSize)
    refetch({page: 1, page_size: pageSize, search: searchValue, ...extraFilterParams})
  }

  const handleReset = () => {
    setIsLoading(false);
    setErrorMessages(null);  
    setData(FETCH_DATA_LIST);
    setPage(1);
    setPageSize(PAGE_SIZE);
    setSearchValue(null);
    setSearched(null)
  }

  React.useImperativeHandle(ref, () => ({
    refetch: () => handleRefresh(),
    setIsLoading: (value) => setIsLoading(value),
    setErrorMessages: (messages) => setErrorMessages(messages),
    reset: () => handleReset(),
	}));

  React.useEffect(() => {
    updateSelectedList(state.selectedList)
  }, [state.selectedList])

  React.useEffect(() => {
    if (!manual) {
      refetch({...extraFilterParams});
      isInitialMount.current = false;
    }
  }, []);

  React.useEffect(() => {
    if (errorMessages) {
      setIsEmpty(true)
    } else {
      setIsEmpty(data.total === 0);
    }
  }, [data, errorMessages])

  const renderActionHeader = () => {
    if (
      extraActions ||
      showSearch ||
      showSize ||
      extraFilter
    ) {
      return (
        <Form>
          <Grid columns='3'>
            { extraActions &&
              <Grid.Column floated='right' width='16'>
                {extraActions}
              </Grid.Column>
            }
            <Grid.Column floated='left' width='13'>
              <Form.Group inline>
                {extraFilter}
                {showSearch && 
                  <Form.Input
                    name='search'
                    label={t('search')}
                    placeholder={`${t('search')}...`}
                    value={searchValue || ''}
                    onChange={e => {
                      setSearchValue(e.target.value);
                    }}

                  />
                }
                { (
                    extraActions ||
                    showSearch ||
                    extraFilter
                  ) &&
                  <Button color='blue' icon='search' onClick={handleSearch}/>
                }
              </Form.Group>
            </Grid.Column>
            {
              showSize &&
              <Grid.Column floated='right' width='3'>
                <Form.Group inline>
                  <label>{t('show')}</label>
                  <Dropdown
                    fluid
                    selection
                    selectOnBlur={false}
                    defaultValue={PAGE_SIZE}
                    options={[
                      {key: '10', text: `10 ${t('datas.rows')}`, value : 10},
                      {key: '25', text: `25 ${t('datas.rows')}`, value : 25},
                      {key: '50', text: `50 ${t('datas.rows')}`, value : 50},
                    ]}
                    onChange={(_, data) => handlePageSizeChange(data.value)}
                  />
                </Form.Group>
              </Grid.Column>
            }
          </Grid>
        </Form>
      )
    }
    return null;
  }

  return (
    <Segment basic style={{ textAlign: 'center', padding: 0 }}>
      <Dimmer inverted active={isLoading}>
        <Loader inverted>{t('loading')}</Loader>
      </Dimmer>
      { errorMessages &&
        <Message negative>
          <Message.Header>{t('error')}</Message.Header>
          <p>{errorMessages}</p>
        </Message>
      }
      { renderActionHeader()}
      <Table {...getTableProps()} unstackable>
        <Table.Header>
          {headerGroups.map(headerGroup => (
            <Table.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <Table.HeaderCell {...column.getHeaderProps()}>
                  {column.render('Header')}
                </Table.HeaderCell>
              ))}
            </Table.Row>
          ))}
        </Table.Header>
        <Table.Body {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <Table.Row {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <Table.Cell {...cell.getCellProps()}>{cell.render('Cell')}</Table.Cell>
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
        {data.total === 0 && !isLoading &&
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='100'>
                {/* show just a table footer */}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        }
      </Table>
      { showPagination && 
        <Pagination
          activePage={page}
          totalPages={data.pages}
          onPageChange={(_, data) => handlePageChange(data.activePage)}
        />
      }
    </Segment>
  )
});

CustomTable.defaultProps = {
  columns: [],
  fetchUrl: '',
  manual: false,
  showPagination: false,
  extraFilter: null,
  extraFilterParams: {},
  showSelect: false,
  extraActions: null,
  updateSelectedList: () => null,
  setIsEmpty: () => null,
}

CustomTable.propTypes = {
  columns: propTypes.array,
  fetchUrl: propTypes.string,
  manual: propTypes.bool,
  showPagination: propTypes.bool,
  extraFilter: propTypes.array,
  extraFilterParams: propTypes.object,
  showSelect: propTypes.bool,
  extraActions: propTypes.object,
  updateSelectedList: propTypes.func,
  setIsEmpty: propTypes.func,
}

export default React.memo(CustomTable)