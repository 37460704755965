import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Button, Container, Form, Header } from 'semantic-ui-react';
import CustomTable from '../../components/common/CustomTable';
import MainHeader, { MENU_REPORT } from '../../components/common/MainHeader';
import ReportExportButton from '../../components/common/ReportExportButton';
import { URL_BARCODE } from '../../constance/urls';


export default function ReportWoodImportListView() {

  const { t } = useTranslation();
  const [date, setDate] = React.useState('');
  const tableRef = React.useRef();

  const columns = React.useMemo(() => [
    {
      Header: t('date'),
      accessor: 'created',
      Cell: ({ row }) => new Date(row.original.created).toLocaleString('en-GB')
    },
    {
      Header: t('wood_imports.order_no'),
      accessor: 'order_no',
    },
    {
      Header: t('truck_transactions.company'),
      accessor: 'truck_company_name',
    },
    {
      Header: t('trucks.type'),
      accessor: 'truck_type_name',
    },
    {
      Header: () => null,
      accessor: 'id',
      Cell: ({ row }) => (
        <div style={{textAlign: 'center'}}>
          {['pdf'].map( type => (
            <ReportExportButton
              url={`${URL_BARCODE.REPORT_WOOD_IMPORT_EXPORT}${type}/?id=${row.original.id}`}
              type={type}
              title={`${t('menus.report_menus.wood_import_menus.wood_import_detail') }.....`}
            />)
          )}
        </div>
      )
    }
  ], []);

  const dateFilterComponent = (
    <Form.Field
      label={t('searchs.fields.date')}
      locale='en-US'
      format='DD/MM/YY'
      showToday={true}
      placeholder={t('searchs.placeholders.date')}
      control={SemanticDatepicker}
      onChange={(_, data) => {
        if (data.value) {
          setDate(moment(data.value).format('YYYY-MM-DD'))
        } else {
          setDate('')
        }
      }}
    />
  )

  return (
    <div>
      <MainHeader activeItem={MENU_REPORT}/>
      <Container style={{ marginTop: '7em' }}>
        <Header as='h2'>{t('menus.report_menus.wood_import_menus.wood_import_detail')}</Header>

        <CustomTable
          ref={tableRef}
          fetchUrl={URL_BARCODE.WOOD_IMPORT_TRANSACTION}
          columns={columns}
          showSearch
          showSize
          showPagination
          extraFilter={dateFilterComponent}
          extraFilterParams={{ date: date, is_approved: true }}
        />

      </Container>
    </div>
  );
}
