import React from "react";
import PropTypes from "prop-types";
import { Button, Checkbox, Dimmer, Form, Grid, Header, List, Loader } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { GET } from "../../utils/HttpClient";
import { URL_POST_BAKING } from "../../constance/urls";
import ErrorMessage from "../common/ErrorMessage";


export default function SelectOvenSetDetail (props) {
  const {
    groupDate,
    onGoToOvenSetDetail,
    onBack,
  } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [errorMessages, setErrorMessages] = React.useState(null);
  const [ovenSetList, setOvenSetList] = React.useState([]);
  const [selectedOvenSet, setSelectOvenSet] = React.useState(null);

  const handleCountWood = () => {
    setErrorMessages(null);
    if (!selectedOvenSet || !selectedOvenSet.length) {
      setErrorMessages(t('post_baking_waste_wood_count.errors.select_oven'))
      return;
    }
    onGoToOvenSetDetail(selectedOvenSet)
  }

  const fetchOvenSet = async () => {
    setLoading(true);
    setErrorMessages(null);
    try {
      const response = await GET(URL_POST_BAKING.OVEN_SET, { date: groupDate })
      setOvenSetList(response.data)
    } catch (error) {
      setErrorMessages(error.errorMessages);
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (groupDate) {
      fetchOvenSet()
    }
  }, [groupDate])

  return (
    <div style={{ marginTop: '3rem' }}>
      <Header as='h4'>{t('post_baking_waste_wood_count.oven_set_wood_status')}</Header>

      <ErrorMessage message={errorMessages}/>
      <Dimmer active={loading} inverted>
        <Loader inverted>{t('loading')}</Loader>
      </Dimmer>
      <Form>
        <List>
          {ovenSetList.map(ovenSet => (
            <List.Item>
              <Form.Field>
                <Checkbox
                  radio
                  label={ovenSet.join(', ')}
                  value={ovenSet}
                  onChange={(__, data) => {
                    setSelectOvenSet(data.value)
                  }}/>
              </Form.Field>
                
            </List.Item>
          ))}
        </List>
        <Form.Group inline style={{ justifyContent: 'center', marginTop: '2rem' }}>
          <Form.Button primary icon='file outline alternate' content={t('detail')} onClick={() => handleCountWood()}/>
          <Form.Button content={t('back')} onClick={onBack}/>
        </Form.Group>
      </Form>
    </div>
  )
}


SelectOvenSetDetail.propTypes = {
  groupDate: PropTypes.string,
  onGoToOvenSetDetail: PropTypes.func,
  onBack: PropTypes.func,
}

SelectOvenSetDetail.defaultProps = {
  groupDate: null,
  onGoToOvenSetDetail: () => null,
  onBack: () => null,
}