import React from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Container, Dimmer, Divider, Header, Loader, Menu, Message, Segment } from 'semantic-ui-react';
import SubHeader from '../components/common/SubHeader';
import LogWoodPODetail from '../components/log-wood-po/LogWoodPODetail';
import LogWoodPOSummary from '../components/log-wood-po/LogWoodPOSummary';
import ActionButtons from '../components/workflow/ActionButtons';
import HistoryListModal from '../components/workflow/HistoryListModal';
import { defaultLogWoodPOWorkflowDetail } from '../constance/dataTypes';
import { URL_WORKFLOW } from '../constance/urls';
import useWorkflowDetail from '../hooks/useWorkflowDetail';
import { PATH } from '../routes/Routes';
import { Toast } from '../utils/Toast';
import { Media } from "../AppMedia";
import { useIdleTimer } from 'react-idle-timer';
import TimeoutModal from '../components/common/TimeoutModal';

const BasicButton = ({icon, content, floated, onClick=() => {}}) => {
  return (
    <Button 
      basic
      color='blue'
      icon={icon}
      content={content}
      floated={floated}
      onClick={onClick}
    />
  )
}

function LogWoodPODetailView () {

  const params = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  const {
    response,
    fetchData,
    handleAction,
    isLoading,
    errorMessages,
  } = useWorkflowDetail({ 
    workflowUrl: URL_WORKFLOW.LOG_WOOD_PO_WORKFLOW,
    id: params.id,
    onSuccess: (action) => {
      history.push(PATH.HOME);
      Toast.success(`${t(`workflows.actions.${action}`)} ${t('success')}`)
    },
  });

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30, // 30 minutes
    onIdle: () => setIsTimeout(true),
    crossTab: true
  })

  const [isTimeout, setIsTimeout] = React.useState(false);
  const [detail, setDetail] = React.useState(defaultLogWoodPOWorkflowDetail);
  const [currentDetail, setCurrentDetail] = React.useState(defaultLogWoodPOWorkflowDetail);
  const [detailTab, setDetailTab] = React.useState('truck_transaction_detail')
  const [isActionLoading, setIsActionLoading] = React.useState(false);
  const [isDetailLoading, setIsDetailLoading] = React.useState(false);
  const detailRef = React.useRef(null);

  // history modal
  const [isHistoryModalVisible, setIsHistoryModalVisible] = React.useState(false);

  React.useEffect(() => {
    if (!params.id) {
      history.push(PATH.HOME);
    }
  }, []);

  React.useEffect(() => {
    setDetail({...defaultLogWoodPOWorkflowDetail, ...response});
  }, [response])

  React.useEffect(() => {
    if (detailRef.current) {
      setCurrentDetail(detailRef.current.detail)
    }
  }, [detailTab, detailRef])

  const handleSaveWorkflowDetail = async () => {
    setIsActionLoading(true);
    if (!detailRef.current) {
      return false;
    }
    const isSuccess = await detailRef.current.handleSaveDetail();
    if (isSuccess) {
      Toast.success(`${t('saving')} ${t('success')}`);
      fetchData();
    } else {
      Toast.error(`${t('saving')} ${t('error')}`);
    }
    setIsActionLoading(false);
    return isSuccess;
  }

  const handleDoAction = async (params) => {
    if (detail.workflow_params.formEditable) {
      const isSuccess = await handleSaveWorkflowDetail();
      if (!isSuccess) {
        return;
      }
    }
    const isActionSuccess = await handleAction(params);
    if (!isActionSuccess) {
      Toast.error(`${t(`workflows.actions.${params.action}`)} ${t('error')}`)
    }
  }

  return (
    <div>
      <Media lessThan="md">
        <TimeoutModal
          open={isTimeout}
          onClose={() => history.push(PATH.HOME)}
        />
      </Media>

      <Dimmer active={isLoading}>
        <Loader>{t('loading')}</Loader>
      </Dimmer>
      <SubHeader/>
      
      <Container style={{ marginTop: '7em' }} textAlign='left'>
        { errorMessages && 
          <Message error>
            <Message.Header>{t('error')}</Message.Header>
            {errorMessages}
          </Message>
        }
        { detail.memo && 
          <Message
            warning 
            icon='info circle'
            header={t('memo')}
            content={detail.memo}
          />
        }

        <Media greaterThan="sm">
          <Header as='h2'>
            <BasicButton 
              icon='history'
              floated='right'
              content={t('workflows.history')}
              onClick={() => setIsHistoryModalVisible(true)}/>
            {t('log_wood_po.detail')} - [{detail.state_name}]
          </Header>
          <Divider/>
        </Media>

        <Media at="sm">
          <Header as='h4'>
            {t('log_wood_po.detail')} - [{detail.state_name}]
          </Header>
          <BasicButton 
            icon='history'
            content={t('workflows.history')}
            onClick={() => setIsHistoryModalVisible(true)}/>
        </Media>

        <Menu tabular attached='top'>
          <Menu.Item
            icon='truck'
            content={t('log_wood_po.truck_transaction_detail')}
            active={detailTab === 'truck_transaction_detail'}
            onClick={() => setDetailTab('truck_transaction_detail')}
          />
          <Menu.Item
            icon='clipboard'
            content={t('log_wood_po.summary')}
            active={detailTab === 'summary'}
            onClick={() => setDetailTab('summary')}
          />
        </Menu>
        <Segment attached='bottom'>
          <LogWoodPODetail
            ref={detailRef}
            enableEdit={detail.workflow_params.formEditable}
            truckTransaction={detail.truck_transaction}
            logWoodEvaluationData={detail.log_wood_evaluation_data}
            active={detailTab === 'truck_transaction_detail'}
            setDetailLoading={(value) => setIsDetailLoading(value)}
          />
          <LogWoodPOSummary
            detail={detail}
            active={detailTab === 'summary'}
          />
        </Segment>
        <ActionButtons
          enableSave={detail.workflow_params.formEditable}
          handleSave={() => handleSaveWorkflowDetail()}
          actions={detail.workflow_actions.filter(e => e !== 'cancel')}
          handleAction={(action) => handleDoAction(action)}
          isLoading={isLoading || isActionLoading || isDetailLoading}
        />
      </Container>

      <HistoryListModal
        open={isHistoryModalVisible}
        onClose={() => setIsHistoryModalVisible(false)}
        workflowHistoryUrl={`${URL_WORKFLOW.LOG_WOOD_PO_WORKFLOW_HISTORY}${params.id}`}
      />
    </div>
  )
}

export default LogWoodPODetailView;